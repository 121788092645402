import { randomString } from 'src/common/helpers/random';

import {
    Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation
} from '@angular/core';

import { ngbTooltipTemplate, Tooltip } from '../content-tooltip/content-tooltip.common';
import { InfoPopup } from '../control-title/control-title.common';
import { RadioButtonItem, RadioLook } from './radio-button.common';

@Component({
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class RadioButtonComponent<TValue> implements OnChanges {
    public isHorizontal = false;
    public radioName = randomString(8);
    public ngbTooltipTemplate = ngbTooltipTemplate;

    @Input()
    public id = randomString(8);

    @Input()
    public title?: string;

    @Input()
    public look = RadioLook.Normal;

    @Input()
    public selectedValue?: TValue;

    @Output()
    public selectedValueChange = new EventEmitter<TValue>();

    @Input()
    public items: RadioButtonItem<TValue>[] = [];

    @Input()
    public disabled = false;

    @Input()
    public tooltip?: Tooltip;

    @Input()
    public infoPopup?: InfoPopup;

    @Input()
    public infoPopupTooltip?: Tooltip;


    ngOnChanges(): void {
        this.isHorizontal = this.look == RadioLook.Horizontal;
    }

    public onSelected(item: RadioButtonItem<TValue>) {
        this.selectedValue = item.value;
        this.selectedValueChange.emit(item.value);
    }
}
