import {
    Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation
} from '@angular/core';

import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { includeSprites } from '../../sprites';
import { ngbTooltipTemplate, Tooltip } from '../content-tooltip/content-tooltip.common';
import { ControlTitleLook, InfoPopup } from './control-title.common';

@Component({
    templateUrl: './control-title.component.html',
    styleUrls: ['./control-title.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ControlTitleComponent implements OnInit, OnChanges {
    public isHiltiStyled = false;

    @Input()
    public focusControlId!: string;

    @Input()
    public title!: string;

    @Input()
    public look = ControlTitleLook.Normal;

    @Input()
    public tooltip?: Tooltip;

    @Input()
    public infoPopup?: InfoPopup;

    @Input()
    public infoPopupTooltip?: Tooltip;

    @Input()
    public isAdvanceInputPopup?: boolean;

    public ngbTooltipTemplate = ngbTooltipTemplate;
    public titleHtml = '';

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private modalService: ModalService,
        private localizationService: LocalizationService
    ) { }

    public ngOnInit() {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-info'
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['title'] != null) {
            const titleHtml = this.localizationService.sanitizeText(this.title ?? '', LocalizationService.SubSup) ?? '';
            if (this.titleHtml != titleHtml) {
                this.titleHtml = titleHtml;
            }
        }

        this.isHiltiStyled = this.look == ControlTitleLook.HiltiStyled;
    }

    public openInfoPopup(): void {
        if (this.infoPopup != null) {
            this.modalService.openModal(this.infoPopup.content, this.infoPopup.options);
        }
    }
}
