<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.SpecificationText.Title')">
    <div class="perfect-spec-logo-wrapper" *ngIf="modalInstance.input?.showPerfectSpec">
        <pe-perfect-spec-logo></pe-perfect-spec-logo>
    </div>
</pe-modal-header>

<pe-modal-body>
    <div class="section">
        <div id="specification-text-area"
            class="specification-text-area control-row input disable-flex"
            [innerHtml]="specificationText">
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button class="modal-button"
        [look]="'Default'"
        (buttonClick)="close()"
        [text]="translate('Agito.Hilti.Profis3.SpecificationText.Close')"></pe-button>

    <pe-button
        class="modal-button"
        [look]="'Cta'"
        (buttonClick)="copyToClipboard()"
        [text]="translate('Agito.Hilti.Profis3.SpecificationText.Copy')">
    </pe-button>
</pe-modal-footer>
