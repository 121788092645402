import { MarketingCampaignType } from '../generated-modules/Hilti.PE.ProductInformationService.Shared.Enums';
import { UserDetails } from '../generated-modules/Hilti.PE.Tracking';

export interface TrackingUserData {
    BrowserType: string;
    BuyingCustomerOriginId: string;
    BuyingCustomerType: string;
    BuyingSalesOrg: string;
    CountryOfResidence: string;
    CustomerCountry: string;
    CustomerId: string;
    CustomerOriginId: string;
    CustomerType: string;
    DiagnosticsAgreement: boolean;
    IsTrial: boolean;
    License: string;
    OperatingSystem: string;
    SalesOrg: string;
    TimezoneOffset?: number;
    UserId: string;
    UserIpAddress: string;
    UserName: string;
}

export const enum ButtonEventType {
    ELearnClicked = 0,
    AskHiltiClicked = 1,
    ContinueWithStandard = 2,
    SwitchInternalLicenseClicked = 3,
    PeDesktopClicked = 4,
    WhatsNewClicked = 5,
    CheckBotImportContinueClicked = 6
}

/**
 * Common tracking service base
 */
export abstract class CommonTrackingServiceBase {
    public abstract getTrackingUserData(timezoneOffset?: number): TrackingUserData;
    public abstract getUserDetails(timezoneOffset: number): UserDetails;
    public abstract trackOnButtonClick(buttonEventType: ButtonEventType, regionDisplayKey?: string, externalAppName?: string): Promise<void>;
    public abstract trackOnDownloadApplication(applicationId: string): Promise<void>;
    public abstract trackFavoriteClicks(clicksCount: number): Promise<void>;
    public abstract trackPopupShown(eventPopupType: MarketingCampaignType, eventPopupName: string): Promise<void>;
    public abstract trackPopupDontShowAgain(eventPopupType: MarketingCampaignType, eventPopupName: string): Promise<void>;
    public abstract trackPopupLinkClicked(eventPopupType: MarketingCampaignType, eventPopupName: string, eventPopupUrl: string): Promise<void>;
    public abstract trackBulkReports(totalReports: number, cbfemDesigns: number): Promise<void>;
    public abstract trackVirtualTour(virtualTourName: string, finishedStep: number, allSteps: number): Promise<void>;
    public abstract trackUserFirstLogin(): Promise<void>;
    public abstract hideMail(checked: boolean): Promise<void>;
    public abstract trackUsageFromUI(projectType: string): Promise<void>;
}

export class CommonTrackingServiceInjected extends CommonTrackingServiceBase {
    public setBaseService(baseService: CommonTrackingServiceBase) {
        this.getTrackingUserData = baseService.getTrackingUserData.bind(baseService);
        this.getUserDetails = baseService.getUserDetails.bind(baseService);
        this.trackOnButtonClick = baseService.trackOnButtonClick.bind(baseService);
        this.trackOnDownloadApplication = baseService.trackOnDownloadApplication.bind(baseService);
        this.trackFavoriteClicks = baseService.trackFavoriteClicks.bind(baseService);
        this.trackPopupShown = baseService.trackPopupShown.bind(baseService);
        this.trackPopupDontShowAgain = baseService.trackPopupDontShowAgain.bind(baseService);
        this.trackPopupLinkClicked = baseService.trackPopupLinkClicked.bind(baseService);
        this.trackBulkReports = baseService.trackBulkReports.bind(baseService);
        this.trackVirtualTour = baseService.trackVirtualTour.bind(baseService);
        this.trackUserFirstLogin = baseService.trackUserFirstLogin.bind(baseService);
        this.hideMail = baseService.hideMail.bind(baseService);
        this.trackUsageFromUI = baseService.trackUsageFromUI.bind(baseService);
    }

    // BrowserServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public getTrackingUserData(_timezoneOffset?: number) {
        return {} as TrackingUserData;
    }

    public getUserDetails(_timezoneOffset: number) {
        return {} as UserDetails;
    }

    public async trackOnButtonClick(_buttonEventType: ButtonEventType, _regionDisplayKey?: string, _externalAppName?: string){
        return Promise.resolve();
    }

    public async trackOnDownloadApplication(_applicationId: string): Promise<void> {
        return Promise.resolve();
    }

    public async trackFavoriteClicks(_clicksCount: number): Promise<void> {
        return Promise.resolve();
    }

    public async trackPopupShown(_eventPopupType: MarketingCampaignType, _eventPopupName: string) {
        return Promise.resolve();
    }

    public async trackPopupDontShowAgain(_eventPopupType: MarketingCampaignType, _eventPopupName: string) {
        return Promise.resolve();
    }

    public async trackPopupLinkClicked(_eventPopupType: MarketingCampaignType, _eventPopupName: string, _eventPopupUrl: string) {
        return Promise.resolve();
    }

    public async trackBulkReports(_totalReports: number, _cbfemDesigns: number) {
        return Promise.resolve();
    }

    public async trackVirtualTour(_virtualTourName: string, _finishedStep: number, _allSteps: number) {
        return Promise.resolve();
    }

    public async trackUserFirstLogin(): Promise<void> {
        return Promise.resolve();
    }

    public async hideMail(_checked: boolean) {
        return Promise.resolve();
    }

    public async trackUsageFromUI(_projectType: string) {
        return Promise.resolve();
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
