import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { LocalizationService, PROFIS3_LOCALIZATION_KEY_PREFIX } from 'src/common/services/localization.service';

@Component({
    templateUrl: './validation-error.component.html',
    styleUrls: ['./validation-error.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ValidationErrorComponent {
    @Input()
    public ngErrors!: ValidationErrors;

    @Input()
    public patternExample!: string;

    constructor(private localizationService: LocalizationService) { }

    public get error() {
        if (this.ngErrors != null) {
            if (this.ngErrors['customError']) {
                return this.ngErrors['customError'];
            }

            if (this.ngErrors['name']) {
                return this.ngErrors['name'];
            }

            if (this.ngErrors['required']) {
                return this.localizationService.getString(`${PROFIS3_LOCALIZATION_KEY_PREFIX}.Controls.HiltiStyled.Required`);
            }

            if (this.ngErrors['minlength'] != null) {
                return this.localizationService.getString(`${PROFIS3_LOCALIZATION_KEY_PREFIX}.Controls.HiltiStyled.MinLength`).replace('{minLength}', (this.ngErrors['minlength'].requiredLength as number).toFixed());
            }

            if (this.ngErrors['maxlength'] != null) {
                return this.localizationService.getString(`${PROFIS3_LOCALIZATION_KEY_PREFIX}.Controls.HiltiStyled.MaxLength`).replace('{maxLength}', (this.ngErrors['maxlength'].requiredLength as number).toFixed());
            }

            if (this.ngErrors['pattern'] != null) {
                return this.localizationService.getString(`${PROFIS3_LOCALIZATION_KEY_PREFIX}.Controls.HiltiStyled.Pattern`).replace('{example}', this.patternExample);
            }
        }

        return null;
    }
}
