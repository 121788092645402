import { Component, Input } from '@angular/core';
import { IMenuItem, MENU_SEPERATOR } from './context-menu.common';
import { randomString } from '../../helpers/random';

/**
 * Represents a context menu component.
 *
 * @template T The type of the menu items.
 */
@Component({
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent<T> {
  /**
   * The array of menu items.
   */
  @Input()
  menu: IMenuItem<T>[] | undefined;

  /**
   * The icon class for the context menu.
   */
  @Input()
  icon: string | undefined;

  /**
   * The label for the context menu.
   */
  @Input()
  label: string | undefined = undefined;

  /**
   * The property to disable menu toggle button.
   */
  @Input()
  disabled = false;

  /**
   * The property to fix the position of the dropdown menu.
   */
  @Input()
  public fixedPosition = false;

  /**
   * The method to be called when the menu is toggled.
   */
  @Input()
  public onMenuToggled?: (opened: boolean) => void;

  /**
   * The dropdown menu id.
   */
  menuId = randomString(8);

  /**
   * The internal property that holds the menu items to be rendered.
   */
  options: IMenuItem<T>[] | undefined;

  /**
   * A constant that represents the menu separator.
   */
  readonly MENU_SEPERATOR = MENU_SEPERATOR;

  /**
   * Handles the click event on a menu item.
   *
   * @param option The clicked menu item.
   */
  onClick = (option: IMenuItem<T>) => {
    if (option.onClick) {
      option.onClick(option);
    }
  };

  setDropdownDirection(event: MouseEvent) {
    if(!this.fixedPosition) {
      return;
    }

    const menuButton = event.currentTarget as HTMLElement;
    if (menuButton == null) {
      return;
    }

    const dropdownMenu = document.querySelector(`.context-menu-dropdown-${this.menuId}`) as HTMLElement;
    const left = menuButton.getBoundingClientRect().left;
    let top = menuButton.getBoundingClientRect().top + menuButton.clientHeight;
    if (menuButton.getBoundingClientRect().top + menuButton.clientHeight + dropdownMenu.clientHeight > document.body.clientHeight) {
      top = menuButton.getBoundingClientRect().top - dropdownMenu.clientHeight;
    }

    dropdownMenu.style.top = `${top}px`;
    dropdownMenu.style.left = `${left}px`;
    dropdownMenu.style.position = 'fixed';
  }
}
