import { CheckboxButtonItem } from '../components/checkbox-button/checkbox-button.common';

export const enum NotificationType {
    info,
    alert
}

export const NotificationTypeMap: Record<keyof typeof NotificationType, NotificationType> = {
    alert: NotificationType.alert,
    info: NotificationType.info
};

export const NotificationTypeImage: Readonly<Record<NotificationType, string>> = {
    [NotificationType.alert]: 'warning',
    [NotificationType.info]: 'info'
};

export const NotificationTypeName: Readonly<Record<NotificationType, keyof typeof NotificationType>> = {
    [NotificationType.alert]: 'alert',
    [NotificationType.info]: 'info'
};

export enum NotificationLocation {
    Start,
    Middle,
    End
}

export interface INotification {
    condition: () => boolean;
    text: string;
    doesCount?: boolean;
    class?: string;
    button?: INotificationButton;
    checkbox?: INotificationCheckbox;
    additionalText?: string;
    additionalButton?: INotificationButton;
    notificationLocation: NotificationLocation;
}

export interface INotificationButton {
    condition: () => boolean;
    disabled: () => boolean;
    click: () => void;
    buttonTitle: string;
    tooltip?: string;
    disableTooltip?: () => boolean;
}

export interface INotificationCheckbox {
    condition: () => boolean;
    items: CheckboxButtonItem<boolean>[];
    value: boolean;
    valueChange: () => void;
}

export interface INotificationScopeCheck {
    type : NotificationType;
    message: string;
    actionButtons: INotificationButton[];
    hasSupportButton?: () => boolean;
    supportButtonClick: () => void;
    hasInfoButton?: () => boolean;
    infoButtonClick: () => void;
}

export interface INotificationsComponentInput {
    isVisible: () => boolean;
    isInfoMessageVisible: () => boolean;
    notifications: INotification[];
    scopeChecks: INotificationScopeCheck[];
}
