import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.ShadowDom,
    templateUrl: './loading-button.component.html',
    styleUrls: ['./loading-button.component.scss']
  })
export class LoadingButtonComponent {
    @Input()
    public loading = false;

    @Input()
    public color = '#524f53';

    @Input()
    public text!: string;
}
