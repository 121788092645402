import {
    Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation
} from '@angular/core';

import { LocalizationService } from '../../services/localization.service';
import { CheckboxButtonProps } from '../checkbox-button/checkbox-button.common';

@Component({
    templateUrl: './export-report-project-details.component.html',
    styleUrls: ['./export-report-project-details.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ExportReportProjectDetailsComponent implements OnChanges {
    @Input()
    public designName!: string;
    @Input()
    public fasteningPoint!: string;
    @Output()
    public fasteningPointChange = new EventEmitter<string>();
    @Input()
    public notes!: string;
    @Output()
    public notesChange = new EventEmitter<string>();
    @Input()
    public includeDetailsInReport!: CheckboxButtonProps<number>;
    @Input()
    public includeSustainabilityInReport?: CheckboxButtonProps<number>;
    @Input()
    public reportDisabled!: boolean;
    @Input()
    public fasteningPointVisible = true;
    @Input()
    public fasteningPointTitle!: string;
    @Input()
    public fasteningPointId!: string;
    @Input()
    public submitted = false;

    public isLoaded = false;

    @Input()
    public isIncludeDetailsInReport? = true;

    constructor(
        public localizationService: LocalizationService
    ) { }

    ngOnChanges(): void {
        if (!this.isLoaded) {
            this.isLoaded = true;
        }
    }
}
