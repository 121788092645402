<pe-control-title
    [focusControlId]="id"
    [title]="title"
    [tooltip]="tooltip"
    [infoPopup]="infoPopup"
    [infoPopupTooltip]="infoPopupTooltip"
></pe-control-title>

<div [id]="id" class="radio-container" [class.radio-container-horizontal]="isHorizontal">
    <label class="radio" *ngFor="let item of items" [ngbTooltip]="ngbTooltipTemplate(item.tooltip, itemTooltipTemplate)">
        <ng-template #itemTooltipTemplate>
            <pe-content-tooltip [tooltip]="item.tooltip"></pe-content-tooltip>
        </ng-template>

        <span class="radio-wrapper">
            <input class="radio-input" [attr.id]="item.id ? item.id : null" [name]="radioName" [checked]="selectedValue == item.value"
                (change)="onSelected(item)" type="radio" [disabled]="item.disabled || disabled">
            <span class="radio-button"></span>
            <span class="radio-label" *ngIf="item.text">{{item.text}}</span>
        </span>

        <span class="radio-description" *ngIf="item.description">{{item.description}}</span>
    </label>
</div>
