import { Injectable } from '@angular/core';
import { LocalizationServiceInjected } from './localization.common';

export const PROFIS3_LOCALIZATION_KEY_PREFIX = 'Agito.Hilti.Profis3';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService extends LocalizationServiceInjected {
}
