import { Tooltip } from '../content-tooltip/content-tooltip.common';
import { InfoPopup } from '../control-title/control-title.common';

export const enum CheckboxLook {
    Normal              = 'Normal',
}

export enum TooltipTarget {
    Item,
    InfoIcon,
}

export enum InfoIcon {
    None,
    Normal,
    Button,
}


export interface CheckboxButtonPropsBase {
    id?: string;
    title?: string;
    look?: CheckboxLook;
    disabled?: boolean;
    tooltip?: Tooltip;
    infoPopup?: InfoPopup;
    infoPopupTooltip?: Tooltip;
    testId?: string;
}

export interface CheckboxButtonProps<TValue = number> extends CheckboxButtonPropsBase {
    selectedValues?: Set<TValue>;
    items?: CheckboxButtonItem<TValue>[];
}

export interface CheckboxButtonGroupProps<TValue = number> extends CheckboxButtonPropsBase {
    selectedValues?: Set<TValue>;
    groupItems?: CheckboxButtonGroupItem<TValue>[];
}

export interface CheckboxButtonItem<TValue> {
    id?: string;
    value: TValue;
    text: string;
    description?: string;
    disabled?: boolean;
    tooltip?: Tooltip;
    tooltipTarget?: TooltipTarget;
    infoIcon?: InfoIcon;
}

export interface CheckboxButtonGroupItem<TValue> {
    title?: string;
    testId?: string;
    items: CheckboxButtonItem<TValue>[];
}
