import { Design, IBaseDesign } from '../entities/design';
import { Project } from '../entities/project';
import { Deferred } from '../helpers/deferred';

export const enum DocumentAccessMode {
    Open,   // open document
    Update, // update document
}

/**
 *  The interface for basic design metadata
 */
export interface DesignExternalMetaData {
    id: string;
    name: string;
    region: number;
    standard: number;
    designType: number;
    approvalNumber?: string;
    designMethod?: number;
    productName?: string;
}

/**
 *  The interface for basic list items.
 */
export interface IDesignListItem extends IBaseDesign {
    metaData: DesignExternalMetaData;
}

export interface IDefferedData<TProjectDesign, TDetailedDisplayDesign> {
    designId: string;
    projectId: string;
    designName: string;
    contentOverride: TProjectDesign;
    metadataOverride: DesignExternalMetaData;
    unlock: boolean;
    displayDesign: TDetailedDisplayDesign;
    exclusiveLock: boolean;
    documentAccessMode: DocumentAccessMode;
}


export interface IDocumentServiceDesign {
    documentid: string;
    name: string;
    projectid: string;
    parentProjectId: string;
}


/**
 * A base class for all can't perform action responses.
 */
export abstract class CantPerformActionReason {}

/**
 * Instance of class derived from this class are sometimes be returned when opening a design insted of the design.
 */
export abstract class CantOpenDesignReason extends CantPerformActionReason {}

/**
 * CantOpen design because another user has locked it.
 */
export class CantOpenDesignBecauseLockedByOtherUser extends CantOpenDesignReason {
    /**
     * User-name of the user that locked the design.
     */
    public username?: string;
}

/**
 * Cant archive design because another user has locked it.
 */
export class CantArchiveDesignBecauseLockedByOtherUser extends CantOpenDesignReason {
    /**
     * User-name of the user that locked the design.
     */
    public username?: string;
}

/**
 * An exception indicating that projects can't be deleted because they are in use or locked.
 */
export class CantDeleteProjectsBecauseDocumentInUse extends CantPerformActionReason {
    /**
     * A list of documents locked together with document names and users that have the files locked.
     */
    public documentsInQuestion: CantPerformActionReasonProblematicDocumentInformation[] = [];
}

/**
 * An exception indicating that projects can't be deleted because they are in use or locked.
 */
export class CantRestoreProjectsBecauseDocumentInUse extends CantPerformActionReason {
    /**
     * A list of documents locked together with document names and users that have the files locked.
     */
    public documentsInQuestion: CantPerformActionReasonProblematicDocumentInformation[] = [];
}

/**
 * An exception indicating that projects can't be deleted because they are in use or locked.
 */
export class CantArchiveProjectsBecauseDocumentInUse extends CantPerformActionReason {
    /**
     * A list of documents locked together with document names and users that have the files locked.
     */
    public documentsInQuestion: CantPerformActionReasonProblematicDocumentInformation[] = [];
}

/**
 * Just holding information for each locked document that cussed the problem
 */
export class CantPerformActionReasonProblematicDocumentInformation {
    public designId?: string;
    public username?: string;
    public document?: string;
}

/**
 * Deferred image data.
 */
export interface IDefferedImageData {
    designId: string;
    thumbnailContent: string;
    respond: boolean;
    isTemplate: boolean;
}

/**
 * Deferred requests, by design identifier.
 */
export interface IDefferedRequests<TProjectDesign, TDetailedDisplayDesign> {
    [designId: string]: {
        defferedData: IDefferedData<TProjectDesign, TDetailedDisplayDesign> | IDefferedImageData;
        deffered: Deferred<void>;
        running: Promise<void>;
    };
}

/**
 * Options used with DocumentServiceBase -> addDesignBase.
 */
export interface IAddDesignBaseOptions {
    /** Project identifier. */
    projectId: string;

    /** Design type. */
    designType: number;
    /** Design object. */
    design: Design;
    /**
     * Design contents override.
     * Used by Web implementations only so the contents of design file can be overriden
     * (for calculating metadata and adding a new file).
     */
    designOverride?: object;
    /**
     * Design name override.
     * Used for replacing design name (as set in design) with a custom string.
     */
    designNameOverride?: string;

    /**
     * If set then (backend) service tries to ensure design name uniqueness.
     * Used by Web implementations only.
     */
    canGenerateUniqueName: boolean;
    /**
     * If set then service will not show warning popups when response with HTTP code 409 (Conflict) is returned from backend seervice.
     * Used by Web implementations only.
     */
    ignoreConflict: boolean;

    /**
     * Sets the project design to (local) design list item.
     * Used by non-Web implementations only.
     */
    setProjectDesign?: (newDesign: IDesignListItem, design: Design) => void;
    /** Sets additional (per-module) properties to design list item. */
    adjustDesignListItemContents: (newDesign: IDesignListItem, design: Design, project: Project) => void;
    /** Returns design meta data for provided design type and design object. */
    getDesignMetadata: (designType: number, design: object) => DesignExternalMetaData;
    /**
     * Obtains project design object for provided design object.
     * Used by Web implementations only.
     */
    getDesignObject: (design: Design) => object;
}

/**
 * Options used with DocumentServiceBase -> getDesignContentBase<TProjectDesign>.
 */
export interface IGetDesignContentBaseOptions<TProjectDesign> {
    /** Design identifier. */
    designId: string;
    /** Session identifier. */
    sessionId: string;
    /** Flag indicating whether design contents should be obtained exclusively. */
    exclusive: boolean;

    /**
     * Project name override.
     * Used for replacing project name (as set in design) with a custom string.
     */
    projectName?: string;
    /** A method adjusting obtained design contents. */
    adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign;

    /** Document identifier. */
    documentId?: string;
    /**
     * A method handling errors related to design being locked
     * (response with HTTP code 423 (Locked)) is returned from backend seervice).
     */
    handleDocumentLocked?: (documentInfo: IDesignListItem) => Promise<void>;
    /** A flag indicating whether or non non 423 (Locked) errors should also be handled. */
    handleNonLockedStatusErrors: boolean;
}

/**
 * Options used with DocumentServiceBase -> updateDesignWithNewContentBase<TProjectDesign, TDetailedDisplayDesign>.
 */
export interface IUpdateDesignWithNewContentBaseOptions<TProjectDesign, TDetailedDisplayDesign> {
    /** Design identifier. */
    designId: string;

    /**
     * Project identifier.
     * Used by non-Web implementations only.
     */
    projectId?: string;
    /**
     * Design name.
     * Used by non-Web implementations only.
     */
    designName?: string;
    /**
     * Design meta data to be used for update.
     * Used by non-Web implementations only.
     */
    metadata?: DesignExternalMetaData;
    /**
     * Design contents (project design) to be used for update.
     * Used by non-Web implementations only.
     */
    contentOverride?: TProjectDesign;
    /**
     * Display design data to be used for update.
     * Used by non-Web implementations only.
     */
    displayDesign?: TDetailedDisplayDesign;
    /**
     * A method used for updating design contents (project design) and meta data.
     * Used by non-Web implementations only.
     */
    updateFn?: (projectDesign: TProjectDesign, internalDesign: IDesignListItem, metadata: DesignExternalMetaData, displayDesign?: TDetailedDisplayDesign) => Promise<void>;

    /**
     * Data to be put in defferedRequests.
     * Used by Web implementations only.
     */
    defferedData?: IDefferedData<TProjectDesign, TDetailedDisplayDesign>;
    /**
     * A method adding a new promise to defferedRequests.
     * Used by Web implementations only.
     */
    putSmallDesignChangePromiseFn?: (data: IDefferedData<TProjectDesign, TDetailedDisplayDesign>) => Promise<void>;
}

/**
 * Options used with DocumentServiceBase -> smallDesignChangeBase<TProjectDesign, TDetailedDisplayDesign>.
 */
export interface ISmallDesignChangeOptions<TProjectDesign, TDetailedDisplayDesign> {
    /** The design list item */
    internalDesign: IDesignListItem;
    /** The design contents. */
    projectDesign: TProjectDesign;
    /** The related deferred request data. */
    data: IDefferedData<TProjectDesign, TDetailedDisplayDesign>;
    /** The design meta data. */
    metaData: DesignExternalMetaData;

    /** A method obtaining design contents object for provided project design. */
    getProjectDesign: (projectDesign: TProjectDesign) => unknown;
    /** A method setting design meta data to design list item. */
    setDesignListItemMetaData: (internalDesign: IDesignListItem, metaData: DesignExternalMetaData) => void;
    /** A method returning design update promise. */
    putSmallDesignChangePromise: (data: IDefferedData<TProjectDesign, TDetailedDisplayDesign>) => Promise<void>;
}


/**
 * Document service base.
 */
export abstract class DocumentServiceBase {
    /** A list of root level projects. Sub projects are accessible in subprojects node of the root level projects. */
    public abstract get projects(): Record<string, Project>;
    /** A list of all projects. */
    public abstract get projectsFlat(): Record<string, Project>;
    /** Draft project. */
    public abstract get draftsProject(): Project;

    /** A list of deferred requests. */
    protected abstract get defferedRequests(): IDefferedRequests<any, any>;

    public abstract findProjectById(projectId: string): Project;
    public abstract findProjectByDesignId(designId: string): Project;
    public abstract findDesignById(designId: string): IDesignListItem;
    public abstract publish(id: string): Promise<void>;
    public abstract updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void>;
    public abstract uploadDesignImage(designId: string, imageContent: string, thumbnailContent: string): Promise<void>;
    public abstract copyDesign(documentId: string, documentName: string, projectId: string): Promise<void>;
    public abstract moveDesign(documentId: string, projectId: string): Promise<void>;
    public abstract getSessionKeyForDesign(designId: string): string;
    /** Creates a unique file name from a given name */
    public abstract createUniqueName(oldName: string, usedNames: string[]): string;


    /**
     * Obtains design contents (project design) non-exclusively.
     * @param design Base design object.
     * @param obtainDesignContent A method obtaining design contents (project design) from provided design object. Used by Local implementations only.
     */
    public abstract openDesignCommon<TProjectDesign>(
        design: IBaseDesign,
        obtainDesignContent?: (design: IBaseDesign) => TProjectDesign
    ): Promise<TProjectDesign>;

    /**
     * Opens design exclusively and returns its contents (project design).
     * Used by non-Desktop implementations only.
     * @param design Base design object.
     * @param adjustContent A method adjusting obtained design contents. Used by Web implementations only.
     * @param obtainDesignContent A method obtaining design contents (project design) from provided design object. Used by Local implementations only.
     */
    public abstract openDesignExclusiveBase<TProjectDesign>(
        design: IBaseDesign,
        adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign,
        obtainDesignContent?: (design: IBaseDesign) => TProjectDesign
    ): Promise<TProjectDesign>;
    /**
     * Opens design exclusively and returns its contents (project design).
     * Used by non-Desktop implementations only.
     * Used by "newer" modules like CW. Uses the same method internally as updateDesignWithNewContentBase (above).
     * @param design Base design object.
     * @param adjustContent A method adjusting obtained design contents. Used by Web implementations only.
     */
    public abstract openDesignExclusive<TProjectDesign>(design: IBaseDesign, adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign>;

    /**
     * Adds a new design.
     */
    public abstract addDesignBase(options: IAddDesignBaseOptions): Promise<IDesignListItem>;
    /**
     * Adds a new design.
     * Used by "newer" modules like CW. Uses addDesignBase (above) internally.
     */
    public abstract addDesignCommon(projectId: string, design: Design, canGenerateUniqueName: boolean, ignoreConflict: boolean): Promise<IDesignListItem>;

    /**
     * Obtains document contents from backend service.
     * Used by Web implementations only.
     */
    public abstract getDesignContentBase<TProjectDesign>(options: IGetDesignContentBaseOptions<TProjectDesign>): Promise<TProjectDesign>;

    /**
     * Updates the design contents.
     */
    public abstract updateDesignWithNewContentBase<TProjectDesign, TDetailedDisplayDesign>(
        opts: IUpdateDesignWithNewContentBaseOptions<TProjectDesign, TDetailedDisplayDesign>
    ): Promise<void>;
    /**
     * Updates the design contents.
     * Used by "newer" modules like CW. Uses the same method internally as updateDesignWithNewContentBase (above).
     */
    public abstract updateDesignWithNewContentCommon<TDisplayDesign>(
        design: Design,
        displayDesign: TDisplayDesign,
        unlock: boolean,
        exclusiveLock: boolean,
        documentAccessMode?: DocumentAccessMode
    ): Promise<void>;

    /**
     * Returns a promise for a small change to projectDesign set in the design object to be applied.
     * Used by Web implementations only.
     */
    public abstract smallDesignChangeBase<TProjectDesign, TDetailedDisplayDesign>(
        options: ISmallDesignChangeOptions<TProjectDesign, TDetailedDisplayDesign>
    ): Promise<void>;
    /**
     * Returns a promise for a small change to projectDesign set in the design object to be applied.
     * Used by Web implementations only.
     * Used by "newer" modules like CW. Uses smallDesignChangeBase (above) internally.
     */
    public abstract smallDesignChangeCommon<TProjectDesign, TDetailedDisplayDesign>(
        internalDesign: IDesignListItem,
        projectDesign: TProjectDesign,
        data: IDefferedData<TProjectDesign, TDetailedDisplayDesign>,
        metaData: DesignExternalMetaData
    ): Promise<void>;
}

export class DocumentServiceInjected extends DocumentServiceBase {
    protected baseService!: DocumentServiceBase;

    public get projects() {
        return this.baseService.projects;
    }

    public get projectsFlat() {
        return this.baseService.projectsFlat;
    }

    public get draftsProject() {
        return this.baseService.draftsProject;
    }

    protected get defferedRequests() {
        return this.baseService['defferedRequests'];
    }


    public setBaseService(baseService: DocumentServiceBase) {
        this.baseService = baseService;

        this.findProjectById = baseService.findProjectById.bind(baseService);
        this.findProjectByDesignId = baseService.findProjectByDesignId.bind(baseService);
        this.findDesignById = baseService.findDesignById.bind(baseService);
        this.publish = baseService.publish.bind(baseService);
        this.updateDesignThumbnailImage = baseService.updateDesignThumbnailImage.bind(baseService);
        this.uploadDesignImage = baseService.uploadDesignImage.bind(baseService);
        this.copyDesign = baseService.copyDesign.bind(baseService);
        this.moveDesign = baseService.moveDesign.bind(baseService);
        this.getSessionKeyForDesign = baseService.getSessionKeyForDesign.bind(baseService);
        this.createUniqueName = baseService.createUniqueName.bind(baseService);


        this.openDesignCommon = baseService.openDesignCommon.bind(baseService);
        this.openDesignExclusiveBase = baseService.openDesignExclusiveBase.bind(baseService);
        this.openDesignExclusive = baseService.openDesignExclusive.bind(baseService);

        this.addDesignBase = baseService.addDesignBase.bind(baseService);
        this.addDesignCommon = baseService.addDesignCommon.bind(baseService);

        this.getDesignContentBase = baseService.getDesignContentBase.bind(baseService);

        this.updateDesignWithNewContentBase = baseService.updateDesignWithNewContentBase.bind(baseService);
        this.updateDesignWithNewContentCommon = baseService.updateDesignWithNewContentCommon.bind(baseService);

        this.smallDesignChangeBase = baseService.smallDesignChangeBase.bind(baseService);
        this.smallDesignChangeCommon = baseService.smallDesignChangeCommon.bind(baseService);
    }

    // DocumentServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public findProjectById(_projectId: string): Project {
        return {} as Project;
    }

    public findProjectByDesignId(_designId: string) {
        return {} as Project;
    }

    public findDesignById(_designId: string): IDesignListItem {
        return {} as IDesignListItem;
    }

    public publish(_id: string): Promise<void> {
        return {} as Promise<void>;
    }

    public updateDesignThumbnailImage(_designId: string, _thumbnailContent: string, _respond: boolean): Promise<void> {
        return {} as Promise<void>;
    }

    public uploadDesignImage(_designId: string, _imageContent: string, _thumbnailContent: string): Promise<void> {
        return {} as Promise<void>;
    }

    public copyDesign(_documentId: string, _documentName: string, _projectId: string): Promise<void> {
        return {} as Promise<void>;
    }

    public moveDesign(_documentId: string, _projectId: string): Promise<void> {
        return {} as Promise<void>;
    }

    public getSessionKeyForDesign(_designId: string): string {
        return '';
    }

    public createUniqueName(_oldName: string, _usedNames: string[]): string {
        return '';
    }


    public openDesignCommon<TProjectDesign>(
        _design: IBaseDesign,
        _obtainDesignContent?: (design: IBaseDesign) => TProjectDesign
    ): Promise<TProjectDesign> {
        return {} as Promise<TProjectDesign>;
    }

    public openDesignExclusiveBase<TProjectDesign>(
        _design: IBaseDesign,
        _adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign,
        _obtainDesignContent?: (design: IBaseDesign) => TProjectDesign
    ): Promise<TProjectDesign> {
        return {} as Promise<TProjectDesign>;
    }

    public openDesignExclusive<TProjectDesign>(_design: IBaseDesign, _adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign> {
        return {} as Promise<TProjectDesign>;
    }


    public addDesignBase(_options: IAddDesignBaseOptions): Promise<IDesignListItem> {
        return {} as Promise<IDesignListItem>;
    }

    public addDesignCommon(_projectId: string, _design: Design, _canGenerateUniqueName: boolean, _ignoreConflict: boolean): Promise<IDesignListItem> {
        return {} as Promise<IDesignListItem>;
    }


    public getDesignContentBase<TProjectDesign>(
        _options: IGetDesignContentBaseOptions<TProjectDesign>
    ): Promise<TProjectDesign> {
        return {} as Promise<TProjectDesign>;
    }


    public updateDesignWithNewContentBase<TProjectDesign, TDetailedDisplayDesign>(
        _options: IUpdateDesignWithNewContentBaseOptions<TProjectDesign, TDetailedDisplayDesign>
    ): Promise<void> {
        return {} as Promise<void>;
    }

    public updateDesignWithNewContentCommon<TDisplayDesign>(_design: Design, _displayDesign: TDisplayDesign, _unlock: boolean, _exclusiveLock: boolean, _documentAccessMode?: DocumentAccessMode): Promise<void> {
        return {} as Promise<void>;
    }

    public smallDesignChangeBase<TProjectDesign, TDetailedDisplayDesign>(
        _options: ISmallDesignChangeOptions<TProjectDesign, TDetailedDisplayDesign>
    ): Promise<void> {
        return {} as Promise<void>;
    }

    public smallDesignChangeCommon<TProjectDesign, TDetailedDisplayDesign>(
        _internalDesign: IDesignListItem,
        _projectDesign: TProjectDesign,
        _data: IDefferedData<TProjectDesign, TDetailedDisplayDesign>,
        _metaData: DesignExternalMetaData
    ): Promise<void> {
        return {} as Promise<void>;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
