import { PickProperties } from 'ts-essentials';

export function replace(value: string, find: string, replaceWith: string) {
    return value.replace(new RegExp(escapeRegExp(find), 'g'), replaceWith);
}

export function format(stringFormat: string, ...args: any[]) {
    return stringFormat.replace(/{(\d+)}/g, (match, number) => {
        return typeof args[number] != 'undefined' ? args[number] : match;
    });
}

export function formatKeyValue(stringFormat: string, args: Record<string, string>) {
    let run = true;

    while (run) {
        run = false;
        stringFormat = stringFormat.replace(/{(.+?)}/g, (match, key) => {
            if (args[key]?.match(/{(.+?)}/g)) {
                run = true;
            }
            return typeof args[key] != 'undefined' ? args[key] : match;
        });
    }

    return stringFormat;
}

export function trim(value: any, length = 100) {
    if (value != null && typeof value == 'string') {
        return value.length > length ? value.substring(0, length) + ' ...' : value;
    }

    return value;
}

export function sortByUnicode<T, K extends keyof PickProperties<T, string>>(collection: T[], property: K) {
    return [...collection].sort((a, b) => (a[property] as unknown as string).localeCompare(b[property] as unknown as string));
}

export function stringNullOrEmpty(value: string | null | undefined) {
    return value == null || value == '';
}

function escapeRegExp(str: string) {
    return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
}
