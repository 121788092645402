import {
    CheckboxButtonProps, CheckboxButtonPropsBase
} from '../components/checkbox-button/checkbox-button.common';

// Simple checkbox button (only 1 item!)
export interface SimpleCheckboxButtonProps extends CheckboxButtonPropsBase {
    checked?: boolean;

    // The only item
    itemId?: string;
    itemText: string;
    itemDescription?: string;
}

export class SimpleCheckboxButtonHelper {
    public static createSimpleCheckbox(checkbox: SimpleCheckboxButtonProps) {
        const retVal: CheckboxButtonProps<boolean> = {
            id: checkbox.id,
            title: checkbox.title,
            look: checkbox.look,
            disabled: checkbox.disabled,
            tooltip: checkbox.tooltip,
            infoPopup: checkbox.infoPopup,
            infoPopupTooltip: checkbox.infoPopupTooltip,
            testId: checkbox.testId,

            items: [
                {
                    id: this.getCheckboxItemId(checkbox),
                    text: checkbox.itemText,
                    description: checkbox.itemDescription,
                    value: true
                }
            ],
            selectedValues: new Set()
        };

        if (checkbox.checked) {
            if (retVal.selectedValues == null) {
                retVal.selectedValues = new Set<boolean>();
            }

            retVal.selectedValues.add(true);
        }

        return retVal;
    }

    public static isSimpleCheckboxChecked(checkbox: CheckboxButtonProps<boolean>) {
        if (checkbox.items == null) {
            return false;
        }
        else {
            if (checkbox.items.length > 1) {
                throw new Error('Checkbox is not a simple checkbox!');
            }

            return checkbox.selectedValues?.has(true);
        }
    }

    public static setSimpleCheckboxChecked(checkbox: CheckboxButtonProps<boolean>, checked: boolean) {
        if (checkbox.selectedValues == null) {
            checkbox.selectedValues = new Set<boolean>();
        }

        if (checked) {
            checkbox.selectedValues.add(true);
        }
        else if (checkbox.selectedValues.has(true)) {
            checkbox.selectedValues.delete(true);
        }
    }

    private static getCheckboxItemId(checkbox: SimpleCheckboxButtonProps) {
        if (checkbox.itemId != null) {
            return checkbox.itemId;
        }

        if (checkbox.id != null) {
            return `${checkbox.id}-checkbox`;
        }

        return undefined;
    }
}
