export abstract class AuthenticationServiceBase {
    public abstract tryExtendToken(): Promise<boolean>;
    public abstract logout(): Promise<never>;
}

export class AuthenticationServiceInjected extends AuthenticationServiceBase {
    public setBaseService(baseService: AuthenticationServiceBase) {
        this.tryExtendToken = baseService.tryExtendToken.bind(baseService);
        this.logout = baseService.logout.bind(baseService);
    }

    public tryExtendToken() {
        return {} as Promise<boolean>;
    }

    public logout() {
        return {} as Promise<never>;
    }
}