import { randomString } from 'src/common/helpers/random';
import { includeSprites } from 'src/common/sprites';

import {
    Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
    ViewEncapsulation
} from '@angular/core';

import { LocalizationService } from '../../services/localization.service';

@Component({
    templateUrl: './collapse.component.html',
    styleUrls: ['./collapse.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class CollapseComponent implements OnInit, OnChanges {
    @Input()
    public id = randomString(8);

    @Input()
    public collapsed = false;

    @Input()
    public container = 'container-content';

    @Output()
    public collapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        public localizationService: LocalizationService,
        private elementRef: ElementRef
    ) { }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-lines',
            'sprite-lines-expanded'
        );
        this.toggleContainerCollapse();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['collapsed'] != null) {
            this.toggleContainerCollapse();
        }
    }

    public toggleCollapsed() {
        this.collapsed = !this.collapsed;
        this.collapsedChange.emit(this.collapsed);

        this.toggleContainerCollapse();
    }

    private toggleContainerCollapse() {
        if (this.container != null && this.container != '') {
            const content = this.elementRef.nativeElement.parentNode.parentNode.querySelector('.' + this.container) as HTMLElement;
            if (content != null && content != undefined) {
                if (this.collapsed === true) {
                    content.classList.add('hidden');
                }
                else {
                    content.classList.remove('hidden');
                }
            }
        }
    }
}
