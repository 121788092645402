import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '../../helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';

interface ISpecificationTextComponentInput {
    specificationText?: string;
    showPerfectSpec?: boolean;
}

@Component({
    selector: 'pe-specification-text',
    templateUrl: './specification-text.component.html',
    styleUrls: ['./specification-text.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SpecificationTextComponent {
    @Input()
    public modalInstance!: ModalInstance<ISpecificationTextComponentInput>;

    constructor(
        private localizationService: LocalizationService) { }


    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public async copyToClipboard() {
        const textContent = this.removeHtmlTags(this.specificationText);
        await navigator.clipboard.writeText(textContent);

        if (document.activeElement instanceof HTMLElement) { // remove focus from button
            document.activeElement.blur();
        }
    }

    public get specificationText() {
        const specificationTextValue = this.modalInstance.input?.specificationText ?? '';
        return this.replaceNewLineChars(specificationTextValue);
    }


    private replaceNewLineChars(value: string): string {
        return value.replace(/\n/g, '\n<br/>');
    }

    private removeHtmlTags(value: string): string {
        if ((value === null) || (value === '')) {
            return '';
        }

        return value.replace(/<(?:.|\n)*?>/gm, '');
    }
}
