<div class="textbox-wrapper" [ngClass]="additionalClasses">
    <pe-control-title
        class="control-title"
        [focusControlId]="id"
        [title]="title"
        [look]="controlTitleStyle"
        [tooltip]="tooltip"
        [infoPopup]="infoPopup"
        [infoPopupTooltip]="infoPopupTooltip">
    </pe-control-title>

    <div class="control-container">
        <textarea [id]="id" [name]="id" class="input"
            [style.height.px]="height"
            [class.submitted]="submitted"
            [minLength]="minLength" [attr.maxLength]="maxLength != null ? maxLength : null"
            [ngModel]="value" (ngModelChange)="ngModelChange($event)" #ngModel="ngModel"
            [ngModelOptions]="updateOnBlur ? { standalone: true, updateOn: 'blur' } : { standalone: true }"
            [disabled]="disabled" [attr.placeholder]="placeholder != null ? placeholder : null"
            autocomplete="off" autocorrect="off" autocapitalize="off"
            spellcheck="false" (blur)="inputBlur.emit($any($event.target).value)"
            (keydown.enter)="onEnterPressed($event)">
        </textarea>
    </div>

    <pe-validation-error
        *ngIf="showValidationErrors"
        class="validation-error"
        [ngErrors]="ngModel?.control?.errors"
        [patternExample]="validationData?.patternExample">
    </pe-validation-error>
</div>
