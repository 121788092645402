import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { ITabItem } from './tabs.common';

@Component({
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class TabsComponent {
    @Input()
    public tabs: ITabItem[] = [];

    @Output()
    public activeTabChange = new EventEmitter<ITabItem>();

    public selectTab(tab: ITabItem) {
        // deactivate all tabs
        this.tabs.forEach(t => t.active = false);

        // activate the tab the user has clicked on.
        tab.active = true;

        this.activeTabChange.emit(tab);
    }
}
