import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'pe-switch-button',
    templateUrl: './switch-button.component.html',
    styleUrls: ['./switch-button.component.scss']
})
export class SwitchButtonComponent {
    @Input()
    public checked!: boolean;

    @Output()
    public valueChange = new EventEmitter<boolean>();

    public toggle() {
        this.valueChange.emit();
    }
}
