<div class="modal-header" [class.higher-modal-header]="showSubtitle">
    <div>
        <h3 [class]="titleClass" [innerHtml]="title"></h3>
        <span *ngIf="showSubtitle" [innerHtml]="subtitle"></span>
    </div>
    <slot></slot>
    <div>
        <button
            *ngIf="enableClose"
            type="button"
            [attr.id]="closeButtonId ? closeButtonId : null"
            [class]="closeButtonClass"
            (click)="close()"
            [disabled]="closeButtonDisabled">
            <span>&times;</span>
        </button>
    </div>
</div>
