import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { ApiServiceBase } from 'src/common/services/api.common';
import { ApiService } from 'src/common/services/api.service';
import { AuthenticationServiceBase } from 'src/common/services/authentication.common';
import { AuthenticationService } from 'src/common/services/authentication.service';
import { BrowserServiceBase } from 'src/common/services/browser.common';
import { BrowserService } from 'src/common/services/browser.service';
import { CommonCodeListServiceBase } from 'src/common/services/common-code-list.common';
import { CommonCodeListService } from 'src/common/services/common-code-list.service';
import { DocumentServiceBase } from 'src/common/services/document.common';
import { DocumentService } from 'src/common/services/document.service';
import {
    FeaturesVisibilityInfoServiceBase
} from 'src/common/services/features-visibility-info.common';
import {
    FeaturesVisibilityInfoService
} from 'src/common/services/features-visibility-info.service';
import { FeaturesVisibilityService } from 'src/common/services/features-visibility.service';
import { LicenseServiceBase } from 'src/common/services/license.common';
import { LicenseService } from 'src/common/services/license.service';
import { LocalizationServiceBase } from 'src/common/services/localization.common';
import { LocalizationService } from 'src/common/services/localization.service';
import { MarketingServiceBase } from 'src/common/services/marketing.common';
import { MarketingService } from 'src/common/services/marketing.service';
import { MathServiceBase } from 'src/common/services/math.common';
import { MathService } from 'src/common/services/math.service';
import { ModalServiceBase } from 'src/common/services/modal.common';
import { ModalService } from 'src/common/services/modal.service';
import { OfflineServiceBase } from 'src/common/services/offline.common';
import { OfflineService } from 'src/common/services/offline.service';
import { ProductInformationServiceBase } from 'src/common/services/product-information.common';
import { ProductInformationService } from 'src/common/services/product-information.service';
import { RoutingServiceBase } from 'src/common/services/routing.common';
import { RoutingService } from 'src/common/services/routing.service';
import {
    SharedEnvironmentData, SharedEnvironmentService
} from 'src/common/services/shared-environment.service';
import { UnitServiceBase } from 'src/common/services/unit.common';
import { UnitService } from 'src/common/services/unit.service';
import { UserSettingsServiceBase } from 'src/common/services/user-settings.common';
import { UserSettingsService } from 'src/common/services/user-settings.service';
import { UserServiceBase } from 'src/common/services/user.common';
import { UserService } from 'src/common/services/user.service';
import { Design } from '../../entities/design';
import { IModulePreInitialData } from '../../entities/module-initial-data';
import { UserSettings } from '../../entities/user-settings';
import { CommonTrackingServiceBase } from '../../services/common-tracking.common';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { ReportTemplateServiceBase } from '../../services/report-template.common';
import { ReportTemplateService } from '../../services/report-template.service';
import { TrimbleConnectServiceBase } from '../../services/trimble-connect.common';
import { TrimbleConnectService } from '../../services/trimble-connect.service';

@Component({
    template: '',
    encapsulation: ViewEncapsulation.ShadowDom
})
export class UiCommonInitComponent implements OnInit, OnChanges {
    // #region Input
    @Input()
    public sharedEnvironmentData!: SharedEnvironmentData;

    @Input()
    public localizationService!: LocalizationServiceBase;

    @Input()
    public modalService!: ModalServiceBase;

    @Input()
    public mathService!: MathServiceBase;

    @Input()
    public unitService!: UnitServiceBase;

    @Input()
    public userSettingsService!: UserSettingsServiceBase<UserSettings>;

    @Input()
    public documentService!: DocumentServiceBase;

    @Input()
    public browserService!: BrowserServiceBase;

    @Input()
    public reportTemplateService!: ReportTemplateServiceBase;

    @Input()
    public trimbleConnectService!: TrimbleConnectServiceBase;

    @Input()
    public licenseService!: LicenseServiceBase;

    @Input()
    public productInformationService!: ProductInformationServiceBase;

    @Input()
    public offlineService!: OfflineServiceBase;

    @Input()
    public commonCodeListService!: CommonCodeListServiceBase;

    @Input()
    public userService!: UserServiceBase<Design>;

    @Input()
    public featureVisibilityInfoService!: FeaturesVisibilityInfoServiceBase;

    @Input()
    public featureVisibilityService!: FeaturesVisibilityService;

    @Input()
    public commonTrackingService!: CommonTrackingServiceBase;

    @Input()
    public marketingService!: MarketingServiceBase;

    @Input()
    public authenticationService!: AuthenticationServiceBase;

    @Input()
    public apiService!: ApiServiceBase;

    @Input()
    public routingService!: RoutingServiceBase;
    // #endregion

    @Output()
    public preInit = new EventEmitter<IModulePreInitialData | (() => IModulePreInitialData | Promise<IModulePreInitialData>)>();


    constructor(
        private sharedEnvironmentService: SharedEnvironmentService,
        private localizationSvc: LocalizationService,
        private modalSvc: ModalService,
        private mathSvc: MathService,
        private unitSvc: UnitService,
        private userSettingsSvc: UserSettingsService,
        private documentSvc: DocumentService,
        private browserSvc: BrowserService,
        private reportTemplateSvc: ReportTemplateService,
        private trimbleConnectSvc: TrimbleConnectService,
        private licenseSvc: LicenseService,
        private productInformationSvc: ProductInformationService,
        private offlineSvc: OfflineService,
        private commonCodeListSvc: CommonCodeListService,
        private userSvc: UserService,
        private featureVisibilityInfoSvc: FeaturesVisibilityInfoService,
        private featureVisibilitySvc: FeaturesVisibilityService,
        private commonTrackingSvc: CommonTrackingService,
        private marketingSvc: MarketingService,
        private authenticationSvc: AuthenticationService,
        private apiSvc: ApiService,
        private routingSvc: RoutingService
    ) { }


    public ngOnInit(): void {
        this.preInit.emit(() => {
            return {} as IModulePreInitialData;
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        try {
            this.initService(changes['sharedEnvironmentData'], () => this.sharedEnvironmentService.init(this.sharedEnvironmentData));

            this.initService(changes['localizationService'], () => this.localizationSvc.setBaseService(this.localizationService));

            this.initService(changes['modalService'], () => this.modalSvc.setBaseService(this.modalService));

            this.initService(changes['mathService'], () => this.mathSvc.setBaseService(this.mathService));

            this.initService(changes['unitService'], () => this.unitSvc.setBaseService(this.unitService));

            this.initService(changes['userSettingsService'], () => this.userSettingsSvc.setBaseService(this.userSettingsService));

            this.initService(changes['documentService'], () => this.documentSvc.setBaseService(this.documentService));

            this.initService(changes['browserService'], () => this.browserSvc.setBaseService(this.browserService));

            this.initService(changes['reportTemplateService'], () => this.reportTemplateSvc.setBaseService(this.reportTemplateService));

            this.initService(changes['trimbleConnectService'], () => this.trimbleConnectSvc.setBaseService(this.trimbleConnectService));

            this.initService(changes['licenseService'], () => this.licenseSvc.setBaseService(this.licenseService));

            this.initService(changes['productInformationService'], () => this.productInformationSvc.setBaseService(this.productInformationService));

            this.initService(changes['offlineService'], () => this.offlineSvc.setBaseService(this.offlineService));

            this.initService(changes['commonCodeListService'], () => this.commonCodeListSvc.setBaseService(this.commonCodeListService));

            this.initService(changes['userService'], () => this.userSvc.setBaseService(this.userService));

            this.initService(changes['featureVisibilityInfoService'], () => this.featureVisibilityInfoSvc.setBaseService(this.featureVisibilityInfoService));

            this.initService(changes['featureVisibilityService'], () => this.featureVisibilitySvc.setBaseService(this.featureVisibilityService));

            this.initService(changes['commonTrackingService'], () => this.commonTrackingSvc.setBaseService(this.commonTrackingService));

            this.initService(changes['marketingService'], () => this.marketingSvc.setBaseService(this.marketingService));

            this.initService(changes['authenticationService'], () => this.authenticationSvc.setBaseService(this.authenticationService));

            this.initService(changes['apiService'], () => this.apiSvc.setBaseService(this.apiService));

            this.initService(changes['routingService'], () => this.routingSvc.setBaseService(this.routingService));
        }
        catch (error) {
            console.error(error);
        }
    }

    private initService(change: SimpleChange, initFn: () => void) {
        if (change != null) {
            initFn();
        }
    }
}
