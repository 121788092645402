import {
    Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation
} from '@angular/core';
import { IDesignSectionExportItem, IDesignSectionExportStateChange } from '../../entities/design-section-export';
import { getValueFromValueOrFn, isPromise } from '../../helpers/types-helper';
import { LocalizationService } from '../../services/localization.service';
import { includeSprites, Sprite } from '../../sprites';

@Component({
    templateUrl: './design-section-export-items.component.html',
    styleUrls: ['./design-section-export-items.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DesignSectionExportItemsComponent implements OnInit, OnChanges {
    @Input()
    public items!: IDesignSectionExportItem[];

    @Output()
    public stateChange = new EventEmitter<IDesignSectionExportStateChange>();

    public getValueFromValueOrFn = getValueFromValueOrFn;
    public loading?: number;

    private isInitialized = false;


    constructor(
        public localizationService: LocalizationService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-arrow-right'
        );
    }

    ngOnChanges(): void {
        if (!this.isInitialized) {
            this.items.forEach((item) => {
                includeSprites(this.elementRef.nativeElement.shadowRoot, item.imageClass as Sprite);
            });

            this.isInitialized = true;
        }
    }

    public async itemClick(item: IDesignSectionExportItem) {
        this.loading = getValueFromValueOrFn(item.buttonLoading);
        this.emitLoadingChange();

        let result = false;
        const clickResult = item.buttonClick();
        if (isPromise(clickResult)) {
            result = await clickResult;
        }
        else {
            result = clickResult;
        }

        this.loading = undefined;
        this.emitLoadingChange(result);
    }

    public getMessage(item: IDesignSectionExportItem): string {
        if (item.message) {
            return getValueFromValueOrFn(item.message) ?? '';
        }

        if (item.messageKey) {
            return this.localizationService.getString(getValueFromValueOrFn(item.messageKey) ?? '');
        }

        return '';
    }

    public getDescription(item: IDesignSectionExportItem): string {
        if (item.description) {
            return getValueFromValueOrFn(item.description) ?? '';
        }

        if (item.descriptionKey) {
            return this.localizationService.getString(getValueFromValueOrFn(item.descriptionKey) ?? '');
        }

        return '';
    }


    private emitLoadingChange(buttonClickSuccess?: boolean) {
        this.stateChange.emit({
            loading: this.loading != null,
            buttonClickSuccess: buttonClickSuccess
        });
    }
}
