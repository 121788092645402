import { Injectable } from '@angular/core';

import { Design } from '../entities/design';
import { UserServiceInjected } from './user.common';

@Injectable({
    providedIn: 'root'
})
export class UserService extends UserServiceInjected<Design> {
}
