<pe-control-title
    [focusControlId]="id"
    [title]="title"
    [tooltip]="tooltip"
    [infoPopup]="infoPopup"
    [infoPopupTooltip]="infoPopupTooltip">
</pe-control-title>

<div [id]="id" class="checkbox-container" [attr.data-test-id]="groupItem.testId ? groupItem.testId : null" *ngFor="let groupItem of groupItems">
    <span class="title">{{groupItem.title}}</span>
    <label class="checkbox"
        *ngFor="let item of groupItem.items"
        [ngbTooltip]="ngbTooltipTemplate(item.tooltip, itemTooltipTemplate)"
        [disableTooltip]="item.tooltipTarget != null && item.tooltipTarget != tooltipTargetEnum.Item">
        <ng-template #itemTooltipTemplate>
            <pe-content-tooltip [tooltip]="item.tooltip"></pe-content-tooltip>
        </ng-template>

        <span class="checkbox-wrapper">
            <input class="checkbox-input" [attr.id]="item.id ? item.id : null" [disabled]="item.disabled || disabled"
                [checked]="selectedValues?.has(item.value)" (change)="onChange(item)" type="checkbox">
            <span class="checkbox-button pe-ui-common-sprite" [class.pe-ui-common-sprite-checkbox]="selectedValues?.has(item.value)"></span>
            <span class="checkbox-label" [attr.id]="item.id ? item.id + '-label': null">{{item.text}}</span>

            <span
                *ngIf="item.infoIcon != null && item.infoIcon != infoIconEnum.None"
                class="sprite sprite-info-tooltip"
                [class.info-tooltip-button]="item.infoIcon == infoIconEnum.Button"
                [ngbTooltip]="ngbTooltipTemplate(item.tooltip, itemTooltipTemplate)"
                [disableTooltip]="item.tooltipTarget == null || item.tooltipTarget != tooltipTargetEnum.InfoIcon"
                (click)="onItemInfoButtonClick($event, item)"></span>
        </span>

        <span class="checkbox-description" *ngIf="item.description">{{item.description}}</span>
    </label>
</div>
