import { UnitType as Unit, UnitGroup } from '../helpers/unit-helper';

export abstract class MathServiceBase {
    public abstract tryComputeUnitValue(value: string, unitGroup?: UnitGroup, unit?: Unit, uiProperty?: any, precision?: number): string;
}

export class MathServiceInjected extends MathServiceBase {
    public setBaseService(baseService: MathServiceBase) {
        this.tryComputeUnitValue = baseService.tryComputeUnitValue.bind(baseService);
    }

    // MathServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public tryComputeUnitValue(_value: string, _unitGroup?: UnitGroup, _unit?: Unit, _uiProperty?: any, _precision?: number) {
        return '';
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
