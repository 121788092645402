<ng-template #tooltipTemplate>
    <pe-content-tooltip [tooltip]="tooltip"></pe-content-tooltip>
</ng-template>

<div id="{{id}}" class="control label-control"
    [class.hilti-styled]="isHiltiStyled">
    <div class="control-header" *ngIf="title">
        <label
            class="control-label"
            [ngbTooltip]="ngbTooltipTemplate(tooltip, tooltipTemplate)">
            {{title}}
        </label>
    </div>

    <div class="text-label-container">
        <p class="text-label" [hidden]="isTextLinesPresent" *ngFor="let t of textLines">{{t}}</p>
    </div>
</div>
