<div class="notification-container notification-container-with-button notification-{{NotificationTypeName[getScopeCheckType(scopeCheck)]}}"
    *ngIf="isLoaded">
    <div class="notification-content-wrapper">
        <span
            class="notification-icon pe-ui-common-sprite pe-ui-common-sprite-{{NotificationTypeImage[getScopeCheckType(scopeCheck)]}}"
            [class.has-info-popup]="returnFn(scopeCheck.hasInfoButton)"
            (click)="infoButtonClicked(scopeCheck)">
        </span>
        <div class="notification-content notification-content-text"
            [innerHTML]="scopeCheck.message">
        </div>
    </div>
    <div class="notification-content notification-button">
        <div *ngFor="let actionButton of scopeCheck.actionButtons;">
            <button class="button button-default button-margin-top"
                *ngIf="actionButton.condition()"
                (click)="actionButton.click()" type="button"
                [disabled]="actionButton.disabled()"
                [ngbTooltip]="actionButton.tooltip"
                [disableTooltip]="returnFn(actionButton.disableTooltip)"
                [innerHtml]="actionButton.buttonTitle">
            </button>
        </div>
        <!-- support button -->
        <button class="button button-default button-margin-top"
            [l10n]="'Agito.Hilti.Profis3.Support.Button.Text'"
            *ngIf="returnFn(scopeCheck.hasSupportButton)"
            (click)="scopeCheck.supportButtonClick()" type="button">
        </button>
    </div>
</div>
