<div class="partial-screen-loader" *ngIf="shown">
    <div class="loader-content">
        <div *ngIf="hasText" class="loader-info" [innerHtml]="text"></div>

        <div class="loader-container">
            <pe-loading [width]="192" [height]="48"></pe-loading>
        </div>

        <button class="button button-primary cancel-button"
            type="button"
            (click)="cancel()"
            (activate)="cancel()"
            *ngIf="cancelFn != null"
            [innerHtml]="translate('Agito.Hilti.Profis3.LongCalculation.Cancel')">
        </button>
    </div>
</div>
