<div *ngFor="let item of items; let isLast = last">
    <div class="tooltip-wrapper"
        *ngIf="item.condition()"
        [ngbTooltip]="getValueFromValueOrFn(item.tooltip)"
        [disableTooltip]="getValueFromValueOrFn(item.tooltipDisabled) ?? false">
        <button class="export-button" (click)="itemClick(item)" type="button"
            [disabled]="item.buttonDisabled() || this.loading != null"
            [class.loading]="item.buttonLoading && loading == item.buttonLoading">
            <div class="report-wrapper" [class.last]="isLast">
                <div class="export-button-image">
                    <span class="image" [class]="item.imageClass" [ngStyle]="item.imageStyle"></span>
                </div>
                <div class="export-button-message">
                    <span class="export-button-message-name">{{getMessage(item)}}</span>
                    <span class="export-button-message-description" *ngIf="getDescription(item)">{{getDescription(item)}}</span>
                </div>
                <div class="export-button-caret">
                    <span class="pe-ui-common-sprite pe-ui-common-sprite-arrow-right"></span>
                </div>
            </div>

            <div class="export-loader-container" *ngIf="item.hasLoading">
                <pe-loading [color]="'#524f53'"></pe-loading>
            </div>
        </button>
    </div>
</div>
