import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IDesignSectionExportComponentInput, IDesignSectionExportStateChange } from '../../entities/design-section-export';
import { ModalInstance } from '../../helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';

@Component({
    templateUrl: './design-section-export.component.html',
    styleUrls: ['./design-section-export.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DesignSectionExportComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<IDesignSectionExportComponentInput>;

    public loading = false;
    public closing = false;

    constructor(
        public localizationService: LocalizationService
    ) { }


    public get items() {
        return this.modalInstance?.input?.exportItems;
    }


    ngOnInit(): void {
        // don't close the modal if an action is pending
        this.modalInstance?.setOnClosing(() => {
            if (!(!this.loading || this.closing)) {
                return false;
            }

            return true;
        });
    }

    public onStateChange(state: IDesignSectionExportStateChange) {
        this.loading = state.loading;
        if (state.buttonClickSuccess) {
            this.loading = false;
            this.close(true);
        }
    }

    public close(closing = false) {
        this.closing = closing;
        this.modalInstance.close();
    }
}
