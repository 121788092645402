import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { includeSprites } from 'src/common/sprites';
import { ModalInstance } from '../../helpers/modal-helper';
import { CloseButtonStyle, TitleStyle } from './modal-header.common';

@Component({
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ModalHeaderComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    @Input()
    public title = '';

    @Input()
    public subtitle = '';

    @Input()
    public showSubtitle = false;

    @Input()
    public closeButtonId = '';

    @Input()
    public closeButtonStyle = CloseButtonStyle.Close;

    @Input()
    public closeButtonDisabled = false;

    @Input()
    public enableClose = true;

    @Input()
    public preventDefaultClose = false;

    @Input()
    public titleStyle = TitleStyle.Uppercase;

    @Output()
    public closing = new EventEmitter<boolean>();

    constructor(
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get closeButtonClass() {
        if (this.closeButtonStyle == CloseButtonStyle.CloseImage) {
            return 'pe-ui-common-sprite pe-ui-common-sprite-close-image close-image';
        }

        return 'close';
    }

    public get titleClass() {
        return this.titleStyle == TitleStyle.Uppercase
            ? 'modal-title uppercase'
            : 'modal-title initial';
    }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-close-image'
        );
    }

    public close() {
        if (!this.preventDefaultClose) {
            this.modalInstance.close();
        }

        this.closing.emit(true);
    }
}
