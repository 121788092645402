import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { LocalizationServiceBase } from '../../services/localization.common';
import { IDisplayDesign } from '../../entities/display-design';
import { Observable } from 'rxjs';
import { Deferred } from '../../helpers/deferred';

export enum ExportReportRowStatus {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
}

export interface IExportReportRowResult {
    file?: Blob;
    displayDesign: IDisplayDesign;
}

export abstract class ExportReportRowBase {

    public static readonly MAX_REPORTS = 20;
    public static readonly MAX_CBFEM_REPORTS = 1;

    protected abstract displayDesign: IDisplayDesign;
    protected abstract exportReportCompleated: EventEmitter<IExportReportRowResult>;
    protected abstract deleteExport: EventEmitter<IDisplayDesign>;
    protected abstract cancelExport: Observable<boolean>;

    protected canceled = false;

    abstract get cancellationToken(): Deferred<void> | undefined;

    private _percentage = 0;
    private _message?: string;
    private _status?: ExportReportRowStatus;

    constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        protected localizationService: LocalizationServiceBase
    ) {
    }

    get percentage(): number {
        return this._percentage;
    }
    set percentage(value: number) {
        if (this.canceled || value < this._percentage) {
            return;
        }
        this._percentage = value;
        this.changeDetectorRef.detectChanges();
    }

    get message(): string | undefined {
        return this._message;
    }
    set message(value: string | undefined) {
        if (this.canceled) {
            return;
        }
        this._message = value;
        this.changeDetectorRef.detectChanges();
    }

    get status(): ExportReportRowStatus | undefined {
        return this._status;
    }
    set status(value: ExportReportRowStatus | undefined) {
        if (this.canceled) {
            return;
        }
        this._status = value;
        this.changeDetectorRef.detectChanges();
    }

    protected handleError(error: any) {
        this.percentage = 100;
        this.status = ExportReportRowStatus.Error;
        this.message = error?.message;
        this.exportResults();
    }

    protected canNotExportReport() {
        this.percentage = 100;
        this.status = ExportReportRowStatus.Error;
        this.message = this.translate('Agito.Hilti.Profis3.ExportReports.Message.CanNotExportReport');
        this.exportResults();
    }

    protected notPossibleReport() {
        this.percentage = 100;
        this.status = ExportReportRowStatus.Error;
        this.message = this.translate('Agito.Hilti.Profis3.ExportReports.Message.NotPossible');
        this.exportResults();
    }

    protected exportResults(blob?: Blob): void {
        if (!this.canceled) {
            this.exportReportCompleated.emit({
                displayDesign: this.displayDesign,
                file: blob
            });
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public removeRow() {
        this.deleteExport.emit(this.displayDesign);
    }

    public cancel(): void {
        if (this.percentage == 100) {
            return;
        }
        this.percentage = 100;
        this.status = ExportReportRowStatus.Error;
        this.message = this.translate('Agito.Hilti.Profis3.ExportReports.Message.Canceled');
        this.cancellationToken?.reject();
        this.exportResults();
        this.canceled = true;
    }
}
