import { TemplateRef } from '@angular/core';

export interface ContentTooltip {
    title?: string;
    content?: string;
}

export type NgbTooltipValue = string | TemplateRef<void>;
export type Tooltip = NgbTooltipValue | ContentTooltip;

export function isContentTooltip(value: unknown): value is ContentTooltip {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return typeof value == 'object' && value != null && 'title' in value && 'content' in value;
}

export function ngbTooltipTemplate(tooltip: Tooltip | undefined, tooltipTemplate: TemplateRef<void>): NgbTooltipValue {
    if (tooltip == undefined) {
        return '';
    }

    if (isContentTooltip(tooltip)) {
        return tooltipTemplate;
    }

    return tooltip;
}
