import { LocalizationService } from 'src/common/services/localization.service';

import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.ShadowDom,
  templateUrl: './loading-custom.component.html',
  styleUrls: ['./loading-custom.component.scss']
})
export class LoadingCustomComponent {
    constructor(
        private localizationService: LocalizationService
    ) { }

    @Input()
    public loadingText!: string;

    @Input()
    public shown = false;

    @Input()
    public cancelFn?: () => void;

    public get text() {
        return this.loadingText;
    }

    public get hasText() {
        return this.loadingText != null && this.loadingText != '';
    }

    public cancel() {
        if (this.cancelFn != null) {
            this.cancelFn();
        }

        this.shown = false;
    }

    public translate(translationKey: string) {
        return this.localizationService.getString(translationKey);
    }
}
