<div class="circle-loader">
    <div class="circle-wrapper"
        [class.progress-0]="loadingProgress < 51"
        [class.progress-51]="loadingProgress >= 51">
        <span class="label">{{loadingProgressDisplay}}%</span>
        <div class="circle">
            <div class="left-half half-circle"
                [ngStyle]="{'transform': 'rotate('+ (loadingProgress * 3.6) +'deg)'}"></div>
            <div class="right-half half-circle"></div>
        </div>
        <div class="shadow"></div>
    </div>
</div>
