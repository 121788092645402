<div class="dropdown-container" *ngIf="menu" ngbDropdown
    [container]="fixedPosition ? null : 'body'"
    [display]="fixedPosition ? 'static' : 'dynamic'"
    placement="bottom-left top-left right-top right-bottom"
    (openChange)="onMenuToggled?.($event)"
    (click)="setDropdownDirection($event)">
    <button [disabled]="disabled" class="toggle-button" ngbDropdownToggle>
        <div class="icon">
            <span class="{{icon}}"></span>
        </div>
        <span *ngIf="label" class="label">{{label}}</span>
    </button>

    <div class="container context-menu-dropdown-{{menuId}}" ngbDropdownMenu>
        <ng-container *ngFor="let option of menu; index as i">
            <ng-container
                *ngTemplateOutlet="option.label!=MENU_SEPERATOR ? Option : Seperator; context: { option: option, index: i}">
            </ng-container>
        </ng-container>

        <ng-template #Option let-option="option" let-index="index">
            <span [ngbTooltip]="option.toolTip">
                <button [id]="'Option-'+index+'-'+option.label" [attr.data-id]="'Option-'+index+'-'+option.label"
                    [disabled]="option.disabled" class="option" ngbDropdownItem (click)="onClick(option)">
                    <span *ngIf="option.icon" class="icon">
                        <span class="sprite-{{option.icon}} sprite" [ngClass]="{'disabled':option.disabled}"></span>
                    </span>
                    <span class="label">{{option.label}}</span>
                </button>
            </span>
        </ng-template>

        <ng-template #Seperator>
            <div class="seperator"></div>
        </ng-template>
    </div>
</div>
