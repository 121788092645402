import { SharedEnvironmentService } from 'src/common/services/shared-environment.service';
import { includeSprites } from 'src/common/sprites';

import {
    ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit,
    Output, TrackByFunction, ViewChild, ViewEncapsulation
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { CommonRegion } from '../../entities/code-lists/common-region';
import { LicenseSuite } from '../../entities/code-lists/license-suite';
import { Design } from '../../entities/design';
import {
    Feature, KnownRegion, LicenseSuiteType
} from '../../generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { format } from '../../helpers/string-helper';
import { AuthenticationService } from '../../services/authentication.service';
import { ButtonEventType } from '../../services/common-tracking.common';
import { CommonCodeList } from '../../services/common-code-list.common';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { MarketingService } from '../../services/marketing.service';
import { ModalService } from '../../services/modal.service';
import { OfflineService } from '../../services/offline.service';
import { ProductInformationService } from '../../services/product-information.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { FeaturesVisibilityService } from '../../services/features-visibility.service';
import { CloseVirtualTourMenuActions, OpenVirtualTourMenuActions } from '../../entities/main-header';

interface IRegionLink {
    id: string;
    url: string;
    nameResourceKey: string;
}

export enum UserOption {
    None,
    Settings,
    Support,
    Learn,
    Updates,
    Profile
}

@Component({
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.scss', './main-header-include.scss', './introjs.css'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MainHeaderComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public designName?: string;

    @Input()
    public designRegion?: CommonRegion;

    @Input()
    public useNewHomePage?: boolean;

    @Input()
    public eLearningVisible?: boolean;

    @Input()
    public displayLDFlags?: boolean;

    @Input()
    public openVirtualTourMenu = (action: OpenVirtualTourMenuActions) => this.openVirtualTourDropdown(action);

    @Input()
    public closeVirtualTourMenu = (action: CloseVirtualTourMenuActions) => this.closeVirtualTourDropdown(action);

    @Input()
    public showVirtualTour = () => this.showVirtualTourMenuDropdown();

    @Input()
    public beforeLogout?: () => Promise<void>;

    @Input()
    public openDesignSettings?: () => void;

    @Input()
    public openSaveAsTemplate?: () => void;

    @Input()
    public openSupportOverride?: () => void;

    @Input()
    public startTour?: () => void;

    @Input()
    public openGeneralNotes?: () => void;

    @Input()
    public openApplicationSettings?: () => void;

    @Input()
    public setIsInternalLicenseSwitchAvailable?: boolean = undefined;

    @Input()
    public isDisabled?: boolean = false;

    @Output()
    public menuOpened = new EventEmitter<void>();

    @Output()
    public hiltiDataPrivacyUrlOpened = new EventEmitter<void>();

    @Output()
    public regionLinkOpened = new EventEmitter<void>();

    @Output()
    public eLearningOpened = new EventEmitter<void>();

    @ViewChild('menuContainerDropdown', { static: false })
    public menuContainerDropdownRef!: NgbDropdown;

    @ViewChild('headerRightUserOptionsApplicationSettingsDropdown', { static: false })
    public headerRightUserOptionsApplicationSettingsDropdown!: NgbDropdown;

    @ViewChild('headerRightSupportMenuDropdown', { static: false }) public headerRightSupportMenuDropdown!: NgbDropdown;
    @ViewChild('headerRightLearnDropdown', { static: false }) public headerRightLearnDropdown!: NgbDropdown;
    @ViewChild('headerRightProfileDropdown', { static: false }) public headerRightProfileDropdown!: NgbDropdown;

    public design?: Design;
    public regionLinks?: IRegionLink[];
    public onlineTechnicalLibrary?: IRegionLink;
    public HiltiBIMCadLibrary?: IRegionLink;
    public userOption?: UserOption = UserOption.None;
    public menuOpen?: UserOption = UserOption.None;
    public menuClose?: UserOption = UserOption.None;
    virtualTourInProgress = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public localizationService: LocalizationService,
        public userSettingsService: UserSettingsService,
        public licenseService: LicenseService,
        public productInformationService: ProductInformationService,
        private featureVisibilityService: FeaturesVisibilityService,
        public offlineService: OfflineService,
        public commonCodeListService: CommonCodeListService,
        public userService: UserService,
        public featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        public commonTrackingService: CommonTrackingService,
        public modalService: ModalService,
        public marketingService: MarketingService,
        public authenticationService: AuthenticationService,
        private elementRef: ElementRef<HTMLElement>,
        private sharedEnvironmentService: SharedEnvironmentService,
        private ngZone: NgZone
    ) {
    }

    public get displayVersions() {
        return this.featureVisibilityService.isFeatureEnabled('VersionPageVisibility') || this.sharedEnvironmentService.data?.displayVersions;
    }

    public get displayNewHomePage() {
        if (this.useNewHomePage == null) {
            this.useNewHomePage = this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');
        }

        return this.useNewHomePage;
    }

    public get displayFeatureFlags() {
        if (this.displayLDFlags == null) {
            this.displayLDFlags = this.featureVisibilityService.isFeatureEnabled('LD_AutomatedPage');
        }

        return this.displayLDFlags;
    }

    public get comparisonRedirectHeaderUrl() {
        return this.productInformationService.regionLinksForUser()?.UpgradeToPremiumHeaderLink;
    }

    public get comparisonRedirectHeaderText() {
        return this.productInformationService.regionLinksTranslationsForUser?.UpgradeToPremiumHeaderText || this.productInformationService.regionLinksForUser()?.UpgradeToPremiumHeaderText;
    }

    public get licenseSuites() {
        return this.commonCodeListService.commonCodeLists[CommonCodeList.LicenseSuite] as LicenseSuite[];
    }

    public get globalRegion() {
        const regionCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(region => region.id == this.userSettingsService.settings.application.general.regionId.value);
    }

    public get regionLanguage() {
        return this.userSettingsService.getRegionLanguage();
    }

    public get isOpenSaveAsTemplateHidden() {
        return this.design?.isTemplate ||
            this.userService?.isCreateTemplate ||
            this.featuresVisibilityInfoService.isHidden(Feature.Application_DesignTemplate, this.globalRegion?.id);
    }

    public get isOpenSaveAsTemplateDisabled() {
        return this.design?.pendingCalculation ||
            this.featuresVisibilityInfoService.isDisabled(Feature.Application_DesignTemplate, this.globalRegion?.id) ||
            this.design?.isReadOnlyDesignMode;
    }

    public get isOpenDesignSettingsDisabled() {
        return this.design?.pendingCalculation || this.design?.isReadOnlyDesignMode;
    }

    public get isAskHiltiAvailable() {
        const link = this.getAskHiltiLink();
        return link != null && link != '';
    }

    public get showReleaseNotesDot() {
        return this.productInformationService.isNewReleaseNoteVersion();
    }

    public get showManageHiltiAccount() {
        const link = this.getManageHiltiAccountUrl();
        return link != null && link != '';
    }

    public get hasUpgradePELink() {
        return this.userSettingsService.getProfis3Url;
    }

    public get hasMarketingCampaigns() {
        return this.productInformationService.hasMarketingCampaigns();
    }

    public get showSubjectDataRights() {
        const regionLanguage = this.userSettingsService.getRegionLanguage();
        return regionLanguage?.dataSubjectRightsUrl != null && regionLanguage.dataSubjectRightsUrl != '';
    }

    public get isInternalLicenseSwitchAvailable() {
        if (this.setIsInternalLicenseSwitchAvailable != null && this.setIsInternalLicenseSwitchAvailable != undefined) {
            return this.setIsInternalLicenseSwitchAvailable;
        }

        return this.userService.isInternalLicenseSwitchAvailable;
    }

    public get internalLicenseSwitchButtonText() {
        const licenseType = this.isLicensePremium ? 'STANDARD' : 'PREMIUM';

        return format(this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Navigation.License.Switch'), licenseType);
    }

    public get generalNotesVisible() {
        if (this.designRegion == null) {
            return false;
        }

        const result =
            this.designRegion.id == KnownRegion.UnitedStates ||
            this.designRegion.id == KnownRegion.Canada ||
            this.designRegion.id == KnownRegion.Emirates ||
            this.designRegion.id == KnownRegion.SaudiArabia ||
            this.designRegion.id == KnownRegion.Qatar ||
            this.designRegion.id == KnownRegion.Kuwait ||
            this.designRegion.id == KnownRegion.Oman ||
            this.designRegion.id == KnownRegion.Bahrain;

        return result;
    }

    public get learn() {
        return UserOption.Learn;
    }

    public get support() {
        return UserOption.Support;
    }

    public get profile() {
        return UserOption.Profile;
    }

    public get isLicensePremium() {
        return this.licenseService.getLicense() === 'PREMIUM' && !this.userSettingsService.settings.application.general.forceFreeLicense.value;
    }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-hilti-logo',
            'sprite-lines',
            'sprite-settings',
            'sprite-new-document',
            'sprite-dots',
            'sprite-support',
            'sprite-e-learning',
            'sprite-notifications',
            'sprite-profile',
            'sprite-Settings-medium',
            'sprite-feedback',
            'sprite-contact',
            'sprite-resources',
            'sprite-shortcut-icon',
            'sprite-arrow',
            'sprite-trainings',
            'sprite-ask-hilti',
            'sprite-tour',
            'sprite-my-profile',
            'sprite-manage-account',
            'sprite-legal',
            'sprite-logout',
            'sprite-templates',
            'sprite-templates-faded',
            'sprite-right-arrow',
            'sprite-general-notes'
        );

        this.design = this.userService.design;
    }

    public ngOnChanges(): void {
        this.createRegionLinks();
        this.getLink();
    }

    public ngOnDestroy(): void {
        if (this.design != undefined) {
            // angular bug: template render is still called multiple times after ngOnDestroy for some reason
            // this causes errors since this.design is already null
            setTimeout(() => {
                this.design = undefined;
            }, 100);
        }
    }

    public upgradeLicense() {
        if (this.comparisonRedirectHeaderUrl != null) {
            const comparisonRedirectUrl = this.comparisonRedirectHeaderUrl
                .replace('{code}', this.licenseService.licenseSuiteItemCode(this.licenseSuites, LicenseSuiteType.Premium));

            if (!this.offlineService.isOffline) {
                window.open(comparisonRedirectUrl, '_blank');
            }
            else {
                this.offlineService.nativeExternalLinkOpen(comparisonRedirectUrl);
            }
        }
    }

    public openSaveAsTemplateTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Application_DesignTemplate) ||
            this.localizationService.getString('Agito.Hilti.Profis3.Main.SaveAsTemplate');
    }

    public openAskHilti() {
        if (this.virtualTourInProgress) {
            return;
        }

        if (this.isAskHiltiAvailable) {
            this.offlineService.nativeExternalLinkOpen(this.getAskHiltiLink() ?? '');
            this.commonTrackingService.trackOnButtonClick(ButtonEventType.AskHiltiClicked);
        }
        else {
            throw new Error('Ask Hilti is not available');
        }
    }

    public userName() {
        if (this.userService.isExternalOnlineRussianUser) {
            return this.userSettingsService.ccmsUserSettings != null ? this.userSettingsService.ccmsUserSettings.FullName : '';
        }
        else {
            return this.userSettingsService.settings.user.generalName.value?.trim()
                ? this.userSettingsService.settings.user.generalName.value
                : this.userService.getUserName();
        }
    }

    public menuToggled(open: boolean, userOption?: UserOption) {
        if (open) {
            this.menuOpened.emit();

            if (this.menuOpen != userOption) {
                this.menuOpen = this.userOption = userOption;
            } else {
                this.userOption = UserOption.None;
            }
        } else {
            this.menuClose = userOption;

            if (this.menuClose == this.menuOpen) {
                this.userOption = this.menuOpen = UserOption.None;
            }
        }
    }

    public openUserSettings() {
        if (this.virtualTourInProgress) {
            return;
        }

        // TODO: BUDQBP-23561 ??
        this.modalService.openUserSettings();
    }

    public openVersionDetails() {
        this.modalService.openVersionDetails();
    }

    public openLDFlags() {
        this.modalService.openLDFlags();
    }

    public openManageHiltiAccount() {
        if (this.virtualTourInProgress) {
            return;
        }

        // TODO: BUDQBP-23561 ??
        if (this.showManageHiltiAccount) {
            this.offlineService.nativeExternalLinkOpen(this.getManageHiltiAccountUrl() ?? '');
        }
        else {
            throw new Error('Manage Hilti account is not available');
        }
    }

    public openShortcutIconPopup() {
        if (this.virtualTourInProgress) {
            return;
        }

        // TODO: BUDQBP-23561
        this.modalService.openShortcutIcon();
    }

    public openFeedbackForm() {
        if (this.virtualTourInProgress) {
            return;
        }

        this.modalService.openFeedBackForm();
    }

    public openWhatsNew() {
        this.productInformationService.getReleaseNotesFromService(this.localizationService.selectedLanguage)
            .then((response) => {
                this.modalService.openWhatsNew(response);
                this.productInformationService.setNewReleaseNoteVersion();
                this.commonTrackingService.trackOnButtonClick(ButtonEventType.WhatsNewClicked);
            });
    }

    public openUpgradePE() {
        if (this.hasUpgradePELink) {
            // TODO: BUDQBP-23561

            if (this.regionLanguage.getProfis3Url != null) {
                this.offlineService.nativeExternalLinkOpen(this.regionLanguage.getProfis3Url);
            }
        }
    }

    public openMarketingCampaigns() {
        // TODO: BUDQBP-23561

        this.productInformationService.openCampaigns();
    }

    public openReportTemplates() {
        if (this.virtualTourInProgress) {
            return;
        }

        // TODO: BUDQBP-23561
        this.modalService.openReportTemplates();
    }

    public isSupportLinkHidden() {
        return this.featuresVisibilityInfoService.isHidden(Feature.Application_SupportButton, this.globalRegion?.id);
    }

    public isSupportLinkDisabled() {
        return this.featuresVisibilityInfoService.isDisabled(Feature.Application_SupportButton, this.globalRegion?.id);
    }

    public supportLinkTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Application_SupportButton) ||
            this.localizationService.getString('Agito.Hilti.Profis3.Main.DesignOptions.Support.Tooltip');
    }

    public openSupportLink() {
        if (this.isSupportLinkHidden() || this.isSupportLinkDisabled() || this.virtualTourInProgress) {
            return;
        }

        // TODO: BUDQBP-23561

        if (this.openSupportOverride != null)
            this.openSupportOverride();
        else
            this.modalService.openSupport();
    }

    public openUserManual() {
        if (this.virtualTourInProgress) {
            return;
        }

        // TODO: BUDQBP-23561
        this.offlineService.nativeExternalLinkOpen(this.marketingService.getUserManualForSelectedLanguage());
    }

    public startVirtualTour() {
        if (this.virtualTourInProgress) {
            return;
        }

        if (this.startTour) {
            this.startTour();
        }
    }

    public openHiltiDataPrivacyUrl() {
        if (this.virtualTourInProgress) {
            return;
        }

        if (this.regionLanguage.hiltiDataPrivacyUrl == null) {
            return;
        }

        // TODO: BUDQBP-23561

        this.offlineService.nativeExternalLinkOpen(this.regionLanguage.hiltiDataPrivacyUrl);
        this.hiltiDataPrivacyUrlOpened.emit();
    }

    public openUserAgreementSettings() {
        if (this.virtualTourInProgress) {
            return;
        }

        // TODO: BUDQBP-23561

        this.modalService.openUserAgreementSettings();
    }

    public openUserAgreementDocument() {
        if (this.virtualTourInProgress) {
            return;
        }

        if (this.regionLanguage.userAgreement == null) {
            return;
        }

        // TODO: BUDQBP-23561

        return this.offlineService.nativeExternalLinkOpen(this.regionLanguage.userAgreement);
    }

    public open3rdPartyLibraries() {
        if (this.virtualTourInProgress) {
            return;
        }

        const thirdPartyLib = 'PROFIS_licenses.pdf';
        return this.offlineService.nativeLocalFileOpen(thirdPartyLib);
    }

    public openSubjectDataRights() {
        if (this.virtualTourInProgress) {
            return;
        }

        // TODO: BUDQBP-23561

        const regionLanguage = this.userSettingsService.getRegionLanguage();
        if (regionLanguage.dataSubjectRightsUrl != null) {
            this.offlineService.nativeExternalLinkOpen(regionLanguage.dataSubjectRightsUrl);
        }
    }

    public switchInternalLicense() {

        // save setting
        this.userSettingsService.settings.application.general.forceFreeLicense.value = this.userSettingsService.settings.application.general.forceFreeLicense.value != null
            ? !this.userSettingsService.settings.application.general.forceFreeLicense.value : !this.licenseService.isStandardLicense();

        this.userSettingsService.save()
            .then(() =>
                // track event
                this.commonTrackingService.trackOnButtonClick(ButtonEventType.SwitchInternalLicenseClicked))
            .finally(() =>
                // reload to get correct initial data
                window.location.reload()
            );
    }

    public regionLinksTrackBy: TrackByFunction<IRegionLink> = function (this: MainHeaderComponent, index, item) {
        return item.id;
    };

    public onLinkClick(link: IRegionLink) {
        if (this.virtualTourInProgress) {
            return;
        }

        this.regionLinkOpened.emit();

        // TODO: BUDQBP-23561

        const bimCadLibLanguage = this.localizationService.selectedBimCadLibLanguage;

        this.offlineService.nativeExternalLinkOpen(link.url.trim() + '?Mandat=' + bimCadLibLanguage.Section + '&Cul=' + bimCadLibLanguage.Culture);
    }

    public async logout() {
        if (this.virtualTourInProgress) {
            return;
        }

        if (this.beforeLogout != null) {
            try {
                await this.beforeLogout();
            }
            finally {
                this.authenticationService.logout();
            }
        }
        else {
            this.authenticationService.logout();
        }
    }

    private getAskHiltiLink() {
        return this.productInformationService.regionLinksForUser()?.AskHiltiLink;
    }

    private getManageHiltiAccountUrl() {
        return this.productInformationService.regionLinksForUser()?.ManageAccountLink;
    }

    private createRegionLinks() {
        this.regionLinks = [];

        const region = this.designRegion ?? this.globalRegion;

        // Add link that contains an actual link/url
        if (region?.onlineTechnicalLibraryUrl != null) {
            this.regionLinks.push({
                id: 'OnlineTechnicalLibraryUrl',
                nameResourceKey: `Agito.Hilti.Profis3.Region.Link.OnlineTechnicalLibraryUrl`,
                url: region.onlineTechnicalLibraryUrl,
            });
        }

        if (region?.hiltiBimCadLibraryUrl != null) {
            this.regionLinks.push({
                id: 'HiltiBimCadLibraryUrl',
                nameResourceKey: `Agito.Hilti.Profis3.Region.Link.HiltiBimCadLibraryUrl`,
                url: region.hiltiBimCadLibraryUrl,
            });
        }

        if (region?.profis3KBUrl != null && region?.profis3KBUrl != '') {
            this.regionLinks.push({
                id: 'KBUrl',
                nameResourceKey: `Agito.Hilti.Profis3.Region.Link.KnowledgeBaseUrl`,
                url: region.profis3KBUrl,
            });
        }
    }

    public getLink() {
        this.regionLinks?.forEach((x) => {
            if (x.id === 'OnlineTechnicalLibraryUrl')
                this.onlineTechnicalLibrary = x;
            if (x.id === 'HiltiBimCadLibraryUrl')
                this.HiltiBIMCadLibrary = x;
        });
    }

    public openELearning() {
        if (this.virtualTourInProgress) {
            return;
        }

        this.eLearningOpened.emit();
    }

    /*
    * BUDQBP-36944 TEMP HACK: this is a temporary hack to get special ask hilti text for US and Canada.
    * Code should be removed once proper solution is implemented via admin console.
    */
    public get askHiltiText() {
        if(this.globalRegion?.id == KnownRegion.UnitedStates || this.globalRegion?.id == KnownRegion.Canada) {
            return this.localizationService.getString('Common.Support.Link.EngineeringCenter');
        }
        return this.localizationService.getString('Agito.Hilti.Profis3.Region.Link.AskHilti');
    }

    private openVirtualTourDropdown(action: OpenVirtualTourMenuActions) {
        switch (action) {
            case 'OpenApplicationSettings':
                if (this.openApplicationSettings != undefined) {
                    this.openApplicationSettings();
                }
                break;
            case 'OpenSupportMenu':
                this.runInAngularZone(() => {
                    this.headerRightSupportMenuDropdown.autoClose = false;
                    this.headerRightSupportMenuDropdown.open();
                });
                break;
            case 'OpenLearnMenu':
                this.runInAngularZone(() => {
                    this.headerRightLearnDropdown.autoClose = false;
                    this.headerRightLearnDropdown.open();
                });
                break;
            case 'OpenProfileMenu':
                this.runInAngularZone(() => {
                    this.headerRightProfileDropdown.autoClose = false;
                    this.headerRightProfileDropdown.open();
                });
                break;
            default:
                throw new Error(`Invalid open virtual tour menu action ${action}`);
        }
        this.virtualTourInProgress = true;
    }
    private closeVirtualTourDropdown(action: CloseVirtualTourMenuActions) {
        switch (action) {
            case 'CloseSupportMenu':
                this.runInAngularZone(() => {
                    this.headerRightSupportMenuDropdown.autoClose = true;
                    this.headerRightSupportMenuDropdown.close();
                });
                break;
            case 'CloseLearnMenu':
                this.runInAngularZone(() => {
                    this.headerRightLearnDropdown.autoClose = true;
                    this.headerRightLearnDropdown.close();
                });
                break;
            case 'CloseProfileMenu':
                this.runInAngularZone(() => {
                    this.headerRightProfileDropdown.autoClose = true;
                    this.headerRightProfileDropdown?.close();
                });
                break;
            case 'CloseAll':
                this.runInAngularZone(() => {
                    this.headerRightSupportMenuDropdown.autoClose = true;
                    this.headerRightLearnDropdown.autoClose = true;
                    this.headerRightProfileDropdown.autoClose = true;
                    this.headerRightSupportMenuDropdown.close();
                    this.headerRightLearnDropdown.close();
                    this.headerRightProfileDropdown.close();
                });
                break;
            default:
                throw new Error(`Invalid open virtual tour menu action ${action}`);
        }
        this.virtualTourInProgress = false;
    }
    private runInAngularZone(fn: () => void) {
        if (NgZone.isInAngularZone()) {
            fn();
        } else {
            this.ngZone.run(fn);
        }
    }
    private showVirtualTourMenuDropdown() {
        this.menuContainerDropdownRef?.open();
        this.headerRightUserOptionsApplicationSettingsDropdown?.open();
        this.changeDetectorRef.detectChanges();
    }
}
