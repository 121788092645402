import { Component, ElementRef, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';

import {
    INotificationScopeCheck, NotificationTypeImage, NotificationTypeName
} from '../../entities/notifications';
import { includeSprites } from '../../sprites';

@Component({
    templateUrl: './notification-scope-check.component.html',
    styleUrls: ['./notification-scope-check.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class NotificationScopeCheckComponent implements OnInit, OnChanges {
    @Input()
    public scopeCheck!: INotificationScopeCheck;


    public NotificationTypeImage = NotificationTypeImage;
    public NotificationTypeName = NotificationTypeName;
    public isLoaded = false;

    constructor(
        private elementRef: ElementRef<HTMLElement>
    ) { }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-info',
            'sprite-warning'
        );
    }

    ngOnChanges(): void {
        if (!this.isLoaded) {
            this.isLoaded = true;
        }
    }

    public getScopeCheckType(scopeCheck: INotificationScopeCheck) {
        // tslint:disable-next-line: no-bitwise
        return scopeCheck.type;
    }

    public returnFn(fn?: () => boolean) {
        if (fn != undefined) {
            return fn();
        }

        return false;
    }

    public infoButtonClicked(scopeCheck: INotificationScopeCheck) {
        if (scopeCheck.hasInfoButton)
            return scopeCheck.infoButtonClick();
    }
}
