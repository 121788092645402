import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { APP_BASE_HREF } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDropdownModule, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ButtonComponent } from './components/button/button.component';
import {
    CheckboxButtonGroupComponent
} from './components/checkbox-button-group/checkbox-button-group.component';
import { CheckboxButtonComponent } from './components/checkbox-button/checkbox-button.component';
import { CircleLoaderComponent } from './components/circle-loader/circle-loader.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { ContentTooltipComponent } from './components/content-tooltip/content-tooltip.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { ControlTitleComponent } from './components/control-title/control-title.component';
import { DesignSectionExportItemsComponent } from './components/design-section-export-items/design-section-export-items.component';
import {
    DesignSectionExportComponent
} from './components/design-section-export/design-section-export.component';
import {
    DesignValidationErrorComponent
} from './components/design-validation-error/design-validation-error.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import {
    ExportReportCompanyLayoutComponent
} from './components/export-report-company-layout/export-report-company-layout.component';
import {
    ExportReportProjectDetailsComponent
} from './components/export-report-project-details/export-report-project-details.component';
import {
    ExportReportRowBaseComponent
} from './components/export-report-row-base/export-report-row-base.component';
import {
    ExportReportSpecificationTextComponent
} from './components/export-report-specification-text/export-report-specification-text.component';
import {
    ExportTrimbleConnectComponent
} from './components/export-trimble-connect/export-trimble-connect.component';
import { InfoLinkButtonComponent } from './components/info-link-button/info-link-button.component';
import { LabelComponent } from './components/label/label.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { LoadingCustomComponent } from './components/loading-custom/loading-custom.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoadsLegendComponent } from './components/loads-legend/loads-legend.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { ModalBodyComponent } from './components/modal-body/modal-body.component';
import { ModalFooterComponent } from './components/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import {
    MultiselectDropdownComponent
} from './components/multiselect-dropdown/multiselect-dropdown.component';
import {
    NotificationScopeCheckComponent
} from './components/notification-scope-check/notification-scope-check.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NumericTextBoxComponent } from './components/numeric-text-box/numeric-text-box.component';
import { PerfectSpecLogoComponent } from './components/perfect-spec-logo/perfect-spec-logo.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { ResizerComponent } from './components/resizer/resizer.component';
import { SectionComponent } from './components/section/section.component';
import { SliderComponent } from './components/slider/slider.component';
import {
    SpecificationTextComponent
} from './components/specification-text/specification-text.component';
import { SwitchButtonComponent } from './components/switch-button/switch-button.component';
import { TabComponent } from './components/tab/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { TextBoxComponent } from './components/text-box/text-box.component';
import {
    ToggleButtonGroupComponent
} from './components/toggle-button-group/toggle-button-group.component';
import { UiCommonInitComponent } from './components/ui-common-init/ui-common-init.component';
import { UtilizationBarComponent } from './components/utilization-bar/utilization-bar.component';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';
import { L10nDirective } from './directive/l10n.directive';
import { L10nPipe } from './pipes/l10n.pipe';

@NgModule({
    declarations: [
        UiCommonInitComponent,
        ButtonComponent,
        CheckboxButtonComponent,
        CheckboxButtonGroupComponent,
        CircleLoaderComponent,
        CollapseComponent,
        ContentTooltipComponent,
        ContextMenuComponent,
        ControlTitleComponent,
        DesignSectionExportComponent,
        DesignSectionExportItemsComponent,
        DesignValidationErrorComponent,
        DropdownComponent,
        ExportReportCompanyLayoutComponent,
        ExportReportProjectDetailsComponent,
        ExportReportRowBaseComponent,
        ExportReportSpecificationTextComponent,
        ExportTrimbleConnectComponent,
        InfoLinkButtonComponent,
        L10nDirective,
        L10nPipe,
        LabelComponent,
        LoadingButtonComponent,
        LoadingComponent,
        LoadingCustomComponent,
        LoadsLegendComponent,
        MainHeaderComponent,
        ModalBodyComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        MultiselectDropdownComponent,
        NotificationComponent,
        NotificationsComponent,
        NotificationScopeCheckComponent,
        NumericTextBoxComponent,
        PerfectSpecLogoComponent,
        RadioButtonComponent,
        ResizerComponent,
        SectionComponent,
        SliderComponent,
        SpecificationTextComponent,
        SwitchButtonComponent,
        TabComponent,
        TabsComponent,
        TextAreaComponent,
        TextBoxComponent,
        ToggleButtonGroupComponent,
        UtilizationBarComponent,
        ValidationErrorComponent,
    ],
    imports: [
        BrowserModule,
        NgbTooltipModule,
        NgbDropdownModule,
        FormsModule,
        NgxSliderModule
    ],
    providers: [{ provide: APP_BASE_HREF, useValue: '/cdn/pe-ui-common/' }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(
        private injector: Injector,
        ngbTooltipConfig: NgbTooltipConfig
    ) {
        this.configureTooltip(ngbTooltipConfig);

        this.registerCustomElement('button', ButtonComponent);
        this.registerCustomElement('checkbox-button', CheckboxButtonComponent);
        this.registerCustomElement('checkbox-button-group', CheckboxButtonGroupComponent);
        this.registerCustomElement('circle-loader', CircleLoaderComponent);
        this.registerCustomElement('collapse', CollapseComponent);
        this.registerCustomElement('content-tooltip', ContentTooltipComponent);
        this.registerCustomElement('context-menu', ContextMenuComponent);
        this.registerCustomElement('control-title', ControlTitleComponent);
        this.registerCustomElement('design-section-export', DesignSectionExportComponent);
        this.registerCustomElement('design-section-export-items', DesignSectionExportItemsComponent);
        this.registerCustomElement('design-validation-error', DesignValidationErrorComponent);
        this.registerCustomElement('dropdown', DropdownComponent);
        this.registerCustomElement('export-report-company-layout', ExportReportCompanyLayoutComponent);
        this.registerCustomElement('export-report-project-details', ExportReportProjectDetailsComponent);
        this.registerCustomElement('export-report-row-base', ExportReportRowBaseComponent);
        this.registerCustomElement('export-report-specification-text', ExportReportSpecificationTextComponent);
        this.registerCustomElement('export-trimble-connect', ExportTrimbleConnectComponent);
        this.registerCustomElement('info-link-button', InfoLinkButtonComponent);
        this.registerCustomElement('label', LabelComponent);
        this.registerCustomElement('loading', LoadingComponent);
        this.registerCustomElement('loading-button', LoadingButtonComponent);
        this.registerCustomElement('loading-custom', LoadingCustomComponent);
        this.registerCustomElement('loads-legend', LoadsLegendComponent);
        this.registerCustomElement('main-header', MainHeaderComponent);
        this.registerCustomElement('modal-body', ModalBodyComponent);
        this.registerCustomElement('modal-footer', ModalFooterComponent);
        this.registerCustomElement('modal-header', ModalHeaderComponent);
        this.registerCustomElement('multiselect-dropdown', MultiselectDropdownComponent);
        this.registerCustomElement('notification', NotificationComponent);
        this.registerCustomElement('notification-scope-check', NotificationScopeCheckComponent);
        this.registerCustomElement('notifications', NotificationsComponent);
        this.registerCustomElement('numeric-text-box', NumericTextBoxComponent);
        this.registerCustomElement('perfect-spec-logo', PerfectSpecLogoComponent);
        this.registerCustomElement('radio-button', RadioButtonComponent);
        this.registerCustomElement('resizer', ResizerComponent);
        this.registerCustomElement('section', SectionComponent);
        this.registerCustomElement('slider', SliderComponent);
        this.registerCustomElement('specification-text', SpecificationTextComponent);
        this.registerCustomElement('tab', TabComponent);
        this.registerCustomElement('tabs', TabsComponent);
        this.registerCustomElement('text-area', TextAreaComponent);
        this.registerCustomElement('text-box', TextBoxComponent);
        this.registerCustomElement('toggle-button-group', ToggleButtonGroupComponent);
        this.registerCustomElement('ui-common-init', UiCommonInitComponent);
        this.registerCustomElement('utilization-bar', UtilizationBarComponent);
        this.registerCustomElement('validation-error', ValidationErrorComponent);
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @angular-eslint/use-lifecycle-interface, @typescript-eslint/no-empty-function
    public ngDoBootstrap() {
        // Nothing to do.
    }

    private configureTooltip(config: NgbTooltipConfig) {
        config.container = 'body';
        config.triggers = 'hover';
        config.openDelay = 500;
    }

    private registerCustomElement(componentName: string, component: Type<any>) {
        const customElement = createCustomElement(component, { injector: this.injector });
        customElements.define(`pe-${componentName}`, customElement);
    }
}
