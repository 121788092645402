<div class="section export-report-company-layout" *ngIf="isLoaded">
    <div class="section-row">
        <div class="section-column separator company-layout-left">
            <div class="template control-row">
                <pe-dropdown class="select-template"
                    [id]="templateDropdown.id"
                    [title]="'Agito.Hilti.Profis3.ExportReport.Layout.Template' | l10n: localizationService.selectedLanguage"
                    [items]="templateDropdown.items"
                    [selectedValue]="templateDropdown.selectedValue"
                    (selectedValueChange)="templateDropdown.selectedValue = $any($event).detail; onSelectedTemplateChange()"
                    [validators]="requiredValidator"
                    [disabled]="submitted">
                </pe-dropdown>

                <pe-button
                    [id]="'export-report-layout-section-template-edit-button'"
                    [look]="'Default'"
                    class="edit-template"
                    (buttonClick)="editTemplate()"
                    [text]="'Agito.Hilti.Profis3.ReportTemplates.Edit' | l10n: localizationService.selectedLanguage"
                    [disabled]="submitted">
                </pe-button>
            </div>
        </div>
        <div class="section-column separator company-layout-middle"
            id="export-report-layout-section-companyinfo-subsection" *ngIf="!isExternalOnlineRussianUser">
            <div class="company-info control-row" *ngIf="reportTemplate != null">
                <p class="control-label" l10n="Agito.Hilti.Profis3.ExportReport.Layout.CompanyInfo"></p>
                <p class="text-label data-item" *ngIf="reportTemplate.Company">{{reportTemplate.Company}}</p>
                <p class="text-label data-item" *ngIf="reportTemplate.Address">{{reportTemplate.Address}}</p>
                <p class="text-label data-item" *ngIf="reportTemplate.ContactPerson">{{reportTemplate.ContactPerson}}</p>
                <p class="text-label data-item" *ngIf="reportTemplate.PhoneNumber">{{reportTemplate.PhoneNumber}}</p>
                <p class="text-label data-item" *ngIf="reportTemplate.Email">{{reportTemplate.Email}}</p>
            </div>

            <pe-text-box class="company-text-box control-row"
                [id]="'export-report-layout-section-company-textbox'"
                [title]="'Agito.Hilti.Profis3.ExportReport.Layout.Company' | l10n: localizationService.selectedLanguage"
                [value]="reportCompanyName"
                (valueChange)="reportCompanyName = $any($event).detail; reportCompanyNameChange.emit($any($event).detail)"
                [disabled]="submitted" [maxLength]="255" *ngIf="reportTemplate == null">
            </pe-text-box>
            <pe-text-box class="address-text-box control-row"
                [id]="'export-report-layout-section-adress-textbox'"
                [title]="'Agito.Hilti.Profis3.ExportReport.Layout.Address' | l10n: localizationService.selectedLanguage"
                [value]="reportAddress"
                (valueChange)="reportAddress = $any($event).detail; reportAddressChange.emit($any($event).detail)"
                [disabled]="submitted" [maxLength]="255" *ngIf="reportTemplate == null">
            </pe-text-box>
            <pe-text-box class="contact-person-text-box control-row"
                [id]="'export-report-layout-section-contactperson-textbox'"
                [title]="'Agito.Hilti.Profis3.ExportReport.Layout.ContactPerson' | l10n: localizationService.selectedLanguage"
                [value]="reportContactPerson"
                (valueChange)="reportContactPerson = $any($event).detail; reportContactPersonChange.emit($any($event).detail)"
                [disabled]="submitted" [maxLength]="255" *ngIf="reportTemplate == null">
            </pe-text-box>
            <pe-text-box class="phone-number-text-box control-row"
                [id]="'export-report-layout-section-phonenumber-textbox'"
                [title]="'Agito.Hilti.Profis3.ExportReport.Layout.PhoneNumber' | l10n: localizationService.selectedLanguage"
                [value]="reportPhoneNumber"
                (valueChange)="reportPhoneNumber = $any($event).detail; reportPhoneNumberChange.emit($any($event).detail)"
                [disabled]="submitted" [maxLength]="255" *ngIf="reportTemplate == null">
            </pe-text-box>
            <pe-text-box class="email-text-box control-row" [id]="'export-report-layout-section-email-textbox'"
                [title]="'Agito.Hilti.Profis3.ExportReport.Layout.Email' | l10n: localizationService.selectedLanguage"
                [value]="reportEmail"
                (valueChange)="reportEmail = $any($event).detail; reportEmailChange.emit($any($event).detail)"
                (isValid)="reportEmailValid.emit($any($event).detail)"
                [disabled]="submitted" [maxLength]="255"
                [validators]="emailValidator"
                *ngIf="reportTemplate == null">
            </pe-text-box>
        </div>
        <div class="section-column separator company-layout-middle" *ngIf="isExternalOnlineRussianUser">
            <div class="company-info control-row" *ngIf="userSettingsService.ccmsUserSettings != null">
                <p class="control-label" l10n="Agito.Hilti.Profis3.ExportReport.Layout.CompanyInfo"></p>
                <p class="text-label data-item" *ngIf="userSettingsService.ccmsUserSettings.CompanyName">
                    {{userSettingsService.ccmsUserSettings.CompanyName}}</p>
                <p class="text-label data-item" *ngIf="userSettingsService.ccmsUserSettings.Address">
                    {{userSettingsService.ccmsUserSettings.Address}}</p>
                <p class="text-label data-item" *ngIf="userSettingsService.ccmsUserSettings.FullName">
                    {{userSettingsService.ccmsUserSettings.FullName}}</p>
                <p class="text-label data-item" *ngIf="userSettingsService.ccmsUserSettings.Phone">
                    {{userSettingsService.ccmsUserSettings.Phone}}</p>
                <p class="text-label data-item" *ngIf="userSettingsService.ccmsUserSettings.EmailAddress">
                    {{userSettingsService.ccmsUserSettings.EmailAddress}}</p>
            </div>
        </div>
        <div class="section-column company-layout-right">
            <pe-dropdown class="report-type-dropdown control-row" [id]="reportTypeDropdown.id"
                [items]="reportTypeDropdown.items"
                [selectedValue]="reportTypeDropdown.selectedValue"
                (selectedValueChange)="reportTypeDropdown.selectedValue = $any($event).detail; onReportTypeChange(undefined)"
                [title]="'Agito.Hilti.Profis3.ExportReport.Layout.ReportType' | l10n: localizationService.selectedLanguage"
                [disabled]="submitted"
                *ngIf="reportTemplate == null">
            </pe-dropdown>
            <pe-dropdown class="language-dropdown control-row"
                [id]="languageDropdown.id"
                [title]="'Agito.Hilti.Profis3.ExportReport.Layout.Language' | l10n: localizationService.selectedLanguage"
                [items]="languageDropdown.items"
                [selectedValue]="languageDropdown.selectedValue"
                (selectedValueChange)="languageDropdown.selectedValue = $any($event).detail"
                [validators]="requiredValidator"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="paper-size-dropdown control-row" [id]="paperSizeDropdown.id"
                [items]="paperSizeDropdown.items"
                [selectedValue]="paperSizeDropdown.selectedValue"
                (selectedValueChange)="paperSizeDropdown.selectedValue = $any($event).detail"
                [title]="'Agito.Hilti.Profis3.ExportReport.Layout.PaperSize' | l10n: localizationService.selectedLanguage"
                [disabled]="submitted"
                *ngIf="reportTemplate == null">
            </pe-dropdown>

            <div class="first-page-number-input-container control-row">
                <div class="control-header">
                    <pe-label [title]="'Agito.Hilti.Profis3.ExportReport.Layout.FirstPage' | l10n: localizationService.selectedLanguage"></pe-label>
                </div>

                <div class="stepper-container">
                    <pe-numeric-text-box [id]="'export-report-layout-section-startpagenumbering-textbox'"
                        [value]="firstPageNumber"
                        (valueChange)="changeFirstPageNumber($any($event).detail)"
                        [minValue]="1" [maxValue]="maxSafeInteger"
                        [disabled]="submitted" [type]="'text'">
                    </pe-numeric-text-box>
                </div>
            </div>

            <pe-dropdown class="load-combination control-row" [id]="loadCombinationDropdown.id"
                [items]="loadCombinationDropdown.items"
                [selectedValue]="loadCombinationDropdown.selectedValue"
                (selectedValueChange)="loadCombinationDropdown.selectedValue = $any($event).detail"
                [title]="'Agito.Hilti.Profis3.ExportReport.Layout.LoadCombination' | l10n: localizationService.selectedLanguage"
                [disabled]="submitted" [tooltip]="loadCombinationDropdown.tooltip"
                *ngIf="isLoadCombinationDropdownVisible" [openUp]="true">
            </pe-dropdown>

            <pe-dropdown class="load-case control-row" [id]="loadCaseDropdown.id"
                [items]="loadCaseDropdown.items"
                [selectedValue]="loadCaseDropdown.selectedValue"
                (selectedValueChange)="loadCaseDropdown.selectedValue = $any($event).detail"
                [title]="'Agito.Hilti.Profis3.ExportReport.Layout.LoadCase' | l10n: localizationService.selectedLanguage"
                [disabled]="submitted"
                [tooltip]="loadCaseDropdown.tooltip"
                *ngIf="displayLoadCaseDropdown">
            </pe-dropdown>

            <pe-dropdown class="load-case-handrail-design control-row" [id]="loadCaseHandrailDropdown.id"
                [items]="loadCaseHandrailDropdown.items"
                [selectedValue]="loadCaseHandrailDropdown.selectedValue"
                (selectedValueChange)="loadCaseHandrailDropdown.selectedValue = $any($event).detail"
                [title]="'Agito.Hilti.Profis3.ExportReport.Layout.LoadCaseHandrail' | l10n: localizationService.selectedLanguage"
                [disabled]="submitted || !handrailSafetyDesign" [tooltip]="loadCaseHandrailDropdown.tooltip"
                *ngIf="handrailSafetyDesign">
            </pe-dropdown>

            <div class="format control-row" *ngIf="reportTemplate != null"
                id="export-report-layout-section-format-lable">
                <p class="control-label" l10n="Agito.Hilti.Profis3.ExportReport.Layout.Format"></p>
                <p class="text-label data-item" *ngIf="formatedReportType">{{formatedReportType}}</p>
                <p class="text-label data-item" *ngIf="formatedPaperSize">{{formatedPaperSize}}</p>
            </div>
        </div>
    </div>
</div>
