<div class="design-validation-error box-section box-section-white" *ngIf="validationError">
    <div class="box-section-header">
        <span class="box-section-title drag-handle-static" l10n="Agito.Hilti.Profis3.ValidationError"
            [ngbTooltip]="'Agito.Hilti.Profis3.Main.Region.DragToMove' | l10n: localizationService.selectedLanguage"
            [class.flex]="!collapsed"></span>
        <div *ngIf="collapsed">
            <pe-collapse
                [container]="'box-section-content'"
                [collapsed]="collapsed"
                (collapsedChange)="onCollapsedChanged($any($event).detail)">
            </pe-collapse>
        </div>
    </div>

    <div class="box-section-content">
        <button class="message" type="button" (click)="click()">{{validationError.Message}}</button>
    </div>
</div>
