<div class="dropdown-wrapper" [ngClass]="additionalClasses" [class.hilti-styled]="isHiltiStyle">
    <pe-control-title class="control-title" [focusControlId]="id" [title]="title" [look]="controlTitleStyle"
        [tooltip]="tooltip" [infoPopup]="infoPopup" [infoPopupTooltip]="infoPopupTooltip">
    </pe-control-title>

    <div [id]="id" class="control-container" #controlContainer (keypress)="onKeyPress($event)"
        (keydown)="onKeyDown($event)">
        <button [id]="id + '-button'" class="dropdown-button" [class.not-selected]="selectedValues == null"
            [class.submitted]="submitted" #dropdownButton type="button" (click)="onClick()" [name]="id"
            [(ngModel)]="selectedValues" #ngModel="ngModel" ngDefaultControl [disabled]="disabled">

            <div class="dropdown-button-container">
                <div class="button-item item"
                    [ngStyle]="{ 'justify-content' : alignment == 'SpaceBetween' ? 'space-between' : 'flex-start' }">

                    <span class="label" [innerHtml]="text"></span>

                    <span *ngFor="let tag of tags" class="tag" [class.new]="tag.type == 'New'"
                        [ngbTooltip]="ngbTooltipTemplate(tag.tooltip, tagTooltipTemplate)">
                        {{tag.text}}
                        <ng-template #tagTooltipTemplate>
                            <pe-content-tooltip [tooltip]="tag.tooltip"></pe-content-tooltip>
                        </ng-template>
                    </span>
                </div>

                <span class="space"></span>

                <div class="caret-container">
                    <div class="caret" [class.caret-down]="!isOpen" [class.caret-up]="isOpen"></div>
                </div>
            </div>

        </button>

        <div class="dropdown-items" #dropdownItems [class.opend]="isOpen" [class.openUp]="openUp"
            [ngStyle]="{ 'max-height' : itemsMaxHeight + 'px' }">


            <button *ngFor="let item of options" type="button" class="dropdown-item"
                [class.selected]="itemSelected(item)" [attr.id]="item.id ? item.id : null"
                [disabled]="isOptionDisabled(item)" (mousedown)="$event.preventDefault()" (click)="onItemClick(item)">

                <div class="item" [class.special]="item.isSpecial"
                    [ngClass]="item.level != null && item.level > 0 ? 'item-level-' + item.level : ''"
                    [ngStyle]="{ 'justify-content' : alignment == 'SpaceBetween' ? 'space-between' : 'flex-start' }">
                    <span *ngIf="item.image" class="image" [ngStyle]="item.image"></span>


                    <span class="checkbox-container">
                        <input type="checkbox" [checked]="itemSelected(item)" [indeterminate]="isIndeterminate(item)">
                        <span [ngClass]="isIndeterminate(item) ? 'indeterminate' : 'checkmark'"></span>
                    </span>

                    <p class="text" *ngIf="item.text">{{item.text}}</p>

                    <span *ngFor="let tag of item.tags" class="tag" [class.new]="tag.type == 'New'"
                        [ngbTooltip]="ngbTooltipTemplate(tag.tooltip, tagTooltipTemplate)">
                        {{tag.text}}
                        <ng-template #tagTooltipTemplate>
                            <pe-content-tooltip [tooltip]="tag.tooltip"></pe-content-tooltip>
                        </ng-template>
                    </span>
                </div>
            </button>
        </div>
    </div>

    <pe-validation-error *ngIf="showValidationErrors" class="validation-error" [ngErrors]="ngModel?.control?.errors">
    </pe-validation-error>
</div>