import {
    Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation
} from '@angular/core';

import { SimpleCheckboxButtonHelper } from '../../helpers/simple-checkbox-button-helper';
import { LocalizationService } from '../../services/localization.service';
import { TrimbleConnectService } from '../../services/trimble-connect.service';
import { CheckboxButtonProps } from '../checkbox-button/checkbox-button.common';

@Component({
    templateUrl: './export-trimble-connect.component.html',
    styleUrls: ['./export-trimble-connect.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ExportTrimbleConnectComponent implements OnChanges {
    @Input()
    public trimbleConnectChecked!: boolean;
    @Output()
    public trimbleConnectCheckedChange = new EventEmitter<boolean>();
    @Input()
    public trimbleConnectLocation!: string;
    @Output()
    public trimbleConnectLocationChange = new EventEmitter<string>();
    @Input()
    public trimbleConnectReportName!: string;
    @Output()
    public trimbleConnectReportNameChange = new EventEmitter<string>();
    @Input()
    public trimbleConnectFolderId!: string;
    @Output()
    public trimbleConnectFolderIdChange = new EventEmitter<string>();
    @Input()
    public trimbleConnectTooltip!: string;
    @Input()
    public isOfflineOnLine!: boolean;
    @Input()
    public reportDisabled!: boolean;
    @Input()
    public submitted = false;


    public isLoaded = false;
    public trimbleConnectUploadCheckbox!: CheckboxButtonProps<boolean>;


    constructor(
        public localizationService: LocalizationService,
        private trimbleConnectService: TrimbleConnectService
    ) { }

    ngOnChanges(): void {
        if (!this.isLoaded) {
            this.trimbleConnectUploadCheckbox =
            {
                items: [
                    {
                        id: 'trimble-connect-upload',
                        text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.TrimbleConnect.Upload'),
                        value: true,
                        tooltip: this.trimbleConnectTooltip
                    }
                ],
                selectedValues: this.trimbleConnectChecked ? (new Set<boolean>()).add(true) : new Set<boolean>(),
            };

            this.isLoaded = true;
        }
    }

    public onTrimbleConnectCheckedChange() {
        this.trimbleConnectChecked = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.trimbleConnectUploadCheckbox) ?? false;
        this.trimbleConnectCheckedChange.emit(this.trimbleConnectChecked);
    }

    public trimbleConnectSelectLocation() {
        return this.trimbleConnectService.getPdfLocation()
            .then(fileInfo => {
                this.trimbleConnectLocation = fileInfo?.location;
                this.trimbleConnectLocationChange.emit(this.trimbleConnectLocation);

                this.trimbleConnectReportName = fileInfo?.name;
                this.trimbleConnectReportNameChange.emit(this.trimbleConnectReportName);

                this.trimbleConnectFolderId = fileInfo?.id;
                this.trimbleConnectFolderIdChange.emit(this.trimbleConnectFolderId);
            })
            .catch(err => {
                this.logError(err);
            });
    }

    private logError(error: any){
        if (error instanceof Error) {
            console.error(error);
        }
    }
}
