export interface CustomError {
    customError: string;
}

export type CustomErrorValidatorFn = () => null | CustomError;

export function customErrorValidator(message?: string): CustomErrorValidatorFn {
    return () => {
        if (message != null) {
            return {
                customError: message
            };
        }

        return null;
    };
}
