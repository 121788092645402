import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    templateUrl: './tab.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class TabComponent {
    @Input()
    public active = false;
}
