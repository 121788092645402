import { Component, ElementRef, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';

import {
    INotification, NotificationTypeImage, NotificationTypeMap
} from '../../entities/notifications';
import { includeSprites } from '../../sprites';

@Component({
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class NotificationComponent implements OnInit, OnChanges {
    @Input()
    public notification!: INotification;

    public NotificationType = NotificationTypeMap;
    public NotificationTypeImage = NotificationTypeImage;
    public isLoaded = false;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
    ) { }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-info'
        );
    }

    ngOnChanges(): void {
        if (!this.isLoaded) {
            this.isLoaded = true;
        }
    }

    public returnFn(fn?: () => boolean) {
        if (fn != undefined) {
            return fn();
        }

        return false;
    }
}
