import { Observable } from 'rxjs';
import { CommonRegion } from '../entities/code-lists/common-region';

import { Language } from '../entities/code-lists/language';
import { RegionLanguage } from '../entities/code-lists/region-language';
import { Separator } from '../entities/code-lists/separator';
import { CcmsUserSettings } from '../generated-modules/Hilti.PE.CcmsLibrary.Entities';
import { MouseClickType } from '../helpers/app-settings-helper';

export interface Controls3dSettings {
    rotate: MouseClickType;
    pan: MouseClickType;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQuickStartUserSettings {}

export abstract class UserSettingsServiceBase<TSettings> {
    public abstract get settings(): TSettings;
    public abstract get userSettingsSaving(): Observable<void>;
    public abstract get controls3dSettings(): Controls3dSettings;
    public abstract get ccmsUserSettings(): CcmsUserSettings;
    public abstract get getProfis3Url(): string;
    public abstract get settingsLoaded(): boolean;

    public abstract getThousandsSeparator(): Separator;
    public abstract getDecimalSeparator(): Separator;
    public abstract getLanguage(): Language;
    public abstract getCommonRegionById(regionId: number) : CommonRegion;
    public abstract save(): Promise<void>;
    public abstract isSectionCollapsed(settingsControlId?: number | string): boolean;
    public abstract setSectionCollapsed(settingsControlId?: number | string, collapsed?: boolean): void;
    public abstract isUpgradeLicenseAvailable(): boolean;
    public abstract getRegionLanguage(regionId?: number, languageId?: number): RegionLanguage;

    public getValueWithFallbacks<T>(value: T | null | undefined, fallbackValue1?: T | null, fallbackValue2?: T | null): T | undefined {
        return value ?? fallbackValue1 ?? fallbackValue2 ?? undefined;
    }
}

export class UserSettingsServiceInjected<TSettings> extends UserSettingsServiceBase<TSettings> {
    protected baseService!: UserSettingsServiceBase<TSettings>;

    public get settings(): TSettings {
        return this.baseService.settings;
    }

    public get userSettingsSaving(): Observable<void> {
        return this.baseService.userSettingsSaving;
    }

    public get controls3dSettings(): Controls3dSettings {
        return this.baseService.controls3dSettings;
    }

    public get ccmsUserSettings(): CcmsUserSettings {
        return this.baseService.ccmsUserSettings;
    }

    public get settingsLoaded(): boolean {
        return this.baseService.settingsLoaded;
    }

    public get getProfis3Url(): string {
        return this.baseService.getProfis3Url;
    }

    public setBaseService(baseService: UserSettingsServiceBase<TSettings>) {
        this.baseService = baseService;

        this.getThousandsSeparator = baseService.getThousandsSeparator.bind(baseService);
        this.getDecimalSeparator = baseService.getDecimalSeparator.bind(baseService);
        this.getLanguage = baseService.getLanguage.bind(baseService);
        this.getCommonRegionById = baseService.getCommonRegionById.bind(baseService);
        this.save = baseService.save.bind(baseService);
        this.isSectionCollapsed = baseService.isSectionCollapsed.bind(baseService);
        this.setSectionCollapsed = baseService.setSectionCollapsed.bind(baseService);
        this.isUpgradeLicenseAvailable = baseService.isUpgradeLicenseAvailable.bind(baseService);
        this.getRegionLanguage = baseService.getRegionLanguage.bind(baseService);
    }

    // UserSettingsServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public getThousandsSeparator() {
        return {} as Separator;
    }

    public getDecimalSeparator() {
        return {} as Separator;
    }

    public getLanguage() {
        return {} as Language;
    }

    public getCommonRegionById(_regionId: number) : CommonRegion {
        return {} as CommonRegion;
    }

    public save() {
        return {} as Promise<void>;
    }

    public isSectionCollapsed(_settingsControlId?: number | string) {
        return false;
    }

    public setSectionCollapsed(_settingsControlId?: number | string, _collapsed?: boolean) {
        // Nothing to do.
    }

    public isUpgradeLicenseAvailable() {
        return false;
    }

    public getRegionLanguage(_regionId?: number, _languageId?: number) {
        return {} as RegionLanguage;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
