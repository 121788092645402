<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="'Agito.Hilti.Profis3.DesignSectionExport.Title' | l10n: localizationService.selectedLanguage"
    [closeButtonDisabled]="loading">
</pe-modal-header>

<pe-modal-body>
    <pe-design-section-export-items
        class="body-wrapper"
        [items]="items"
        (stateChange)="onStateChange($any($event).detail)">
    </pe-design-section-export-items>
</pe-modal-body>
