<div [id]="id" class="toggle-button-group control">
    <pe-control-title
        [focusControlId]="id"
        [title]="title"
        [tooltip]="tooltip">
    </pe-control-title>

    <div class="toggle-buttons" [class.join]="display == ButtonGroupDisplay.join">
        <div *ngFor="let item of items" class="control toggle-button">
            <ng-template #itemTooltipTemplate>
                <pe-content-tooltip [tooltip]="item.tooltip"></pe-content-tooltip>
            </ng-template>

            <span [ngbTooltip]="ngbTooltipTemplate(item.tooltip, itemTooltipTemplate)">
                <button
                    type="button"
                    class="button button-element"
                    [class.single-item-width]="type == getToggleMultipleType()"
                    [ngClass]="additionalClasses"
                    [style.height.px]="itemHeight"
                    [style.width.px]="itemWidth"
                    [class.active]="isItemActive(item)"
                    [attr.id]="item.id ? (item.id + '-toggle-button') : null"
                    (click)="onClick(item)"
                    [disabled]="disabled || item.disabled">
                    <div class="image-container" *ngIf="item.image">
                        <span class="image" [class]="item.class" [ngStyle]="item.image"></span>
                    </div>

                    <div class="text" *ngIf="item.text">{{item.text}}</div>
                </button>
            </span>
        </div>
    </div>
</div>
