// import sprites info and images
import mainSpriteInfo from './bundles/main.json';
import mainImage from './bundles/main.png';

// sprite types
export type MainSprite = keyof typeof mainSpriteInfo;

// add more sprites with |
export type Sprite = MainSprite;

export type SpriteClass = `pe-ui-common-${Sprite}`;

// add more sprites with ...
const spritesInfo = {
    ...toSpriteInfo(mainSpriteInfo, 'main.png')
};

const imagesUrl: Record<string, string> = {
    'main.png': mainImage
};

function toSpriteInfo<T>(sprites: Record<string, T>, image: string): Record<string, T & { image: string }> {
    return Object.fromEntries((Object.entries(sprites) as [string, T & { image: string }][]).map(([spriteName, sprite]) => {
        sprite.image = image;

        return [spriteName, sprite];
    }));
}

export function includeSprites(root: Node | null, ...sprites: Sprite[]): void {
    if (root != null) {
        let cssExists = false;
        let css = `
            .pe-ui-common-sprite {
                display: block;
            }
        `;

        for (const sprite of sprites) {
            const spriteInfo = spritesInfo[sprite];
            if (spriteInfo == null) {
                console.warn(`Sprite with name '${sprite}' does not exist (missing json)`);
                continue;
            }

            const imageUrl = imagesUrl[spriteInfo.image];

            if (!imageUrl) {
                console.warn(`Sprite with name '${sprite}' does not exist (missing image)`);
                continue;
            }

            css += `
                .pe-ui-common-sprite.pe-ui-common-${sprite} {
                    background-image: url(${imageUrl});
                    background-position: -${spriteInfo.x}px -${spriteInfo.y}px;
                    width: ${spriteInfo.width}px;
                    min-width: ${spriteInfo.width}px;
                    height: ${spriteInfo.height}px;
                }
            `;
            cssExists = true;
        }

        if (cssExists) {
            const spriteStyle = document.createElement('style');
            spriteStyle.textContent = css;

            root.insertBefore(spriteStyle, root.firstChild);
        }
    }
}
