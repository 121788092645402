import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { CollapsingControls } from '../../entities/collapsing';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { IValidationError } from '../../entities/design';

@Component({
    templateUrl: './design-validation-error.component.html',
    styleUrls: ['./design-validation-error.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DesignValidationErrorComponent implements OnInit {

    @Input()
    public validationError!: IValidationError;

    public collapsed = false;

    constructor(
        public localizationService: LocalizationService,
        private userSettings: UserSettingsService
    ) { }

    public click() {
        /*
        // focus control
        if (this.menu != null && this.validationError.Id != null) {
            const control = this.menu.controls[this.validationError.Id];

            // focus
            if (control != null) {
                //find the tab for the control;
                this.menu.selectedTab = ;

                //focus the control
                // wait for tab switch
                setTimeout(() => control.control.focus());
            }
        }
        */
    }

    ngOnInit(): void {
        this.collapsed = this.userSettings.isSectionCollapsed(CollapsingControls.ValidationError);
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.ValidationError, collapsed);
    }
}
