import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LocalizationService } from 'src/common/services/localization.service';
import { isContentTooltip, Tooltip } from './content-tooltip.common';

@Component({
    templateUrl: './content-tooltip.component.html',
    styleUrls: ['./content-tooltip.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ContentTooltipComponent {
    public isContentTooltip = false;
    public title?: string;
    public content?: string;


    constructor(
        private localizationService: LocalizationService
    ) { }


    @Input()
    public set tooltip(tooltip: Tooltip) {
        if (tooltip != null) {
            // eslint-disable-next-line no-cond-assign
            if (this.isContentTooltip = isContentTooltip(tooltip)) {
                this.title = this.localizationService.sanitizeText(tooltip.title ?? '', LocalizationService.SubSup);
                this.content = this.localizationService.sanitizeText(tooltip.content ?? '', LocalizationService.SubSup);
            }
        }
    }
}
