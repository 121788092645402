import {
    RegionLinks, RegionLinksTranslations, ReleaseNote
} from '../generated-modules/Hilti.PE.ProductInformationService.Shared.Entities';

export abstract class ProductInformationServiceBase {
    public abstract get regionLinksTranslationsForUser(): RegionLinksTranslations;

    public abstract regionLinksForUser(regionId?: number): RegionLinks;
    public abstract isNewReleaseNoteVersion(): boolean;
    public abstract getReleaseNotesFromService(selectedLanguage: string): Promise<ReleaseNote[]>;
    public abstract setNewReleaseNoteVersion(): void;
    public abstract hasMarketingCampaigns(): boolean;
    public abstract openCampaigns(): Promise<void>;
    public abstract getInfoLink(key: string) : string;
}

export class ProductInformationServiceInjected extends ProductInformationServiceBase {
    protected baseService!: ProductInformationServiceBase;

    public get regionLinksTranslationsForUser(): RegionLinksTranslations {
        return this.baseService.regionLinksTranslationsForUser;
    }

    public setBaseService(baseService: ProductInformationServiceBase) {
        this.baseService = baseService;

        this.regionLinksForUser = baseService.regionLinksForUser.bind(baseService);
        this.isNewReleaseNoteVersion = baseService.isNewReleaseNoteVersion.bind(baseService);
        this.getReleaseNotesFromService = baseService.getReleaseNotesFromService.bind(baseService);
        this.setNewReleaseNoteVersion = baseService.setNewReleaseNoteVersion.bind(baseService);
        this.hasMarketingCampaigns = baseService.hasMarketingCampaigns.bind(baseService);
        this.openCampaigns = baseService.openCampaigns.bind(baseService);
        this.getInfoLink = baseService.getInfoLink.bind(baseService);
    }

    // ProductInformationServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public regionLinksForUser(_regionId?: number) {
        return {} as RegionLinks;
    }

    public isNewReleaseNoteVersion() {
        return false;
    }

    public getReleaseNotesFromService(_selectedLanguage: string) {
        return {} as Promise<ReleaseNote[]>;
    }

    public setNewReleaseNoteVersion() {
        return;
    }

    public hasMarketingCampaigns() {
        return false;
    }

    public openCampaigns() {
        return {} as Promise<void>;
    }

    public getInfoLink(_key: string) : string {
        return '';
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
