import {
    Component, Input, OnChanges, OnInit, ViewEncapsulation
} from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.ShadowDom,
    templateUrl: './circle-loader.component.html',
    styleUrls: ['./circle-loader.component.scss']
})
export class CircleLoaderComponent implements OnInit, OnChanges {
    @Input()
    public loadingProgress!: number;

    @Input()
    public isLoading!: boolean;

    public loadingProgressDisplay!: number;


    public ngOnInit(): void {
        this.loadingProgress = this.loadingProgressDisplay = 0;
    }

    public ngOnChanges(): void {
        this.loadingProgressDisplay = Math.ceil(this.loadingProgress);
    }
}
