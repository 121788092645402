export abstract class MarketingServiceBase {
    public abstract getUserManualForSelectedLanguage() : string;
}

export class MarketingServiceInjected extends MarketingServiceBase {
    public setBaseService(baseService: MarketingServiceBase) {
        this.getUserManualForSelectedLanguage = baseService.getUserManualForSelectedLanguage.bind(baseService);
    }

    // MarketingServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public getUserManualForSelectedLanguage() {
        return '';
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
