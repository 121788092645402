import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '../../helpers/unit-helper';
import { UnitService } from '../../services/unit.service';

@Component({
    templateUrl: './utilization-bar.component.html',
    styleUrls: ['./utilization-bar.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class UtilizationBarComponent {
    @Input()
    public collapsed = true;

    @Input()
    public utilizationInvalid = false;

    @Input()
    public utilizationValue?: number;

    public unitGroupEnum = {
        Percentage: UnitGroup.Percentage
    };

    constructor(
        public unit: UnitService
    ) { }
}
