import { CodeList } from '../entities/code-lists/code-list';

export enum CommonCodeList {
    Region = 1000,
    WorldArea,
    RegionLanguage,
    ReportPaperSize,
    ReportType,
    Language,
    DecimalSeparator,
    ThousandsSeparator,
    UnitLength,
    UnitLengthLarge,
    UnitArea,
    UnitStress,
    UnitStressSmall,
    UnitForce,
    UnitMoment,
    UnitTemperature,
    UnitForcePerLength,
    UnitTime,
    UnitMomentPerLength,
    UnitDensity,
    UnitAreaPerLength,
    StructuralCalculationSoftware,
    LicenseComparisonPackages,
    TrialBannerFeature,
    LicenseSuite,
    BimCadLibLanguages,
    UnitSpecificWeight,
    UnitItemsPerArea,
    UnitVolume
}

export abstract class CommonCodeListServiceBase {
    public abstract readonly commonCodeLists: Record<CommonCodeList, CodeList[]>;
}

export class CommonCodeListServiceInjected extends CommonCodeListServiceBase {
    private baseService!: CommonCodeListServiceBase;

    public get commonCodeLists() {
        return this.baseService.commonCodeLists;
    }

    public setBaseService(baseService: CommonCodeListServiceBase) {
        this.baseService = baseService;
    }
}
