import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { UnitGroup, UnitType } from '../../helpers/unit-helper';
import { UnitService } from '../../services/unit.service';
import { Options } from '@angular-slider/ngx-slider';
import { SliderType } from './slider.common';
import { randomString } from '../../helpers/random';
import { Tooltip } from '../content-tooltip/content-tooltip.common';
import { InfoPopup } from '../control-title/control-title.common';
import debounce from 'lodash-es/debounce';

@Component({
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SliderComponent implements OnChanges {
    @Input()
    public id = randomString(8);

    @Input()
    public title?: string;

    @Input()
    public value!: number;

    @Input()
    public options!: Options;

    @Input()
    public highValue!: number;

    @Input()
    public sliderType: SliderType = SliderType.Single;

    @Input()
    public unitGroup!: UnitGroup;

    @Input()
    public tooltip?: Tooltip;

    @Input()
    public infoPopup?: InfoPopup;

    @Input()
    public infoPopupTooltip?: Tooltip;

    @Input()
    public isAdvanceInput?: boolean = false;

    @Output()
    public valueChange = new EventEmitter<number>();

    @Output()
    public highValueChange = new EventEmitter<number>();

    upperValue = 0;
    lowerValue = 0;

    defaultUnit?: UnitType;
    constructor(
        private unitService: UnitService
    ) {
        this.setValue = debounce(this.setValue, 500);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes['value'] && changes['value'].currentValue != changes['value'].previousValue) {
            this.setValue(changes);
        }

        if (changes['highValue'] && changes['highValue'].currentValue != changes['highValue'].previousValue) {
            this.setHighValue(changes);
        }

        if (changes['value'] || changes['highValue']) {
            this.defaultUnit = this.getDefaultUnit();
        }
    }

    private setValue(changes: SimpleChanges) {
        this.lowerValue = changes['value'].currentValue;
    }

    private setHighValue(changes: SimpleChanges) {
        this.upperValue = changes['highValue'].currentValue;
    }

    public get sliderTypeEnum() {
        return SliderType;
    }

    public getDefaultUnit() {
        return this.unitService.getDefaultUnit(this.unitGroup);
    }

    public changeSliderValue(value: number, highValue = 0) {
        this.value = value;
        this.lowerValue = value;
        this.valueChange.emit(value);
        if (this.sliderType == SliderType.Range) {
            this.highValue = highValue;
            this.upperValue = highValue;
            this.highValueChange.emit(highValue);
        }
    }
}
