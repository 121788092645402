import { Injectable } from '@angular/core';

import { UserSettings } from '../entities/user-settings';
import { UserSettingsServiceInjected } from './user-settings.common';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService extends UserSettingsServiceInjected<UserSettings> {
}
