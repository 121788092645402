import { Component, ElementRef, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';

import { CollapsingControls } from '../../entities/collapsing';
import {
    INotificationsComponentInput, NotificationLocation, NotificationType, NotificationTypeImage,
    NotificationTypeMap, NotificationTypeName
} from '../../entities/notifications';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { includeSprites } from '../../sprites';

@Component({
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class NotificationsComponent implements OnInit, OnChanges {
    @Input()
    public inputs!: INotificationsComponentInput;

    @Input()
    public isVisible = false;

    public collapsed = false;

    public NotificationType = NotificationTypeMap;
    public NotificationTypeImage = NotificationTypeImage;
    public NotificationTypeName = NotificationTypeName;

    public isLoaded = false;
    private localInputs!: INotificationsComponentInput;

    constructor(
        public localizationService: LocalizationService,
        private userSettings: UserSettingsService,
        private elementRef: ElementRef<HTMLElement>,
    ) { }

    public get beforeSCNotifications() {
        if (this.localInputs.isVisible() || this.localInputs.notifications) {
            return this.localInputs.notifications.filter((item) => item.notificationLocation == NotificationLocation.Start && item.condition());
        }
        else {
            return [];
        }
    }

    public get betweenSCNotifications() {
        if (this.localInputs.isVisible() || this.localInputs.notifications) {
            return this.localInputs.notifications.filter((item) => item.notificationLocation == NotificationLocation.Middle && item.condition());
        }
        else {
            return [];
        }
    }

    public get afterSCNotifications() {
        if (this.localInputs.isVisible() || this.localInputs.notifications) {
            return this.localInputs.notifications.filter((item) => item.notificationLocation == NotificationLocation.End && item.condition());
        }
        else {
            return [];
        }
    }

    public get hasAlertScopeChecks() {
        return this.alertScopeChecks.length > 0;
    }

    public get alertScopeChecks() {
        if (this.localInputs.isVisible() && this.localInputs.scopeChecks) {
            return this.localInputs.scopeChecks.filter((sc) => sc.type == NotificationType.alert);
        }
        else {
            return [];
        }
    }

    public get hasInfoMessageVisible()
    {
        return this.localInputs.isInfoMessageVisible();
    }

    public get hasInfoScopeChecks() {
        return this.infoScopeChecks.length > 0;
    }

    public get infoScopeChecks() {
        if (this.localInputs.isVisible() && this.localInputs.scopeChecks) {
            return this.localInputs.scopeChecks.filter((sc) => sc.type == NotificationType.info);
        }
        else {
            return [];
        }
    }

    ngOnInit() {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-info',
            'sprite-warning'
        );

        this.collapsed = this.userSettings.isSectionCollapsed(CollapsingControls.NotificationsDesign);
    }

    ngOnChanges(): void {
        if (!this.isLoaded) {
            this.isLoaded = true;
        }

        this.localInputs = this.inputs;
    }

    public getNotificationCount(type: NotificationType) {
        let scCount = this.localInputs.scopeChecks.filter((item) => item.type == type).length;

        if (type == NotificationType.info) {
            scCount += this.localInputs.notifications.filter((item) => item.doesCount && item.condition()).length;
        }

        return scCount;
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.NotificationsDesign, collapsed);
    }
}

