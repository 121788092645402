import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { ngbTooltipTemplate, Tooltip } from '../content-tooltip/content-tooltip.common';
import { LabelLook } from './label.common';

@Component({
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LabelComponent implements OnChanges {
    public isHiltiStyled = false;
    public textLines!: string[];
    public ngbTooltipTemplate = ngbTooltipTemplate;

    @Input()
    public id!: string;

    @Input()
    public title?: string;

    @Input()
    public look = LabelLook.Normal;

    @Input()
    public text!: string | string[];

    @Input()
    public disabled = false;

    @Input()
    public tooltip?: Tooltip;


    public get isTextLinesPresent() {
        if (this.textLines != null) {
            return this.textLines?.length < 1;
        }

        return false;
    }


    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['text'] != null) {
            // Ensure string[] ...
            const text = Array.isArray(this.text)
                ? this.text
                : [this.text];

            // and filter out empty entries.
            this.textLines = text.filter(t => t != null && typeof t == 'string' && t != '');
        }

        this.isHiltiStyled = this.look == LabelLook.HiltiStyled;
    }
}
