import { HttpRequest, HttpResponse } from '@angular/common/http';

export interface ApiOptions {
    supressErrorMessage?: boolean;
    forceIncludeAuthenticationHeaders?: boolean;
    cancellationToken?: CancellationToken;
}

export class CancellationTokenSource {
    public isCanceled = false;

    private fns: (() => void)[] = [];

    public token: CancellationToken = {
        register: (fn: () => void) => {
            this.fns.push(fn);
        },
        isCanceled: false
    };

    public cancel(): void {
        this.isCanceled = true;
        this.token.isCanceled = true;

        for (const fn of this.fns) {
            fn();
        }
    }
}

export interface CancellationToken {
    register(fn: () => void): void;
    isCanceled: boolean;
}

/**
 * Api service base
 */
export abstract class ApiServiceBase {
    public abstract request<T = void>(httpRequest: HttpRequest<any>, options?: ApiOptions): Promise<HttpResponse<T>>;
    public abstract getServiceName(url: string): string;
}

export class ApiServiceInjected extends ApiServiceBase {
    protected baseService!: ApiServiceBase;

    public setBaseService(baseService: ApiServiceBase) {
        this.baseService = baseService;
        this.request = baseService.request.bind(baseService);
    }

    // ApiServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public getServiceName(_url: string) {
        return '';
    }

    public request<T = void>(_httpRequest: HttpRequest<any>, _options?: ApiOptions): Promise<HttpResponse<T>> {
        return {} as Promise<HttpResponse<T>>;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
