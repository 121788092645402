/* T or Promise<T> */
export type Promisable<T> = T | Promise<T>;

export function isPromise<T>(v: any): v is Promise<T> {
    return v?.then;
}


/* T or () => T */
type supportedValueOrFnTypes = string | boolean | number | null | undefined;
type Fn<T extends supportedValueOrFnTypes> = (() => T);
export type ValueOrFn<T extends supportedValueOrFnTypes> = T | Fn<T>;

export function getValueFromValueOrFn<T extends supportedValueOrFnTypes>(arg: ValueOrFn<T>): T {
    return typeof arg === 'function' ? arg() : arg;
}
