import {
    Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewEncapsulation
} from '@angular/core';
import { includeSprites, Sprite } from '../../sprites';

import { ButtonLook, ButtonType } from './button.common';

@Component({
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ButtonComponent implements OnChanges {
    @Input()
    public id?: string;

    @Input()
    public text?: string;

    @Input()
    public look = ButtonLook.Normal;

    @Input()
    public type = ButtonType.Button;

    @Input()
    public disabled = false;

    @Input()
    public width?: string;

    @Input()
    public height?: string;

    @Input()
    public sprite?: Sprite;

    @Output()
    public buttonClick = new EventEmitter<Event>();

    public additionalClasses = '';

    constructor(
        private elementRef: ElementRef
    ) { }

    public ngOnChanges(): void {
        if (this.sprite) {
            includeSprites(this.elementRef.nativeElement.shadowRoot,
                this.sprite
            );
        }

        // CSS classes
        const additionalCssClasses: string[] = [];
        switch (this.look) {
            case ButtonLook.Default:
                additionalCssClasses.push('button-default');
                break;

            case ButtonLook.Primary:
                additionalCssClasses.push('button-primary');
                break;

            case ButtonLook.Cta:
                additionalCssClasses.push('button-CTA');
                break;

            case ButtonLook.Ghost:
                additionalCssClasses.push('button-ghost');
                break;


            case ButtonLook.HiltiStyledButtonPrimary:
                additionalCssClasses.push('hilti-styled-button');
                additionalCssClasses.push('hilti-styled-button-primary');
                break;

            case ButtonLook.HiltiStyledButtonSecondary:
                additionalCssClasses.push('hilti-styled-button');
                additionalCssClasses.push('hilti-styled-button-secondary');
                break;

            case ButtonLook.HiltiStyledButtonGray:
                additionalCssClasses.push('hilti-styled-button');
                additionalCssClasses.push('hilti-styled-button-gray');
                break;

            case ButtonLook.DefaultIcon:
                additionalCssClasses.push('button-default');
                additionalCssClasses.push('button-icon');
                break;

            case ButtonLook.HiltiStyledButtonRed:
                additionalCssClasses.push('hilti-styled-button');
                additionalCssClasses.push('hilti-styled-button-red');
                break;

            case ButtonLook.Normal:
            default:
                break;
        }

        this.additionalClasses = additionalCssClasses.join(' ');
    }

    public onClick(e: Event) {
        if (this.type == ButtonType.Submit) {
            this.handleSubmit(e);
            return;
        }

        this.buttonClick?.emit(e);
    }

    private handleSubmit(e: Event) {
        // Submitting form (not in Shadow DOM) with a button (in Shadow DOM)
        // Src: https://stackoverflow.com/questions/54370436/can-i-get-a-button-in-shadow-dom-to-submit-a-form-not-in-shadow-dom
        const form = this.elementRef.nativeElement.closest('form');
        if (form == null) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        const submit = document.createElement('button');
        submit.setAttribute('type', 'submit');
        submit.style.display = 'none';

        form.appendChild(submit);
        submit.click();

        form.removeChild(submit);
    }
}
