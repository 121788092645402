import { LicenseSuite } from '../entities/code-lists/license-suite';
import { LicenseSuiteType } from '../generated-modules/Hilti.PE.Common.Shared.Models.Enums';

export abstract class LicenseServiceBase {
    public abstract get floatingLimitReached(): boolean;

    public abstract isTrial(): boolean;
    public abstract isTrialExpired(): boolean;
    public abstract isAdvanced(): boolean;
    public abstract isBasic(): boolean;
    public abstract isFree(): boolean;
    public abstract isOnlyBasic(): boolean;
    public abstract isStandardLicense(): boolean;
    public abstract getRemainingDays(expiresAt?: Date): number;
    public abstract isNoLicenseHandledAsFree(): boolean;
    public abstract isDesktop(): boolean;
    public abstract licenseSuiteItemCode(licenseSuites: LicenseSuite[], suiteType: LicenseSuiteType): string;
    public abstract getLicense(): string;
}

export class LicenseServiceInjected extends LicenseServiceBase {
    protected baseService!: LicenseServiceBase;

    public setBaseService(baseService: LicenseServiceBase) {
        this.baseService = baseService;

        this.isTrial = baseService.isTrial.bind(baseService);
        this.isTrialExpired = baseService.isTrialExpired.bind(baseService);
        this.isAdvanced = baseService.isAdvanced.bind(baseService);
        this.isBasic = baseService.isBasic.bind(baseService);
        this.isFree = baseService.isFree.bind(baseService);
        this.isOnlyBasic = baseService.isOnlyBasic.bind(baseService);
        this.isStandardLicense = baseService.isStandardLicense.bind(baseService);
        this.getRemainingDays = baseService.getRemainingDays.bind(baseService);
        this.isNoLicenseHandledAsFree = baseService.isNoLicenseHandledAsFree.bind(baseService);
        this.isDesktop = baseService.isDesktop.bind(baseService);
        this.licenseSuiteItemCode = baseService.licenseSuiteItemCode.bind(baseService);
        this.getLicense=baseService.getLicense.bind(baseService);
    }

    public get floatingLimitReached() {
        return this.baseService.floatingLimitReached;
    }

    // LicenseServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public isTrial() {
        return false;
    }

    public isTrialExpired() {
        return false;
    }

    public isAdvanced() {
        return false;
    }

    public isBasic() {
        return false;
    }

    public isFree() {
        return false;
    }

    public isOnlyBasic() {
        return false;
    }

    public isStandardLicense() {
        return false;
    }

    public getRemainingDays(_expiresAt?: Date) {
        return 0;
    }

    public isNoLicenseHandledAsFree() {
        return false;
    }

    public isDesktop() {
        return false;
    }

    public getLicense(){
        return '';
    }

    public licenseSuiteItemCode(_licenseSuites: LicenseSuite[], _suiteType: LicenseSuiteType) {
        return '';
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
