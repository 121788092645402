import { CodeList } from '../entities/code-lists/code-list';
import { IUnitProviderObject } from '../entities/design';
import { UnitGroup, UnitType as Unit } from '../helpers/unit-helper';

/**
 * kgf in N
 */
const kgfInN = 9.80665;

/**
 * N in kgf
 */
const NInKgf = 0.10197162129779283;

/**
 * kgfm in Nmm
 */
const kgfmInNmm = 9806.65;

/**
 * Nmm in kgfm
 */
const NmmInKgfm = 0.00010197162129779283;


/* eslint-disable no-sparse-arrays */
export const conversionMatrixLength = [
    [1, 0.1, 0.001, 1 / 25.4, 0.0393700787 / 12, 0.000000621371192],
    [10, 1, 0.01, 10 / 25.4, 0.393700787 / 12, 0.00000621371192],
    [1000, 100, 1, 1000 / 25.4, 39.3700787 / 12, 0.000621371192],
    [25.4, 2.54, 0.0254, 1, 1 / 12, 0.0000157828283],
    [304.8, 30.48, 0.3048, 12, 1, 0.000189393939],
    [1609344, 160934.4, 1609.344, 63360, 5280, 1]
];

export const conversionMatrixForce = [
    [1, 0.1, 0.001, 0.224808943, 0.0002248089, NInKgf],
    [10, 1, 0.01, 2.24808943, 0.002248089, 10 * NInKgf],
    [1000, 100, 1, 224.808943, 0.224808943, 1000 * NInKgf],
    [1 / 0.224808943, 1 / 2.24808943, 1 / 224.808943, 1, 0.001, (1 / 0.224808943) * NInKgf],
    [1 / 0.000224808943, 1 / 0.00224808943, 1 / 0.224808943, 1000, 1, (1 / 0.000224808943) * NInKgf],
    [kgfInN, 0.1 * kgfInN, 0.001 * kgfInN, 0.224808943 * kgfInN, 0.0002248089 * kgfInN, 1],
    [9806.65, 980.665, 98.0665, 2204.622621852, 2.204622621852, 1000, 1]
];

export const conversionMatrixMoment = [
    [1, 0.001, 0.0001, 0.000001, 0.000737562149 * 12, 0.000737562149, 0.000000737562149277266 * 12, 0.000000737562149277266, NmmInKgfm, 100 * NmmInKgfm],
    [1000, 1, 0.1, 0.001, 0.737562149 * 12, 0.737562149, 0.000737562149277266 * 12, 0.000737562149277266, 1000 * NmmInKgfm, 100000 * NmmInKgfm],
    [10000, 10, 1, 0.01, 7.37562149 * 12, 7.37562149, 0.00737562149277266 * 12, 0.00737562149277266, 10000 * NmmInKgfm, 1000000 * NmmInKgfm],
    [1000000, 1000, 100, 1, 737.562149 * 12, 737.562149, 0.737562149277266 * 12, 0.737562149277266, 1000000 * NmmInKgfm, 100000000 * NmmInKgfm],
    [1 / (0.000737562149 * 12), 1 / (0.737562149 * 12), 1 / (7.37562149 * 12), 1 / (737.562149 * 12), 1, 1 / 12, 0.001, 0.001 / 12, 1 / (0.000737562149 * 12) * NmmInKgfm, 1 / (0.000737562149 * 12) * 100 * NmmInKgfm],
    [1 / 0.000737562149, 1 / 0.737562149, 1 / 7.37562149, 1 / 737.562149, 12, 1, 0.012, 0.001, 1 / 0.000737562149 * NmmInKgfm, undefined, 1 / 0.000737562149 * 100 * NmmInKgfm],
    [1000 / (0.000737562149277266 * 12), 1000 / (0.737562149277266 * 12), 1000 / (7.37562149277266 * 12), 1000 / (737.562149277266 * 12), 1000, 1000 / 12, 1, 1 / 12, 1000 / (0.000737562149277266 * 12) * NmmInKgfm, undefined, 1000 / (0.000737562149277266 * 12) * 100 * NmmInKgfm],
    [1000 / 0.000737562149277266, 1000 / 0.737562149277266, 1000 / 7.37562149277266, 1000 / 737.562149277266, 12000, 1000, 12, 1, 1000 / 0.000737562149277266 * NmmInKgfm, 1000 / 0.000737562149277266 * 100 * NmmInKgfm],
    [kgfmInNmm, 0.001 * kgfmInNmm, 0.0001 * kgfmInNmm, 0.000001 * kgfmInNmm, 0.000737562149 * 12 * kgfmInNmm, 0.000737562149 * kgfmInNmm, 0.000000737562149277266 * 12 * kgfmInNmm, 0.000000737562149277266 * kgfmInNmm, 1, 100],
    [0.01 * kgfmInNmm, 0.00001 * kgfmInNmm, 0.000001 * kgfmInNmm, 0.00000001 * kgfmInNmm, 0.000737562149 * 12 * 0.01 * kgfmInNmm, 0.000737562149 * 0.01 * kgfmInNmm, 0.000000737562149277266 * 12 * 0.01 * kgfmInNmm, 0.000000737562149277266 * 0.01 * kgfmInNmm, 0.01, 1]
];

export const conversionMatrixPercentage = [
    [1, 10],
    [0.1, 1]
];

export const conversionMatrixStress = [
    [1, 145.037738, 0.145037738, 1000, 1, 1000000, 10.197162129779, 1 / (4.788e-5)],
    [1 / 145.037738, 1, 0.001, 1 / 0.145037738, 1 / 145.037738, 1 / 0.000145037738, 0.070306957829636, 1 / (1 / 144)],
    [1 / 0.145037738, 1000, 1, 1 / 0.000145037738, 1 / 0.145037738, 1 / 0.000000145037738, 70.306957829636, 1 / (1 / (144 * 1000))],
    [0.001, 0.145037738, 0.000145037738, 1, 0.001, 1000, 0.010197162129779282, 1 / (0.04788)],
    [1, 145.037738, 0.145037738, 1000, 1, 1000000, 10.197162129779, 1 / (4.788e-5)],
    [0.000001, 0.000145037738, 0.000000145037738, 0.001, 0.000001, 1, 0.000010197162129779282, 1 / (47.88)],
    [1 / 10.197162129779, 1 / 0.070306957829636, 1 / 70.306957829636, 1 / 0.010197162129779282, 1 / 10.197162129779, 1 / 0.000010197162129779282, 1, 1 / (4.788e-4)],
    [4.788e-5, 1 / 144, 1 / (144 * 1000), 0.04788, 4.788e-5, 47.88, 4.788e-4, 1]
];

export const conversionMatrixTime = [
    [1, 0.001, 1 / 60000, 1 / 3600000],
    [1000, 1, 1 / 60, 1 / 3600],
    [60000, 60, 1, 1 / 60],
    [3600000, 3600, 60, 1]
];

export const conversionMatrixVelocity = [
    [1]
];

export const conversionMatrixDensity = [
    [1, 0.0624279606],
    [16.01846337396, 1]
];

export const conversionMatrixSpecificWeight = [
    [1, 1000000, 6365880.3356736],
    [0.000001, 1, 0.63658803356736],
    [6365880.3356736, 0.63658803356736, 1]
];

/* eslint-enable no-sparse-arrays */

export class UnitValue {
    public value: number;
    public unit: Unit;

    constructor(value: number, unit: Unit) {
        this.value = value;
        this.unit = unit;
    }
}

export interface UnitPrecision
{
    getPrecision: (unit: Unit) => number;
    getPrecisionForProperty?: (uiProperty: number, unit: Unit) => number | undefined;
}

export abstract class UnitServiceBase {
    public abstract getUnitGroupFromUnit(unit: Unit): UnitGroup;
    public abstract getInternalUnit(unitGroup: UnitGroup): any;
    public abstract convertUnitValueToUnit(unitValue: UnitValue, unit: Unit): UnitValue;
    public abstract incDecValueByUnit(unit: Unit, stepValue?: number): number;
    public abstract formatUnitValue(value: UnitValue, precision?: number, decimalSeparator?: string, groupSeparator?: string, uiProperty?: any, toFixed?: boolean): string;
    public abstract getPrecision(unit: Unit, uiProperty?: any, unitPrecision?: UnitPrecision): number;
    public abstract formatNumber(value: number, decimals?: number, toFixed?: boolean | null, decimalSeparator?: string, groupSeparator?: string): string;
    public abstract parseUnitValue(value: string, unitGroup: UnitGroup, defaultUnit?: Unit, decimalSeparator?: string, groupSeparator?: string): UnitValue;
    public abstract formatUnitValueArgs(value: number, unit?: Unit, precision?: number, decimalSeparator?: string, groupSeparator?: string, uiProperty?: any, appendUnit?: boolean, toFixed?: boolean): string;
    public abstract formatUnitAsDefault(value: string, unitGroup: UnitGroup): string;
    public abstract convertUnitValueArgsToUnit(value: number, unitFrom: Unit, unitTo: Unit, useDefaulPrecision?: boolean): number;
    public abstract transformWithSeparator(value: string, fromDecimalSeparator: string, fromGroupSeparator: string, toDecimalSeparator: string, toGroupSeparator: string, precision?: number, uiProperty?: any): string;
    public abstract getDefaultUnit(unitGroup: UnitGroup, design?: IUnitProviderObject): Unit;
    public abstract formatInternalValueAsDefault(value: number, unitGroup: UnitGroup, precision?: number, design?: IUnitProviderObject): string;
    public abstract getUnitStrings(unit: Unit): string[];
    public abstract parseNumber(value: string, decimalSeparator?: string, groupSeparator?: string): number;
    public abstract formatUnit(unit: Unit): string;
    public abstract convertInternalValueToDefaultValue(value: number, unitGroup: UnitGroup): number;
    public abstract getUnitGroupCodeList(unitGroup: UnitGroup): CodeList[];
    public abstract parseUnknownUnitValue(value: string, decimalSeparator?: string, groupSeparator?: string): UnitValue;
    public abstract getDefaultPrecision(): number;
    public abstract convertInternalValueToDefaultUnitValue(value: number, unitGroup: UnitGroup): UnitValue;
    public abstract convertUnitValueToInternalUnitValue(unitValue: UnitValue): UnitValue | undefined;
    public abstract appendPrecisionLossSymbolToValueString(value: number, valueString: string): string;
    public abstract appendUnitToValueString(value: number, valueString: string, unit?: Unit): string;
}

export abstract class UnitServiceInjected extends UnitServiceBase {
    public setBaseService(baseService: UnitServiceBase) {
        this.getUnitGroupFromUnit = baseService.getUnitGroupFromUnit.bind(baseService);
        this.getInternalUnit = baseService.getInternalUnit.bind(baseService);
        this.convertUnitValueToUnit = baseService.convertUnitValueToUnit.bind(baseService);
        this.incDecValueByUnit = baseService.incDecValueByUnit.bind(baseService);
        this.formatUnitValue = baseService.formatUnitValue.bind(baseService);
        this.getPrecision = baseService.getPrecision.bind(baseService);
        this.formatNumber = baseService.formatNumber.bind(baseService);
        this.parseUnitValue = baseService.parseUnitValue.bind(baseService);
        this.formatUnitValueArgs = baseService.formatUnitValueArgs.bind(baseService);
        this.formatUnitAsDefault = baseService.formatUnitAsDefault.bind(baseService);
        this.convertUnitValueArgsToUnit = baseService.convertUnitValueArgsToUnit.bind(baseService);
        this.transformWithSeparator = baseService.transformWithSeparator.bind(baseService);
        this.getDefaultUnit = baseService.getDefaultUnit.bind(baseService);
        this.getUnitStrings = baseService.getUnitStrings.bind(baseService);
        this.formatInternalValueAsDefault = baseService.formatInternalValueAsDefault.bind(baseService);
        this.parseNumber = baseService.parseNumber.bind(baseService);
        this.formatUnit = baseService.formatUnit.bind(baseService);
        this.convertInternalValueToDefaultValue = baseService.convertInternalValueToDefaultValue.bind(baseService);
        this.getUnitGroupCodeList = baseService.getUnitGroupCodeList.bind(baseService);
        this.parseUnknownUnitValue = baseService.parseUnknownUnitValue.bind(baseService);
        this.getDefaultPrecision = baseService.getDefaultPrecision.bind(baseService);
        this.convertInternalValueToDefaultUnitValue = baseService.convertInternalValueToDefaultUnitValue.bind(baseService);
        this.convertUnitValueToInternalUnitValue = baseService.convertUnitValueToInternalUnitValue.bind(baseService);
        this.appendPrecisionLossSymbolToValueString = baseService.appendPrecisionLossSymbolToValueString.bind(baseService);
        this.appendUnitToValueString = baseService.appendUnitToValueString.bind(baseService);
    }

    // UnitServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public getUnitGroupFromUnit(_unit: Unit) {
        return UnitGroup.None;
    }

    public getInternalUnit(_unitGroup: UnitGroup) {
        return UnitGroup.None as any;
    }

    public convertUnitValueToUnit(_unitValue: UnitValue, unit: Unit) {
        return new UnitValue(0, unit);
    }

    public incDecValueByUnit(_unit: Unit, stepValue?: number) {
        return 0;
    }

    public formatUnitValue(_value: UnitValue, _precision?: number, _decimalSeparator?: string, _groupSeparator?: string, _uiProperty?: any, _toFixed?: boolean) {
        return '';
    }

    public getPrecision(_unit: Unit, _uiProperty?: any, _unitPrecision?: UnitPrecision) {
        return 0;
    }

    public formatNumber(_value: number, _decimals?: number, _toFixed?: boolean | null, _decimalSeparator?: string, _groupSeparator?: string) {
        return '';
    }

    public parseUnitValue(_value: string, _unitGroup: UnitGroup, _defaultUnit?: Unit, _decimalSeparator?: string, _groupSeparator?: string){
        return new UnitValue(0, Unit.C);
    }

    public formatUnitValueArgs(_value: number, _unit?: Unit, _precision?: number, _decimalSeparator?: string, _groupSeparator?: string, _uiProperty?: any, _appendUnit?: boolean, _toFixed?: boolean){
        return '';
    }

    public formatUnitAsDefault(_value: string, _unitGroup: UnitGroup): string {
        return '';
    }

    public convertUnitValueArgsToUnit(_value: number, _unitFrom: Unit, _unitTo: Unit, _useDefaulPrecision?: boolean) {
        return 0;
    }

    public transformWithSeparator(_value: string, _fromDecimalSeparator: string, _fromGroupSeparator: string, _toDecimalSeparator: string, _toGroupSeparator: string, _precision?: number, _uiProperty?: any): string {
        return '';
    }

    public getDefaultUnit(_unitGroup: UnitGroup, _design?: IUnitProviderObject): Unit {
        return Unit.None;
    }

    public formatInternalValueAsDefault(_value: number, _unitGroup: UnitGroup, _precision?: number, _design?: IUnitProviderObject) {
        return '';
    }

    public getUnitStrings(_unit: Unit): string[] {
        return [];
    }

    public parseNumber(_value: string, _decimalSeparator?: string, _groupSeparator?: string): number {
        return 0;
    }

    public formatUnit(unit: Unit): string {
        return '';
    }

    public convertInternalValueToDefaultValue(_value: number, _unitGroup: UnitGroup): number {
        return 0;
    }

    public getUnitGroupCodeList(_unitGroup: UnitGroup): CodeList[] {
        return [];
    }

    public parseUnknownUnitValue(_value: string, _decimalSeparator?: string, _groupSeparator?: string): UnitValue {
        return new UnitValue(0, Unit.None);
    }

    public getDefaultPrecision(): number {
        return 0;
    }

    public convertInternalValueToDefaultUnitValue(_value: number, _unitGroup: UnitGroup): UnitValue {
        return new UnitValue(0, Unit.None);
    }

    public convertUnitValueToInternalUnitValue(_unitValue: UnitValue) : UnitValue | undefined{
        return undefined;
    }

    public appendPrecisionLossSymbolToValueString(_value: number, _valueString: string) : string {
        return '';
    }

    public appendUnitToValueString(_value: number, _valueString: string, _unit?: Unit) : string {
        return '';
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
