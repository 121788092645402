import {
    Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation
} from '@angular/core';

import { ResizerDirection } from './resizer.common';

@Component({
    encapsulation: ViewEncapsulation.ShadowDom,
    templateUrl: './resizer.component.html',
    styleUrls: ['./resizer.component.scss']
})
export class ResizerComponent implements OnInit {
    @Input()
    // Target element (element which gets resized)
    public targetHtmlElement!: HTMLElement;

    @Input()
    // Parent element (element to which overlay is inserted, also used for height calculation)
    public parentHtmlElement!: HTMLElement;

    @Input()
    // Accomodates for target element's margins and other fixes
    public correction = 0;

    @Input()
    public direction: ResizerDirection = 'down';

    @Input()
    // If disabled resize handle will be hidden!
    public disabled = false;

    @Output()
    public sizeChange: EventEmitter<void> = new EventEmitter<void>();

    private resizeOverlay!: HTMLElement;

    constructor(
        public elementRef: ElementRef
    ) {}

    ngOnInit() {
        this.resizeOverlay = document.createElement('div');
        this.resizeOverlay.setAttribute('id', 'resize-drag-handle-horizontal');
    }

    readonly boundResizing = (e: MouseEvent) => this.resizing(e);
    readonly boundResizeEnd = (e: MouseEvent) => this.resizeEnd(e);

    public resizeStart(event: MouseEvent): void {
        event.preventDefault();

        this.attachDragHandle();

        document.addEventListener('mousemove', this.boundResizing, false);
        document.addEventListener('mouseup', this.boundResizeEnd, false);
    }

    private resizing(event: MouseEvent): void {
        this.resizeOverlay.style.top = event.clientY + 'px';
    }

    private resizeEnd(event: MouseEvent): void {
        event.preventDefault();

        const offsetTop = this.detachDragHandle();
        this.resizeTarget(offsetTop);

        document.removeEventListener('mousemove', this.boundResizing, false);
        document.removeEventListener('mouseup', this.boundResizeEnd, false);

        this.sizeChange.emit();
    }

    private attachDragHandle() {
        if (this.parentHtmlElement == null) {
            return;
        }

        const position = this.parentHtmlElement.getBoundingClientRect();

        this.resizeOverlay.style.left = position.left + 'px';
        this.resizeOverlay.style.width = position.width + 'px';
        this.resizeOverlay.style.top = '-1px';
        this.parentHtmlElement.append(this.resizeOverlay);

        this.parentHtmlElement.classList.add('resizing');
    }

    private detachDragHandle() {
        const offsetTop = this.resizeOverlay.offsetTop;
        this.resizeOverlay.remove();
        return offsetTop;
    }

    private resizeTarget(offsetTop: number) {
        if (this.parentHtmlElement == null || this.targetHtmlElement == null) {
            return;
        }

        const parentElementPosition = this.parentHtmlElement.getBoundingClientRect();

        if (offsetTop > 0) {
            let height = 0;

            if (this.direction == 'up') {
                height = parentElementPosition.height + parentElementPosition.top - offsetTop;

                this.targetHtmlElement.style.height = height + 'px';
            }
            else {
                // Difference between parent element and target's parent element heights
                const targetOffset = this.parentHtmlElement.offsetHeight - (this.targetHtmlElement.offsetParent as HTMLElement).offsetHeight;

                // Height (in percent)
                // - offsetTop: resize overlay offset from the top of the window
                // - parentElementPosition.top: offset of parent element from the top of the window
                // - parentElementPosition.height: height of parent element
                // - targetOffset: described above
                height = (offsetTop - parentElementPosition.top) / (parentElementPosition.height - targetOffset) * 100;

                // Correction: accomodates for target element's margins and other fixes
                this.targetHtmlElement.style.height = `calc(${height}% + ${this.correction}px)`;
            }
        }

        this.parentHtmlElement.classList.remove('resizing');
    }
}
