<div *ngIf="options" class="ngx-slider-wrapper">
    <pe-control-title
        [focusControlId]="id"
        [title]="title"
        [tooltip]="tooltip"
        [infoPopup]="infoPopup"
        [isAdvanceInputPopup]="isAdvanceInput"
        [infoPopupTooltip]="infoPopupTooltip">
    </pe-control-title>
    <div [id]="id" *ngIf="sliderType == sliderTypeEnum.Range">
        <ngx-slider [id]="id + '-rangeSlider'" [(value)]="lowerValue" [(highValue)]="upperValue" (userChange)="changeSliderValue(lowerValue, upperValue)" [options]="options"></ngx-slider>
    </div>
    <div [id]="id" *ngIf="sliderType == sliderTypeEnum.Single" class="single-slider-parent">
        <div>
            <ngx-slider [id]="id + '-singleSlider'" (userChange)="changeSliderValue(lowerValue)" [(value)]="lowerValue" [options]="options"></ngx-slider>
        </div>
        <div class="stepper-container" *ngIf="!isAdvanceInput">
            <span>
                <pe-numeric-text-box
                    [id]="id + '-slider-textbox'"
                    [value]="lowerValue"
                    (valueChange)="changeSliderValue($any($event).detail)"
                    [minValue]="options.floor"
                    [maxValue]="options.ceil"
                    [unit]="defaultUnit"
                    [type]="'text'">
                </pe-numeric-text-box>
            </span>
        </div>
    </div>
</div>
