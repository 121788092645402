import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { stringNullOrEmpty } from '../../helpers/string-helper';
import { LocalizationService } from '../../services/localization.service';
import { OfflineService } from '../../services/offline.service';
import { ProductInformationService } from '../../services/product-information.service';
import { ButtonLook } from '../button/button.common';

@Component({
    templateUrl: './info-link-button.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InfoLinkButtonComponent {
    @Input()
    public buttonLook: string = ButtonLook.Primary;

    @Input()
    public translationKey = 'Agito.Hilti.Profis3.LearnMore.DefaultText';

    @Input()
    public infoLinkKey?: string = undefined;

    @Output()
    public buttonClick = new EventEmitter<Event>();


    constructor(
        public localizationService: LocalizationService,
        private productInformationService: ProductInformationService,
        private offlineService: OfflineService,
    ) { }


    public get isDisplayed() {
        return !stringNullOrEmpty(this.getInfoLink());
    }

    public onClick(e: Event) {
        const url = this.getInfoLink();
        if (!stringNullOrEmpty(url)) {
            this.offlineService.nativeExternalLinkOpen(url ?? '');
            this.buttonClick?.emit(e);
        }
    }

    private getInfoLink(): string | undefined {
        if (!stringNullOrEmpty(this.infoLinkKey)) {
            return this.productInformationService.getInfoLink(this.infoLinkKey ?? '');
        }

        return undefined;
    }
}
