import debounce from 'lodash-es/debounce';
import { randomString } from 'src/common/helpers/random';
import { UnitType as Unit } from 'src/common/helpers/unit-helper';
import { IValidationAdditionalData } from 'src/common/helpers/validation-helper';
import { LocalizationService } from 'src/common/services/localization.service';
import { MathService } from 'src/common/services/math.service';
import { UnitValue } from 'src/common/services/unit.common';
import { UnitService } from 'src/common/services/unit.service';

import {
    ChangeDetectorRef, Component, EventEmitter, HostListener, Input, NgZone, OnChanges, OnInit,
    Output, SimpleChanges, ViewEncapsulation
} from '@angular/core';
import { ValidatorFn } from '@angular/forms';

import { Tooltip } from '../content-tooltip/content-tooltip.common';
import { InfoPopup } from '../control-title/control-title.common';
import { TextBoxAlign, TextBoxBackground, TextBoxBorder, TextBoxDisplay, TextBoxLook, TextBoxType } from '../text-box/text-box.common';

const stepperValueChangeDelay = 400;
const stepperButtonDelay = 500;
const stepperInterval = 70;

enum StepperType {
    increment,
    decrement
}

@Component({
    templateUrl: './numeric-text-box.component.html',
    styleUrls: ['./numeric-text-box.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class NumericTextBoxComponent implements OnInit, OnChanges {
    @Input()
    public id?: string;

    @Input()
    public title?: string;

    @Input()
    public prefix?: string;

    @Input()
    public value?: number;

    @Output()
    public valueChange = new EventEmitter<number>();

    @Input()
    public unit?: Unit;

    @Input()
    public decimalSeparator?: string;

    @Input()
    public groupSeparator?: string;

    @Input()
    public placeholder?: string | number;

    @Input()
    public disabled = false;

    @Input()
    public type: TextBoxType = 'text';

    @Input()
    public minValue?: number;

    @Input()
    public maxValue?: number;

    @Input()
    public precision?: number;

    @Input()
    public tooltip?: Tooltip;

    @Input()
    public infoPopup?: InfoPopup;

    @Input()
    public infoPopupTooltip?: Tooltip;

    @Input()
    public submitted = false;

    @Output()
    public submittedChange = new EventEmitter<boolean>();

    @Input()
    public updateOnBlur = false;

    @Input()
    public debounceStepperChanges = false;

    @Input()
    public required = false;

    @Input()
    public defaultStepperValue?: number;

    @Input()
    public stepValue?: number;

    @Output()
    public enterPressed = new EventEmitter<number>();

    // Look
    @Input()
    public look = TextBoxLook.Normal;

    @Input()
    public showStepper = true;

    @Input()
    public appendUnit = true;

    @Input()
    public fixedDecimals = false;

    @Input()
    public borderTop = TextBoxBorder.Normal;

    @Input()
    public borderBottom = TextBoxBorder.Normal;

    @Input()
    public borderLeft = TextBoxBorder.Normal;

    @Input()
    public borderRight = TextBoxBorder.Normal;

    @Input()
    public textAlign = TextBoxAlign.Start;

    @Input()
    public background = TextBoxBackground.Normal;

    @Input()
    public display = TextBoxDisplay.Normal;

    @Input()
    public height: number | undefined;

    @Input()
    public width: number | undefined;

    // Validation
    @Input()
    public validators?: ValidatorFn[];

    @Input()
    public validationData?: IValidationAdditionalData;

    @Output()
    public isValid = new EventEmitter<boolean>();

    // Internal
    public displayValue?: string;
    public textBoxId?: string;
    public textBoxTitle?: string;

    private timeoutId?: number;
    private intervalId?: number;
    private debouncedValueChangeFn?: ((value?: number) => void);


    constructor(
        private localizationService: LocalizationService,
        private math: MathService,
        private unitService: UnitService,
        private ngZone: NgZone,
        private changeDetector: ChangeDetectorRef
    ) {
    }


    private get unitInternal() {
        return this.unit ?? Unit.None;
    }

    private get unitGroup() {
        return this.unitService.getUnitGroupFromUnit(this.unitInternal);
    }

    private get internalUnit() {
        return this.unitService.getInternalUnit(this.unitGroup);
    }

    public ngOnInit(): void {
        this.id = this.textBoxId = randomString(8);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const unitChanged = changes['unit']?.currentValue != changes['unit']?.previousValue;
        if (changes['value'] != null || unitChanged) {
            this.updateDisplayValue();
        }

        if (changes['id'] != null) {
            this.textBoxId = this.id;
        }

        if (changes['title'] != null) {
            this.textBoxTitle = this.title;
        }

        if (this.debounceStepperChanges) {
            this.debouncedValueChangeFn = debounce((value) => {
                this.valueChange.emit(value);
            }, stepperValueChangeDelay);
        }
        else {
            this.debouncedValueChangeFn = undefined;
        }
    }

    @HostListener('document:mouseup')
    clickedOut() {
        this.stopDebounce();
    }

    public displayPlaceholder() {
        if (this.placeholder != null) {
            if (typeof this.placeholder == 'string') {
                return this.placeholder;
            }
            else {
                return this.formatDisplayValue(this.placeholder);
            }
        }

        return null;
    }

    public l10n(key: string) {
        return this.localizationService.getString(key);
    }

    public incrementTooltip() {
        return this.l10n('Agito.Hilti.Profis3.TextBox.StepperIncrement');
    }

    public decrementTooltip() {
        return this.l10n('Agito.Hilti.Profis3.TextBox.StepperDecrement');
    }

    public debounce(stepperType: StepperType) {
        this.timeoutId = setTimeout(() => {
            this.intervalId = setInterval(() => {
                NgZone.assertInAngularZone();

                this.changeValue(stepperType);
            }, stepperInterval);
        }, stepperButtonDelay);
    }

    public stopDebounce() {
        if (this.intervalId != null) {
            clearInterval(this.intervalId);
            this.intervalId = undefined;
        }

        if (this.timeoutId != null) {
            clearTimeout(this.timeoutId);
            this.timeoutId = undefined;
        }
    }

    public onUserChange(value: string) {
        this.displayValue = value;
        this.parseAndSet(value, false);
    }

    public onEnterPressed() {
        this.enterPressed.emit(this.value);
    }

    public increment() {
        this.changeValue(StepperType.increment);
    }

    public decrement() {
        this.changeValue(StepperType.decrement);
    }

    private changeValue(stepperType: StepperType) {
        if (this.value != null) {
            // convert value from internal unit to user selected unit
            let value = this.unitService.convertUnitValueToUnit(({ value: this.value, unit: this.internalUnit }) as UnitValue, this.unitInternal).value;

            // find step to increment / decrement
            const stepValue = this.unitService.incDecValueByUnit(this.unitInternal, this.stepValue);

            // update value
            value += stepperType == StepperType.increment ? stepValue : -(stepValue);

            const formattedUnitValue = this.unitService.formatUnitValue(({ value, unit: this.unitInternal }) as UnitValue);

            // display value
            this.parseAndSet(formattedUnitValue, true);
        }
        else if (this.defaultStepperValue != null) {
            // convert value from internal unit to user selected unit
            const value = this.unitService.convertUnitValueToUnit(({ value: this.defaultStepperValue, unit: this.internalUnit }) as UnitValue, this.unitInternal).value;

            const precision = this.precision ?? this.unitService.getPrecision(Unit.None);
            const formattedUnitValue = this.unitService.formatNumber(value, precision);

            // display value
            this.parseAndSet(formattedUnitValue, true);
        }
        else if (this.placeholder != null && (typeof this.placeholder == 'number')) {
            this.setValue(true, this.placeholder);
        }
    }

    private parseAndSet(input: string, fromStepper: boolean) {
        const rawInput = input;
        const computedValue = this.math.tryComputeUnitValue(input, this.unitGroup, this.unitInternal, null, this.precision);
        if (computedValue != null) {
            input = computedValue;
        }

        const unitValue = this.unitService.parseUnitValue(input, this.unitGroup, this.unitInternal);

        let value: number | undefined;
        let valueFixed = false;
        if (unitValue != null && !Number.isNaN(unitValue.value)) {
            value = this.unitService.convertUnitValueToUnit(unitValue, this.internalUnit).value;

            const [valueChecked, valueFixedChecked] = this.checkParsedUnitValue(value, rawInput);
            value = valueChecked;
            valueFixed = valueFixedChecked;
        }
        else if (this.required) {
            value = this.value;
            valueFixed = true;
        }

        if (valueFixed) {
            // The value is changed inside the same cycle so change detection
            // needs to be run again before the new change
            this.changeDetector.detectChanges();
        }

        this.setValue(fromStepper, value);
    }

    private formatDisplayValue(value?: number) {
        if (value == null) {
            return undefined;
        }

        const formattedValue = this.formatUnitAndValue(value);

        return `${this.prefix ?? ''}${formattedValue}`;
    }

    private formatUnitAndValue(value: number) {
        const precision = this.precision ?? this.unitService.getPrecision(this.unitInternal);
        return this.unitService.formatUnitValueArgs(
            this.unitService.convertUnitValueArgsToUnit(value, this.internalUnit, this.unitInternal),
            this.unitInternal,
            precision,
            this.decimalSeparator,
            this.groupSeparator,
            null,
            this.appendUnit,
            this.fixedDecimals
        );
    }

    private updateDisplayValue() {
        const displayValue = this.formatDisplayValue(this.value);
        if (this.displayValue != displayValue) {
                // The value is changed inside the same cycle so change detection
                // needs to be run again after the new change
            this.displayValue = displayValue;
            this.ngZone.run(() => { /**/ });
        }
    }

    private setValue(fromStepper: boolean, value?: number) {
        this.value = value;
        this.updateDisplayValue();

        // If change originates from stepper we might want to
        // debounce it (i.e. not to trigger calculation immediatelly)
        if (fromStepper && this.debouncedValueChangeFn != null) {
            this.debouncedValueChangeFn(this.value);
        }
        else {
            this.valueChange.emit(this.value);
        }
    }

    private checkParsedUnitValue(valueInput: number, rawInput: string): [number, boolean] {
        let value = valueInput;
        let valueFixed = false;

        if (this.maxValue != null && valueInput > this.maxValue) {
            value = this.maxValue;
            valueFixed = true;
        }

        if (this.minValue != null && valueInput < this.minValue) {
            value = this.minValue;
            valueFixed = true;
        }

        if (!valueFixed && this.value == value) {
            // The same value entered again
            // Check if formatted value is different from the entered one
            // If so, force value update in TextBox
            const formattedValue = this.formatDisplayValue(this.value);
            if (rawInput != formattedValue) {
                valueFixed = true;
            }
        }

        return [value, valueFixed];
    }
}
