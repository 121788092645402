<div [class.loading]="loading">
    <div class="loading-container" *ngIf="loading">
        <pe-loading [width]="24" [height]="6" [color]="color"></pe-loading>
    </div>

    <div class="content-container">
        <span class="text">{{text}}</span>
        <slot class="content"></slot>
    </div>
</div>
