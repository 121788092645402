import { randomString } from 'src/common/helpers/random';

import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';

import { ngbTooltipTemplate, Tooltip } from '../content-tooltip/content-tooltip.common';
import {
    ButtonGroupDisplay, ToggleButtonGroupItem, ToggleButtonGroupLook, ToggleButtonGroupType
} from './toggle-button-group.common';
import { IConfirmChangeProps } from '../confirm-change/confirm-change.common';
import { ModalService } from '../../services/modal.service';

@Component({
    templateUrl: './toggle-button-group.component.html',
    styleUrls: ['./toggle-button-group.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ToggleButtonGroupComponent<TValue> implements OnChanges {
    public ngbTooltipTemplate = ngbTooltipTemplate;
    public ButtonGroupDisplay = ButtonGroupDisplay;

    @Input()
    public id = randomString(8);

    @Input()
    public title?: string;

    @Input()
    public type = ToggleButtonGroupType.single;

    @Input()
    public display = ButtonGroupDisplay.join;

    @Input()
    public look = ToggleButtonGroupLook.Normal;

    @Input()
    public model?: TValue[] | TValue;

    @Input()
    public items: ToggleButtonGroupItem<TValue>[] = [];

    @Input()
    public disabled = false;

    @Input()
    public unselectable = false;

    @Input()
    public tooltip?: Tooltip;

    @Input()
    public itemHeight: number | undefined;

    @Input()
    public itemWidth: number | undefined;

    //set this if popup needs to be triggerred
    @Input()
    public confirmProps?: IConfirmChangeProps;

    @Output()
    public modelChange = new EventEmitter<TValue[] | TValue>();

    public additionalClasses = '';

    constructor(public modalService: ModalService){
    }

    getToggleMultipleType() {
        return ToggleButtonGroupType.multiple;
    }

    public ngOnChanges(): void {
        // CSS classes
        const additionalCssClasses: string[] = [];
        switch (this.look) {
            case ToggleButtonGroupLook.Normal:
                additionalCssClasses.push('button-ghost');
                additionalCssClasses.push('button-ghost-white');
                break;

            case ToggleButtonGroupLook.Default:
                additionalCssClasses.push('button-default');
                break;

            case ToggleButtonGroupLook.Primary:
                additionalCssClasses.push('button-primary');
                break;

            default:
                break;
        }

        this.additionalClasses = additionalCssClasses.join(' ');
    }

    public isItemActive(item: ToggleButtonGroupItem<TValue>) {
        if (this.type == ToggleButtonGroupType.multiple) {
            const model = this.model as TValue[];
            return model?.some((value) => item.value == value);
        }
        else {
            const model = this.model as TValue;
            return model == item.value;
        }
    }

    public openConfirmChange(item: ToggleButtonGroupItem<TValue>){
        this.modalService.openConfirmChange({
            id: this.confirmProps?.id ?? '',
            title: this.confirmProps?.title,
            message: this.confirmProps?.message,
            confirmButtonText: this.confirmProps?.confirmButtonText,
            cancelButtonText: this.confirmProps?.cancelButtonText,
            onConfirm: async (modal) => {
                this.clickAction(item);
                modal.close();
            },
            onCancel: (modal) => {
                modal.close();
            }
        });
    }

    public onClick(item: ToggleButtonGroupItem<TValue>) {
        if(this.confirmProps && item.confirmClick){
            this.openConfirmChange(item);
            return ;
        }

        this.clickAction(item);
    }

    private clickAction(item: ToggleButtonGroupItem<TValue>){
        let changed = false;
        let newItemActive = false;

        if (item.value != null) {
            newItemActive = this.isItemActive(item) ? false : true;

            if (newItemActive) {
                changed = this.clickActionOnNewItem(item.value);
            }
            else {
                changed = this.clickActionOnActiveItem(item.value);
            }
        }

        // if model has changed
        if (changed) {
            this.modelChange.emit(this.model);
        }
    }

    private clickActionOnNewItem(itemValue: TValue) {
        let changed = false;

        if (this.type == ToggleButtonGroupType.multiple) {
            let model = this.model as TValue[];

            if (!model.some((modelValue) => modelValue == itemValue)) {
                this.model = model = ((this.model as TValue[]) || []);
                model.push(itemValue);
                changed = true;
            }
        }
        else {
            this.model = itemValue;
            changed = true;
        }

        return changed;
    }

    private clickActionOnActiveItem(itemValue: TValue) {
        let changed = false;
        const activeItems = this.items.some((toggleButton) => this.isItemActive(toggleButton));

        // Unselectable and actually something to unselect
        if (this.unselectable && activeItems) {
            if (this.type == ToggleButtonGroupType.multiple) {
                const model = this.model as TValue[];
                const modelValueIndex = model.indexOf(itemValue);

                if (modelValueIndex != -1) {
                    model.splice(modelValueIndex, 1);
                    changed = true;
                }
            }
            else if (this.model == itemValue) {
                this.model = undefined;
                changed = true;
            }
        }

        // Not unselectable or nothing to unselect

        return changed;
    }
}
