<div *ngIf="isLoaded">
    <div class="tooltip-wrapper" [ngbTooltip]="'Agito.Hilti.Profis3.Browser.NoInternetConnection' | l10n: localizationService.selectedLanguage"
        [disableTooltip]="isOfflineOnLine">
        <pe-checkbox-button
            [id]="'export-report-trimbleconnect-section-upload-checkbox'"
            [items]="trimbleConnectUploadCheckbox.items"
            [selectedValues]="trimbleConnectUploadCheckbox.selectedValues"
            (selectedValuesChange)="trimbleConnectUploadCheckbox.selectedValues = $any($event).detail; onTrimbleConnectCheckedChange()"
            class="trimble-connect-upload control-row er-checkbox"
            [disabled]="submitted || !isOfflineOnLine || reportDisabled">
        </pe-checkbox-button>
    </div>

    <div class="trimble-connect-location-container">
        <pe-text-box class="trimble-connect-location"
            [id]="'export-report-trimbleconnect-section-location-texbox'"
            [title]="'Agito.Hilti.Profis3.ExportReport.Layout.TrimbleConnect.Location' | l10n: localizationService.selectedLanguage"
            [value]="trimbleConnectLocation"
            (valueChange)="trimbleConnectLocation = $any($event).detail; trimbleConnectLocationChange.emit($any($event).detail)"
            [disabled]="true" [maxLength]="512">
        </pe-text-box>

        <pe-button
            class="trimble-connect-select-location"
            [id]="'export-report-trimbleconnect-section-selectlocation-button'"
            [look]="'Default'"
            (buttonClick)="trimbleConnectSelectLocation()"
            [text]="'Agito.Hilti.Profis3.ExportReport.TrimbleConnect.SelectLocation' | l10n: localizationService.selectedLanguage"
            [disabled]="submitted || !((trimbleConnectUploadCheckbox.selectedValues?.size ?? 0) > 0)"
            [ngbTooltip]="trimbleConnectTooltip">
        </pe-button>
    </div>

    <pe-text-box class="trimble-connect-report-name control-row"
        [id]="'export-report-trimbleconnect-section-reportname-texbox'"
        [title]="'Agito.Hilti.Profis3.ExportReport.Layout.TrimbleConnect.ReportName' | l10n: localizationService.selectedLanguage"
        [value]="trimbleConnectReportName"
        (valueChange)="trimbleConnectReportName = $any($event).detail; trimbleConnectReportNameChange.emit($any($event).detail)"
        [disabled]="submitted || !((trimbleConnectUploadCheckbox.selectedValues?.size ?? 0) > 0) || !isOfflineOnLine"
        [maxLength]="256" [tooltip]="trimbleConnectTooltip">
    </pe-text-box>
</div>
