import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { includeSprites } from '../../sprites';
import { ExportReportRowStatus } from './export-report-row-base.common';

@Component({
    templateUrl: './export-report-row-base.component.html',
    styleUrls: ['./export-report-row-base.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ExportReportRowBaseComponent implements OnInit {

    public exportReportRowStatus = ExportReportRowStatus;

    @Input()
    public displayName?: string;
    @Input()
    public displayApprovalNumber?: string;

    @Input()
    public percentage = 0;
    @Input()
    public message?: string;
    @Input()
    public status?: ExportReportRowStatus;

    @Output()
    removeExportRow = new EventEmitter();

    constructor(
        protected elementRef: ElementRef<HTMLElement>
    ) { }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-document',
            'sprite-delete-active'
        );
    }

    public remove() {
        this.removeExportRow.emit();
    }
}
