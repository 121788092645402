import { Injectable } from '@angular/core';
import {
    FeatureFlagFromPeUi, FeaturesVisibilityServiceInjected
} from 'src/common/services/feature-visibility.common';

export type FeatureFlag =
    FeatureFlagFromPeUi |
    'VersionPageVisibility';

@Injectable({
    providedIn: 'root'
})

export class FeaturesVisibilityService extends FeaturesVisibilityServiceInjected<FeatureFlag> { }
