<div class="card">
    <div class="image">
        <span class="pe-ui-common-sprite pe-ui-common-sprite-document"></span>
    </div>
    <div class="container">
        <h4 *ngIf="displayName">{{displayName}}</h4>
        <p *ngIf="displayApprovalNumber">{{displayApprovalNumber}}</p>
    </div>
    <div class="number" [class]="status" >
        <span *ngIf="status != exportReportRowStatus.Error;else removeRow">{{percentage}}%</span>
        <ng-template #removeRow>
            <span (click)="remove()" class="pe-ui-common-sprite pe-ui-common-sprite-delete-active"></span>
        </ng-template>
    </div>
    <div class="progress">
        <p *ngIf="message" [class]="status" [innerHtml]="message"></p>
        <div class="progress-bar" [class]="status" [ngStyle]="{'width': percentage+'%'}"></div>
    </div>
</div>
<hr>
