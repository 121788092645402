import {
    Component, ElementRef, EventEmitter, OnInit, Output, ViewEncapsulation
} from '@angular/core';
import { includeSprites } from '../../sprites';
import { ProductInformationService } from '../../services/product-information.service';
import { OfflineService } from '../../services/offline.service';
import { stringNullOrEmpty } from '../../helpers/string-helper';

@Component({
    templateUrl: './perfect-spec-logo.component.html',
    styleUrls: ['./perfect-spec-logo.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class PerfectSpecLogoComponent implements OnInit {
    @Output()
    public buttonClick = new EventEmitter<Event>();

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private productInformationService: ProductInformationService,
        private offlineService: OfflineService,
    ) { }

    public ngOnInit() {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-perfect-spec-logo-small',
            'sprite-info'
        );
    }


    public get shouldDisplayLink() {
        const link = this.getPerfectSpecLink();
        const display = !stringNullOrEmpty(link);
        return display;
    }

    public onClick(e: Event) {
        const url = this.getPerfectSpecLink();
        if (!stringNullOrEmpty(url)) {
            this.offlineService.nativeExternalLinkOpen(url ?? '');
            this.buttonClick?.emit(e);
        }
    }


    private getPerfectSpecLink(): string | undefined {
        return this.productInformationService.regionLinksForUser()?.PerfectSpecLink;
    }
}
