import { randomString } from 'src/common/helpers/random';
import { includeSprites } from 'src/common/sprites';

import {
    Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation
} from '@angular/core';

import {
    CheckboxButtonGroupItem, CheckboxButtonItem, CheckboxLook, InfoIcon, TooltipTarget
} from '../checkbox-button/checkbox-button.common';
import { ngbTooltipTemplate, Tooltip } from '../content-tooltip/content-tooltip.common';
import { InfoPopup } from '../control-title/control-title.common';

@Component({
    templateUrl: './checkbox-button-group.component.html',
    styleUrls: ['./checkbox-button-group.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class CheckboxButtonGroupComponent<TValue> implements OnInit {
    public tooltipTargetEnum = TooltipTarget;
    public infoIconEnum = InfoIcon;

    @Input()
    public id = randomString(8);

    @Input()
    public title?: string;

    @Input()
    public look = CheckboxLook.Normal;

    @Input()
    public selectedValues?: Set<TValue>;

    @Input()
    public groupItems?: CheckboxButtonGroupItem<TValue>[];

    @Input()
    public disabled = false;

    @Input()
    public tooltip?: Tooltip;

    @Input()
    public infoPopup?: InfoPopup;

    @Input()
    public infoPopupTooltip?: Tooltip;

    @Input()
    public testId?: string;

    @Output()
    public selectedValuesChange = new EventEmitter<TValue>();

    @Output()
    public itemInfoButtonClick = new EventEmitter<TValue>();

    @Output()
    public itemToggle = new EventEmitter<TValue>();

    public ngbTooltipTemplate = ngbTooltipTemplate;

    constructor(
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-checkbox'
        );
    }

    public onChange(item: CheckboxButtonItem<TValue>) {
        this.selectedValues = this.selectedValues ?? new Set();

        if (this.selectedValues.has(item.value)) {
            this.selectedValues.delete(item.value);
        }
        else {
            this.selectedValues.add(item.value);
        }

        this.selectedValuesChange.emit(item.value);
    }

    public onItemInfoButtonClick(event: MouseEvent, item: CheckboxButtonItem<TValue>) {
        if (item.infoIcon == InfoIcon.Button) {
            event.preventDefault();
            this.itemInfoButtonClick.emit(item.value);
        }
    }
}
