<div class="section" *ngIf="isLoaded">
    <div class="section-row">
        <div class="section-column project-details-left">
            <pe-label
                class="design-name control-row"
                [id]="'export-report-design-name'"
                [title]="'Agito.Hilti.Profis3.ExportReport.ProjectDetails.ProjectName' | l10n: localizationService.selectedLanguage"
                [text]="designName">
            </pe-label>
        </div>
        <div class="section-column project-details-middle">
            <div class="fastening-point control-row" *ngIf="fasteningPointVisible">
                <pe-text-box
                    [id]="fasteningPointId"
                    [title]="fasteningPointTitle"
                    [value]="fasteningPoint"
                    (valueChange)="fasteningPoint = $any($event).detail;fasteningPointChange.emit($any($event).detail)"
                    [disabled]="submitted">
                </pe-text-box>
            </div>

            <div class="notes control-row">
                <pe-text-area
                    [id]="'export-report-details-section-notes-textbox'"
                    [title]="'Agito.Hilti.Profis3.ExportReport.ProjectDetails.Notes' | l10n: localizationService.selectedLanguage"
                    [value]="notes"
                    (valueChange)="notes = $any($event).detail; notesChange.emit($any($event).detail)"
                    [disabled]="submitted"
                    [maxLength]="700"
                    [marginBottom]="'None'"
                    [height]="80"
                    class="notes-input"
                ></pe-text-area>
            </div>
        </div>
        <div class="section-column project-details-right">
            <div class="include-design control-row" *ngIf="isIncludeDetailsInReport">
                <p class="control-label" l10n="Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeDesign"></p>

                <pe-checkbox-button
                    [items]="includeDetailsInReport.items"
                    [selectedValues]="includeDetailsInReport.selectedValues"
                    (selectedValuesChange)="includeDetailsInReport.selectedValues = $any($event).detail"
                    [disabled]="submitted || reportDisabled">
                </pe-checkbox-button>
            </div>

            <div class="include-design control-row" *ngIf="includeSustainabilityInReport">
                <p class="control-label" l10n="Agito.Hilti.Profis3.ExportReport.Sustainability.Title"></p>

                <pe-checkbox-button
                    [items]="includeSustainabilityInReport.items"
                    [selectedValues]="includeSustainabilityInReport.selectedValues"
                    (selectedValuesChange)="includeSustainabilityInReport.selectedValues = $any($event).detail"
                    [disabled]="submitted || reportDisabled">
                </pe-checkbox-button>
            </div>
        </div>
    </div>
</div>
