import { Feature } from '../generated-modules/Hilti.PE.Common.Shared.Models.Enums';

export abstract class FeaturesVisibilityInfoServiceBase {
    public abstract isHidden(feature: Feature, regionId?: number): boolean;
    public abstract isDisabled(feature: Feature, regionId?: number): boolean;
    public abstract tooltip(feature: Feature): string;
    public abstract get areIntegrationsEnabled(): boolean;
    public abstract get hasAdvancedFeature(): boolean;
}

export class FeaturesVisibilityInfoServiceInjected extends FeaturesVisibilityInfoServiceBase {
    protected baseService!: FeaturesVisibilityInfoServiceBase;

    public setBaseService(baseService: FeaturesVisibilityInfoServiceBase) {
        this.baseService = baseService;

        this.isHidden = baseService.isHidden.bind(baseService);
        this.isDisabled = baseService.isDisabled.bind(baseService);
        this.tooltip = baseService.tooltip.bind(baseService);
    }


    /**
     * Value indicating whether integrations are enabled or disabled. Shortcut for:
     * !featuresVisibilityInfo.isDisabled(Feature.Application_Settings_InterfacesToStructuralCalculationSoftware)
     */
    public get areIntegrationsEnabled(): boolean {
        return this.baseService.areIntegrationsEnabled;
    }

    public get hasAdvancedFeature(): boolean {
        return this.baseService.hasAdvancedFeature;
    }


    // FeaturesVisibilityInfoService methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public isHidden(_feature: Feature, _regionId?: number): boolean {
        return false;
    }

    public isDisabled(_feature: Feature, _regionId?: number): boolean {
        return false;
    }

    public tooltip(_feature: Feature): string {
        return '';
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
