import {
    ReportTemplateEntity
} from '../generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';

export abstract class ReportTemplateServiceBase {
    public abstract templates: ReportTemplateEntity[];
}

export class ReportTemplateServiceInjected extends ReportTemplateServiceBase {
    protected baseService!: ReportTemplateServiceBase;

    public setBaseService(baseService: ReportTemplateServiceBase) {
        this.baseService = baseService;
    }

    public get templates() {
        return this.baseService.templates;
    }

    // ReportTemplateService methods
    /* eslint-disable @typescript-eslint/no-unused-vars */

    /* eslint-enable @typescript-eslint/no-unused-vars */
}
