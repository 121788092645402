import { IConfirmChangeProps } from '../components/confirm-change/confirm-change.common';
import { IAddEditDesignComponentFromModuleInput } from '../entities/add-edit-design-component';
import { IApplicationError } from '../entities/application-error';
import { IDesignSectionExportComponentInput } from '../entities/design-section-export';
import { IModalGridComponentInput } from '../entities/modal-grid';
import { ReleaseNote } from '../generated-modules/Hilti.PE.ProductInformationService.Shared.Entities';
import { IModalOpened, ModalContent, ModalOptions } from '../helpers/modal-helper';
import { ApplicationSettingsDisplayType } from '../helpers/app-settings-helper';

/**
 * Modal service base
 */
export abstract class ModalServiceBase {
    public abstract loadingCustomShown: boolean;
    public abstract loadingCustomOnCancel: () => void;
    public abstract loadingCustomLoadingText: string;

    public abstract openModal(content?: ModalContent, options?: ModalOptions, inputValues?: object): IModalOpened;
    public abstract openWebComponentModal(componentName: string, options?: ModalOptions, inputValues?: object): IModalOpened;
    public abstract openConfirmChange(props?: IConfirmChangeProps): IModalOpened;
    public abstract openModalGrid(props?: IModalGridComponentInput<any>, opts?: ModalOptions): IModalOpened;
    public abstract openLoadsMessages(messages: string[]): void;
    public abstract openAlertWarning(title: string, message: string): IModalOpened;
    public abstract openAlertError(title: string, message: string, applicationError?: IApplicationError): IModalOpened;
    public abstract openAlertGLError(): IModalOpened;
    public abstract openAlertSignalRError(applicationError: IApplicationError): void;
    public abstract openUnauthorizedAccess(): void;
    public abstract openDesignSectionExport(props?: IDesignSectionExportComponentInput): void;
    public abstract openSpecificationText(specificationText: string, showPerfectSpec?: boolean): void;
    public abstract openReportTemplates(templateId?: number): IModalOpened;
    public abstract openAddEditDesignFromModule(props?: IAddEditDesignComponentFromModuleInput): IModalOpened;
    public abstract openUserSettings(): void;
    public abstract openShortcutIcon(): void;
    public abstract openWhatsNew(releaseNotes: ReleaseNote[]): void;
    public abstract openSupport(applicationError?: IApplicationError, projectDesign?: object): void;
    public abstract openUserAgreementSettings(): void;
    public abstract openGeneralNotes(text: string, copyText: string): void;
    public abstract openSaveAsTemplate(props?: object): void;
    public abstract openVirtualTourPopup(selectTab?: (tab: string) => void): void;
    public abstract openApplicationSettings(initialDisplayType?: ApplicationSettingsDisplayType): void;
    public abstract loadingCustomOpen(text?: string, onCancel?: () => void): void;
    public abstract loadingCustomClose(): void;
    public abstract openFeedBackForm(): void;
    public abstract openVersionDetails(): void;
    public abstract openLDFlags(): void;
    public abstract openDocumentInUseWarning(user: string): IModalOpened;
}

export class ModalServiceInjected extends ModalServiceBase {
    protected baseService!: ModalServiceBase;

    public setBaseService(baseService: ModalServiceBase) {
        this.baseService = baseService;

        this.openModal = baseService.openModal.bind(baseService);
        this.openWebComponentModal = baseService.openWebComponentModal.bind(baseService);
        this.openConfirmChange = baseService.openConfirmChange.bind(baseService);
        this.openModalGrid = baseService.openModalGrid.bind(baseService);
        this.openLoadsMessages = baseService.openLoadsMessages.bind(baseService);
        this.openAlertWarning = baseService.openAlertWarning.bind(baseService);
        this.openAlertError = baseService.openAlertError.bind(baseService);
        this.openAlertGLError = baseService.openAlertGLError.bind(baseService);
        this.openAlertSignalRError = baseService.openAlertSignalRError.bind(baseService);
        this.openUnauthorizedAccess = baseService.openUnauthorizedAccess.bind(baseService);
        this.openDesignSectionExport = baseService.openDesignSectionExport.bind(baseService);
        this.openSpecificationText = baseService.openSpecificationText.bind(baseService);
        this.openReportTemplates = baseService.openReportTemplates.bind(baseService);
        this.openAddEditDesignFromModule = baseService.openAddEditDesignFromModule.bind(baseService);
        this.openUserSettings = baseService.openUserSettings.bind(baseService);
        this.openShortcutIcon = baseService.openShortcutIcon.bind(baseService);
        this.openWhatsNew = baseService.openWhatsNew.bind(baseService);
        this.openSupport = baseService.openSupport.bind(baseService);
        this.openUserAgreementSettings = baseService.openUserAgreementSettings.bind(baseService);
        this.openGeneralNotes = baseService.openGeneralNotes.bind(baseService);
        this.openSaveAsTemplate = baseService.openSaveAsTemplate.bind(baseService);
        this.openVirtualTourPopup = baseService.openVirtualTourPopup.bind(baseService);
        this.openApplicationSettings = baseService.openApplicationSettings.bind(baseService);
        this.loadingCustomOpen = baseService.loadingCustomOpen.bind(baseService);
        this.loadingCustomClose = baseService.loadingCustomClose.bind(baseService);
        this.openFeedBackForm=baseService.openFeedBackForm.bind(baseService);
        this.openVersionDetails=baseService.openVersionDetails.bind(baseService);
        this.openLDFlags = baseService.openLDFlags.bind(baseService);
        this.openDocumentInUseWarning = baseService.openDocumentInUseWarning.bind(baseService);
    }

    // ModalServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public get loadingCustomShown() {
        return this.baseService.loadingCustomShown;
    }

    public get loadingCustomOnCancel() {
        return this.baseService.loadingCustomOnCancel;
    }

    public get loadingCustomLoadingText() {
        return this.baseService.loadingCustomLoadingText;
    }

    public openModal(_content?: ModalContent, _options?: ModalOptions, _inputValues?: object): IModalOpened {
        return {} as IModalOpened;
    }

    public openWebComponentModal(_componentName: string, _options?: ModalOptions, _inputValues?: object): IModalOpened {
        return {} as IModalOpened;
    }

    public openConfirmChange(_props?: IConfirmChangeProps): IModalOpened {
        return {} as IModalOpened;
    }

    public openModalGrid(_props?: IModalGridComponentInput<any>, _opts?: ModalOptions) {
        return {} as IModalOpened;
    }

    public openLoadsMessages(_messages: string[]) {
        return;
    }

    public openAlertWarning(_title: string, _message: string) {
        return {} as IModalOpened;
    }

    public openAlertError(_title: string, _message: string, _applicationError?: IApplicationError) {
        return {} as IModalOpened;
    }

    public openAlertGLError() {
        return {} as IModalOpened;
    }

    public openAlertSignalRError(applicationError: IApplicationError) {
        return;
    }

    public openUnauthorizedAccess() {
        return;
    }

    public openDesignSectionExport(_props?: IDesignSectionExportComponentInput) {
        return;
    }

    public openSpecificationText(_specificationText: string, _showPerfectSpec?: boolean) {
        return;
    }

    public openReportTemplates(_templateId?: number) {
        return {} as IModalOpened;
    }

    public openAddEditDesignFromModule(props?: IAddEditDesignComponentFromModuleInput) {
        return {} as IModalOpened;
    }

    public openUserSettings() {
        return;
    }

    public openVersionDetails() {
        return;
    }

    public openShortcutIcon() {
        return;
    }

    public openWhatsNew(_releaseNotes: ReleaseNote[]) {
        return;
    }

    public openSupport(applicationError?: IApplicationError, projectDesign?: object) {
        return;
    }

    public openUserAgreementSettings() {
        return;
    }

    public openFeedBackForm(){
        return;
    }

    public openGeneralNotes(_text: string, _copyText: string) {
        return;
    }

    public openSaveAsTemplate(_props?: object) {
        return;
    }

    public openVirtualTourPopup(_selectTab?: (tab: string) => void) {
        return;
    }

    public openApplicationSettings(_initialDisplayType?: ApplicationSettingsDisplayType) {
        return;
    }

    public loadingCustomOpen(_text?: string, _onCancel?: () => void) {
        return;
    }

    public loadingCustomClose() {
        return;
    }

    public openLDFlags(){
        return;
    }

    public openDocumentInUseWarning(_user: string) {
        return this.openAlertWarning('', '');
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
