import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { randomString } from 'src/common/helpers/random';
import { includeSprites } from '../../sprites';
import { NgbTooltipValue } from '../content-tooltip/content-tooltip.common';
import { CollapseLook } from './section.common';

@Component({
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SectionComponent implements OnInit, OnChanges {
    public isSectionBoxStyled = false;

    @Input()
    public id = randomString(8);

    @Input()
    public title = '';

    @Input()
    public look = CollapseLook.Section;

    @Input()
    public collapsed = false;

    @Input()
    public collapseTooltip?: NgbTooltipValue;

    @Output()
    public collapsedChange = new EventEmitter<boolean>();

    constructor(private elementRef: ElementRef<HTMLElement>) { }

    public ngOnInit() {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-lines',
            'sprite-lines-expanded'
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['look'] != null) {
            this.isSectionBoxStyled = this.look == CollapseLook.SectionBox;
        }
    }

    public toggleCollapsed() {
        this.collapsed = !this.collapsed;

        this.collapsedChange.emit(this.collapsed);
    }
}
