import { ITrimbleConnectFileInfo, ITrimbleConnectSession } from '../entities/trimble-connect';

export abstract class TrimbleConnectServiceBase {
    public abstract readonly isEnabled: boolean;

    public abstract uploadDesign(projectDesign: Blob, browserOpend?: () => void): Promise<void>;
    public abstract getPdfLocation(): Promise<ITrimbleConnectFileInfo>;
    public abstract ensureSession(): Promise<ITrimbleConnectSession>;
    public abstract uploadFile(folderFileId: string, fileName: string, projectDesignFile: Blob): Promise<boolean>;
}

export abstract class TrimbleConnectServiceInjected extends TrimbleConnectServiceBase {
    protected baseService!: TrimbleConnectServiceBase;

    public get isEnabled() {
        return this.baseService.isEnabled;
    }

    public setBaseService(baseService: TrimbleConnectServiceBase) {
        this.baseService = baseService;

        this.uploadDesign = baseService.uploadDesign.bind(baseService);
        this.getPdfLocation = baseService.getPdfLocation.bind(baseService);
        this.ensureSession = baseService.ensureSession.bind(baseService);
        this.uploadFile = baseService.uploadFile.bind(baseService);
    }

    // TrimbleConnectServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public uploadDesign(_projectDesign: Blob, _browserOpend?: () => void) {
        return {} as Promise<void>;
    }

    public getPdfLocation() {
        return {} as Promise<ITrimbleConnectFileInfo>;
    }

    public ensureSession(): Promise<ITrimbleConnectSession> {
        return {} as Promise<ITrimbleConnectSession>;
    }

    public uploadFile(_folderFileId: string, _fileName: string, _projectDesignFile: Blob): Promise<boolean> {
        return {} as Promise<boolean>;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
