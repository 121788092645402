import { IIconStyle } from '../../helpers/image-helper';
import { Tooltip } from '../content-tooltip/content-tooltip.common';

export enum ButtonGroupDisplay {
    separate,
    join
}

export enum ToggleButtonGroupType {
    single,
    multiple
}

export const enum ToggleButtonGroupLook {
    Normal                      = 'Normal',
    Default                     = 'Default',
    Primary                     = 'Primary'
}

export interface ToggleButtonGroupProps<TValue = number> {
    id?: string;
    title?: string;
    type?: ToggleButtonGroupType;
    display?: ButtonGroupDisplay;
    look?: ToggleButtonGroupLook;
    model?: TValue[] | TValue;
    items?: ToggleButtonGroupItem<TValue>[];
    disabled?: boolean;
    unselectable?: boolean;
    tooltip?: Tooltip;
    itemHeight?: number;
    itemWidth?: number;
}

export interface ToggleButtonGroupItem<TValue = number> {
    id?: string;
    value?: TValue;
    text?: string;
    image?: IIconStyle;
    class?: string;
    disabled?: boolean;
    tooltip?: Tooltip;
    confirmClick?: boolean;
}
