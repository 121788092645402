<div class="buttons" *ngIf="isLoaded">
    <div class="notification-container notification-container-with-button notification-info" *ngIf="notification.condition()">
        <div class="notification-content-wrapper">
            <span class="notification-icon pe-ui-common-sprite pe-ui-common-sprite-{{NotificationTypeImage[NotificationType.info]}}"></span>
            <div class="notification-content notification-content-text" [innerHtml]="notification.text"></div>
        </div>
        <div class="notification-content notification-button" *ngIf="notification.button && notification.button?.condition()">
            <button class="button button-default button-margin-top"
                (click)="notification.button.click()"
                [disabled]="notification.button.disabled()"
                [innerHtml]="notification.button.buttonTitle"
                [ngbTooltip]="notification.button.tooltip"
                [disableTooltip]="returnFn(notification.button.disableTooltip)"
                type="button"></button>
        </div>
        <div *ngIf="notification.checkbox && notification.checkbox?.condition()">
            <pe-checkbox-button
                class="margin-top-8"
                [items]="notification.checkbox.items"
                [selectedValue]="notification.checkbox.value"
                (selectedValuesChange)="notification.checkbox.value = $any($event).detail; notification.checkbox.valueChange()">
            </pe-checkbox-button>
        </div>
        <div class="notification-content margin-top-8" *ngIf="notification.additionalButton && notification.additionalButton?.condition()">
            <div class="notification-content-wrapper">
                <div class="notification-content notification-content-text" [innerHtml]="notification.additionalText"></div>
            </div>
            <div class="notification-content notification-button" *ngIf="notification.additionalButton && notification.additionalButton?.condition()">
                <button class="button button-default button-margin-top"
                    [disabled]="notification.additionalButton.disabled()"
                    (click)="notification.additionalButton.click()"
                    [innerHtml]="notification.additionalButton.buttonTitle"
                    [ngbTooltip]="notification.button?.tooltip"
                    [disableTooltip]="returnFn(notification.button?.disableTooltip)"
                    type="button"></button>
            </div>
        </div>
    </div>
</div>
