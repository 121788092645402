<ng-template #tooltipTemplate>
    <pe-content-tooltip [tooltip]="tooltip"></pe-content-tooltip>
</ng-template>

<ng-template #infoPopupTooltipTemplate>
    <pe-content-tooltip [tooltip]="infoPopupTooltip"></pe-content-tooltip>
</ng-template>

<label
    [id]="focusControlId + '-label'"
    *ngIf="titleHtml"
    class="label" [class.textcolor]="isAdvanceInputPopup"
    [class.hilti-styled]="isHiltiStyled"
    [for]="focusControlId"
    [ngbTooltip]="ngbTooltipTemplate(tooltip, tooltipTemplate)"
    [innerHtml]="titleHtml">
</label>

<button *ngIf="infoPopup" class="info-popup-button" type="button"
    [ngbTooltip]="ngbTooltipTemplate(infoPopupTooltip, infoPopupTooltipTemplate)" (click)="openInfoPopup()">
    <span class="info-popup-icon pe-ui-common-sprite pe-ui-common-sprite-info"></span>
</button>
