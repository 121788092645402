import { CommonRegion } from '../entities/code-lists/common-region';

/**
 * Browser service base
 */
 export abstract class BrowserServiceBase {

    public abstract get scrollbarWidth(): number;
    public abstract encodeB64(str: string): string;
    public abstract decodeB64(data: string): number[];
    public abstract base64toBlob(base64Data: string, contentType: string): Blob;
    public abstract downloadBlob(blob: Blob, fileName: string, storeInTemp: boolean, openAfterSave: boolean, filePath?: string): Promise<string>;
    public abstract getRegion(): CommonRegion;
    public abstract toBase64(val: object): string;
    public abstract fromBase64(value: string): string;
    public abstract get isOfflineOnLine(): boolean;
}

export class BrowserServiceInjected extends BrowserServiceBase {
    private baseService!: BrowserServiceBase;

    public setBaseService(baseService: BrowserServiceBase) {
        this.baseService = baseService;

        this.encodeB64 = baseService.encodeB64.bind(baseService);
        this.decodeB64 = baseService.decodeB64.bind(baseService);
        this.base64toBlob = baseService.base64toBlob.bind(baseService);
        this.downloadBlob = baseService.downloadBlob.bind(baseService);
        this.getRegion = baseService.getRegion.bind(baseService);
        this.toBase64 = baseService.toBase64.bind(baseService);
        this.fromBase64 = baseService.fromBase64.bind(baseService);
    }

    // BrowserServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public get scrollbarWidth() {
        return this.baseService.scrollbarWidth;
    }

    public get isOfflineOnLine() {
        return this.baseService.isOfflineOnLine;
    }

    public encodeB64(_str: string) {
        return '';
    }
    public decodeB64(_data: string): number[] {
        return [];
    }
    public base64toBlob(_base64Data: string, _contentType: string) {
        return {} as Blob;
    }
    public downloadBlob(_blob: Blob, _fileName: string, _storeInTemp: boolean, _openAfterSave: boolean, _filePath?: string) {
        return {} as Promise<string>;
    }
    public getRegion() {
        return {} as CommonRegion;
    }
    public toBase64(_val: object) {
        return '';
    }
    public fromBase64(_value: string) {
        return '';
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
