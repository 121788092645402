/* tslint:disable */

export enum Feature {
    Application_FileSharing = 5,
    Application_SupportButton = 6,
    Application_DesignTemplate = 7,
    Application_ReportTemplate = 8,
    Application_Report = 9,
    Application_Settings_InterfacesToStructuralCalculationSoftware = 10,
    Design_LoadCombination = 100,
    Design_UndoRedo = 101,
    Design_ScopeCheckButtons = 102,
    Design_CADExport = 103,
    Design_ShowInputLoads = 105,
    Menu_Favorites = 200,
    Control_DlubalImportExport = 300,
    Control_SAP2000ImportExport = 301,
    Control_EtabsImportExport = 302,
    Control_RobotImportExport = 303,
    Control_StaadProImportExport = 304,
    Control_ExportRisa = 305,
    Design_AdvancedBaseplateCalculation = 400
}

export enum KnownRegion {
    InternalHiltiRegion = -1,
    Unknown = 0,
    Germany = 1,
    France = 2,
    Italy = 3,
    Spain = 4,
    UnitedKingdom = 5,
    UnitedStates = 6,
    Austria = 7,
    Netherlands = 8,
    Denmark = 9,
    Thailand = 10,
    Sweden = 11,
    Switzerland = 12,
    Belgium = 13,
    CzechRepublic = 62,
    Slovakia = 63,
    Luxembourg = 64,
    Australia = 65,
    Poland = 66,
    Russia = 67,
    Bulgaria = 68,
    Norway = 69,
    Israel = 70,
    International = 71,
    Singapore = 72,
    Portugal = 73,
    China = 74,
    Japan = 75,
    Finland = 76,
    Ukraine = 77,
    Hungary = 78,
    Mexico = 79,
    Chile = 80,
    Lithuania = 81,
    Estonia = 82,
    Latvia = 83,
    Philippines = 84,
    Croatia = 85,
    Slovenia = 86,
    Romania = 87,
    SouthAfrica = 88,
    Taiwan = 89,
    Canada = 90,
    Venezuela_Deprecated = 91,
    HongKong = 92,
    India = 93,
    Turkey = 100,
    Emirates = 101,
    NewZealand = 102,
    Malta = 103,
    Brazil = 104,
    Argentina = 105,
    Malaysia = 106,
    Colombia = 107,
    Ireland = 108,
    Korea = 109,
    SaudiArabia = 110,
    Macau = 118,
    Qatar = 121,
    Kuwait = 122,
    Oman = 123,
    Bahrain = 124,
    Belarus = 125,
    Kazakhstan = 126,
    Indonesia = 127,
    Vietnam = 128,
    Serbia = 129,
    Greece = 130,
    Algeria = 131,
    Morocco = 132,
    Peru = 142,
    DominicanRepublic = 145,
    CostaRica = 146,
    Guatemala = 147,
    Uruguay = 148,
    Panama = 149,
    Ecuador = 150,
    Trinidad = 151,
    Curacao = 152,
    Guyana = 153,
    Jamaica = 154,
    Barbados = 155,
    Belize = 156,
    Cayman = 157,
    Honduras = 158,
    ElSalvador = 159,
    Nicaragua = 160,
    Cuba = 161,
    Bolivia = 162,
    Paraguay = 163,
    Venezuela = 164,
    Bangladesh = 165,
    SriLanka = 166,
    Nepal = 167,
    Myanmar = 168,
    Egypt = 169,
    Pakistan = 170,
    Jordan = 171,
    Kenya = 172,
    Cyprus = 173,
    Azerbaijan = 174,
    Lebanon = 175,
    Iraq = 176,
    Uzbekistan = 177,
    Albania = 178
}
export enum LicenseSuiteType {
    Standard = 1,
    Premium = 2
}
export enum RegionHub {
    Unknown = 0,
    E1 = 1,
    E2 = 2,
    E3 = 3,
    E4 = 4,
    W1_HNA = 5,
    W2 = 6,
    A1 = 7,
    EE = 8,
    META = 9,
    A2 = 10
}
export enum Separator {
    Default = 0,
    Comma = 1,
    Dot = 2,
    Apostrophe = 3,
    WhiteSpace = 4
}
export enum StructuralCalculationSoftware {
    Unknown = 0,
    Dlubal = 1,
    Risa = 2,
    SAP2000 = 3,
    Robot = 4,
    ETABS = 5,
    StaadPro = 6,
    RAM = 7
}
export enum TrialBannerFeature {
    NoLicenseComparisonButton = 1
}
export enum Unit {
    None = 0,
    mm = 100,
    cm = 101,
    m = 102,
    inch = 103,
    ft = 104,
    mi = 105,
    mm2 = 200,
    cm2 = 201,
    m2 = 202,
    inch2 = 203,
    ft2 = 204,
    Nmm2 = 300,
    PSI = 301,
    KSI = 302,
    kNm2 = 303,
    MPa = 304,
    Nm2 = 305,
    kgfcm2 = 306,
    N = 400,
    daN = 401,
    kN = 402,
    lb = 403,
    Kip = 404,
    kgf = 405,
    tonf = 406,
    Nmm = 500,
    Nm = 501,
    daNm = 502,
    kNm = 503,
    in_lb = 504,
    ft_lb = 505,
    in_kip = 506,
    ft_kip = 507,
    kgfm = 508,
    kgfcm = 509,
    C = 600,
    F = 601,
    ms = 700,
    s = 701,
    min = 702,
    h = 703,
    degree = 800,
    rad = 801,
    percent = 900,
    permile = 901,
    N_mm = 1000,
    N_m = 1002,
    daN_m = 1008,
    kN_m = 1014,
    lb_in = 1021,
    lb_ft = 1022,
    Kip_in = 1027,
    Kip_ft = 1028,
    kgf_cm = 1031,
    kgf_m = 1032,
    m_s = 1100,
    Nmm_mm = 1200,
    kNm_m = 1201,
    daNm_m = 1202,
    Nm_m = 1203,
    kgfm_m = 1204,
    kgfcm_cm = 1205,
    in_lb_in = 1206,
    ft_lb_in = 1207,
    ft_lb_ft = 1208,
    ft_kip_ft = 1209,
    in_kip_in = 1210,
    ft_kip_in = 1211,
    kg_m3 = 1300,
    lb_ft3 = 1301,
    mm2_m = 1400,
    cm2_m = 1401,
    inch2_ft = 1402,
    N_mm3 = 1500,
    kN_m3 = 1501,
    lbf_ft3 = 1502,
    item_mm2 = 1600,
    item_cm2 = 1601,
    item_m2 = 1602,
    item_inch2 = 1603,
    item_ft2 = 1604,
    mm3 = 1700,
    cm3 = 1701,
    m3 = 1702,
    inch3 = 1703,
    ft3 = 1704,
    N_cm = 1001,
    N_in = 1003,
    N_ft = 1004,
    N_mi = 1005,
    daN_mm = 1006,
    daN_cm = 1007,
    daN_in = 1009,
    daN_ft = 1010,
    daN_mi = 1011,
    kN_mm = 1012,
    kN_cm = 1013,
    kN_in = 1015,
    kN_ft = 1016,
    kN_mi = 1017,
    lb_mm = 1018,
    lb_cm = 1019,
    lb_m = 1020,
    lb_mi = 1023,
    Kip_mm = 1024,
    Kip_cm = 1025,
    Kip_m = 1026,
    Kip_mi = 1029,
    kgf_mm = 1030,
    kgf_in = 1033,
    kgf_ft = 1034,
    kgf_mi = 1035,
    tonf_mm = 1036,
    tonf_cm = 1037,
    tonf_m = 1038
}
export enum UnitGroup {
    LengthLarge = -100,
    StressSmall = -300,
    None = 0,
    Length = 100,
    Area = 200,
    Stress = 300,
    Force = 400,
    Moment = 500,
    Temperature = 600,
    Time = 700,
    Angle = 800,
    Percentage = 900,
    ForcePerLength = 1000,
    Velocity = 1100,
    MomentPerLength = 1200,
    Density = 1300,
    AreaPerLength = 1400,
    SpecificWeight = 1500,
    ItemsPerArea = 1600,
    Volume = 1700
}
