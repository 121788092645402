/**
 * Routing service base
 */
 export abstract class RoutingServiceBase {

    public abstract get currentUrl(): URL;
    public abstract get currentPath(): string;
    public abstract getUrl(url: string, queryParams?: { [key: string]: any }): string;
    public abstract reloadWindow(url: string): void;
    public abstract navigateToUrl(url: string): Promise<boolean>;
}

export class RoutingServiceInjected extends RoutingServiceBase {
    private baseService!: RoutingServiceBase;

    public setBaseService(baseService: RoutingServiceBase) {
        this.baseService = baseService;

        this.getUrl = baseService.getUrl.bind(baseService);
        this.reloadWindow = baseService.reloadWindow.bind(baseService);
        this.navigateToUrl = baseService.navigateToUrl.bind(baseService);
    }

    // RoutingServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public get currentUrl() {
        return this.baseService.currentUrl;
    }

    public get currentPath() {
        return this.baseService.currentPath;
    }

    public getUrl(_url: string, _queryParams?: { [key: string]: any }) {
        return '';
    }
    public reloadWindow(_url: string) {
        // Nothing to do.
    }
    public navigateToUrl(_url: string) {
        return {} as Promise<boolean>;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
